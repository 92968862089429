import { useState, useEffect } from "react";
import PasswordInfoForm from "./PasswordInfoForm";
import ProfileInfoForm from "./ProfileInfoForm";
import * as consts from "../../consts/Consts";
import { createHeaders, fetchAboutMe } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { useNavigate } from "react-router-dom";

function EditProfile() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [getData, setGetData] = useState({
    full_name: "",
    email: "",
    phone: "",
    birth_day: "",
    github: "",
    linkedin: "",
    reset_email: "",
  });
  const [imageUrl, setImageUrl] = useState(getData.image || "");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAboutMe(navigate);
        setGetData(data);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const formattedPhone = getData.phone.replace(/[^\d+]/g, "");

  const validateForm = () => {
    const newErrors = {};

    if (!getData.full_name.trim()) {
      newErrors.full_name = "Ad və soyad tələb olunur";
    } else if (getData.full_name.length > 64) {
      newErrors.full_name = "Ad və soyad maksimum 64 simvol ola bilər.";
    } else if (getData.full_name.length < 2) {
      newErrors.full_name = "Ad və soyad minimum 2 simvol olmalıdır.";
    }

    if (!getData.phone) {
      newErrors.phone = "Telefon nömrəsini daxil edin.";
    } else if (getData.phone && getData.phone.replace(/[^\d+]/g, "").length < 13) {
      newErrors.phone = "Telefon nömrəsi doğru deyil.";
    }

    if (!getData.birth_day) {
      newErrors.birth_day = "Doğum tarixi tələb olunur";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGetData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (imageUrl) => {
    setImage(imageUrl);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const headers = createHeaders();
      let apiPath;
      switch (getData.role) {
        case "teacher":
          apiPath = `${consts.API_URL}/teacher/profile`;
          break;
        case "admin":
          apiPath = `${consts.API_URL}/admin/profile`;
          break;
        case "student":
          apiPath = `${consts.API_URL}/student/profile`;
          break;
      }
      const updatedData = {
        ...getData,
        phone: formattedPhone,
      };

      try {
        const profileResponse = await fetch(apiPath, {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(updatedData),
        });

        if (profileResponse.ok) {
          const profileData = await profileResponse.json();
          window.location.reload();
        } else {
          const errorData = await profileResponse.json();
          setErrors((prevErrors) => ({
            ...prevErrors,
            api: errorData.message,
          }));
        }
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      }
    }
  };


  return (
    <form>
      <div className="profile-container">
        <div className="profile-info">
          <div className="pi-title">
            <div className="pi-color"></div>
            <h4 className="pi-subtitle">Profil məlumatları</h4>
          </div>
          <div className="pi-content">
            <div className="pi-form">
              <ProfileInfoForm
                formData={getData}
                handleChange={handleChange}
                imageUrl={imageUrl}
                setImageUrl={setImageUrl}
                setImage={handleFileChange}
                errors={errors}
              />
            </div>
          </div>
        </div>

        <div className="divider"></div>

        <div className="password-info">
          <div className="pi-title">
            <div className="pi-color"></div>
            <h4 className="pi-subtitle">Şifrə məlumatları</h4>
          </div>

          <div className="pi-content">
            <div className="pi-form">
              <PasswordInfoForm role={getData.role} />
            </div>
          </div>
        </div>

        <div className="divider"></div>

        <div className="save-btn-container">
          <button className="save-btn" onClick={handleSubmit}>
            Yadda saxla
          </button>
        </div>
      </div>
    </form>
  );
}

export default EditProfile;
