import { useEffect, useState } from "react";

const EvaluateTableItem = ({ isEdit, data, onPercentChange, total }) => {
  const [errors, setErrors] = useState({
    [data.key]: "",
  });
  

  const handleChange = (key, value) => {
    const numericValue = parseFloat(Number(value));
    onPercentChange(key, numericValue);

    if (numericValue > 100) {
      setErrors((prev) => ({
        ...prev,
        [key]: "Dəyər 100-dən böyük olmamalıdır!",
      }));
    } else if (total < 100) {
      setErrors((prev) => ({
        ...prev,
        [key]: "Toplam dəyər 100-dən kiçik olmamalıdır!",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [key]: "",
      }));
    }
  };

  useEffect(() => {
    if (!isEdit) {
      setErrors({
        [data.key]: "",
      });
    }
    if (total === 100) {
      setErrors({
        [data.key]: "",
      });
    }
  }, [isEdit, total,data]);

  return (
    <>
      <tr>
        <td>
          <div className="left column-content">{data.label}</div>
        </td>
        <td>
          <div className="right column-content justify-content-center">
            <div className="input-container">
              {isEdit ? (
                <>
                  <input
                    type="number"
                    className="settings-input"
                    value={data.value || ""}
                    onChange={(e) => handleChange(data.key, e.target.value)}
                    min="0"
                    max="100"
                  />

                  {errors[data.key] !== "" && (
                    <div className="percent-error">{errors[data.key]}</div>
                  )}
                </>
              ) : (
                <span className="percent">{data.value}%</span>
              )}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default EvaluateTableItem;
