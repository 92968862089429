import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Form } from 'react-bootstrap';
import * as consts from "../../consts/Consts";
import { fetchAboutUsAdmin, createHeaders } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

function AboutUs() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	const [formData, setFormData] = useState({
		logo_white: "",
		logo_black: "",
		company_name: "",
		translations: [
		]
	});

	const [errors, setErrors] = useState({});

	const [imageUrls, setImageUrls] = useState({ logo_white: "", logo_black: "" });
	const [isLoading, setIsLoading] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === "description") {
			setFormData((prevData) => ({
				...prevData,
				translations: [{
					language_id: 1,
					description: value
				}]
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}

		if (value.trim() && errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: undefined,
			}));
		}
	};


	const uploadFile = async (imgData) => {
		setIsLoading(true);
		let headers = createHeaders();
		delete headers["Content-Type"];

		try {
			const response = await fetch(`${consts.API_URL}/site/uploader`, {
				method: "POST",
				headers: headers,
				body: imgData,
			});

			const result = await response.json();

			if (response.ok) {
				return result.data.path;
			}
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
		return "";
	};

	const handleLogoWhiteChange = async (e) => {
		const selectedFile = e.target.files[0];
		if (selectedFile) {
			const imgData = new FormData();
			imgData.append("file", selectedFile);
			imgData.append("folder", "about");

			const imageUrl = await uploadFile(imgData);
			setImageUrls((prevUrls) => ({
				...prevUrls,
				logo_white: `${consts.API_URL_IMG}/${imageUrl}`,
			}));
		}
	};

	const handleLogoBlackChange = async (e) => {
		const selectedFile = e.target.files[0];
		if (selectedFile) {
			const imgData = new FormData();
			imgData.append("file", selectedFile);
			imgData.append("folder", "about");

			const imageUrl = await uploadFile(imgData);
			setImageUrls((prevUrls) => ({
				...prevUrls,
				logo_black: `${consts.API_URL_IMG}/${imageUrl}`,
			}));
		}
	};


	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchAboutUsAdmin(navigate);
				const description = data?.translations[0]?.description || "";
				setFormData({
					logo_white: data.logo_white,
					logo_black: data.logo_black,
					company_name: data.company_name,
					translations: [{ language_id: 1, description: description }],
				});
				setImageUrls({ logo_white: data.logo_white, logo_black: data.logo_black });
				consts.setAboutInfo({
					logo_white: data.logo_white,
					logo_black: data.logo_black,
					company_name: data.company_name,
					// translations: [{ language_id: 1, description: description }],
				});
				
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [navigate]);

	const validateForm = () => {
		const newErrors = {};

		if (!formData.company_name.trim()) {
			newErrors.company_name = "Şirkət adını daxil edin";
		} else if (formData.company_name.length > 64) {
			newErrors.company_name = "Şirkət adı 64 simvoldan çox olmamalıdır.";
		}

		if (!formData.translations[0]?.description.trim()) {
			newErrors.description = "Şirkət haqqında məlumat daxil edin";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (validateForm()) {
			const headers = createHeaders();
			const apiPath = `${consts.API_URL}/admin/about`;

			const updatedData = {
				logo_white: imageUrls.logo_white || formData.logo_white,
				logo_black: imageUrls.logo_black || formData.logo_black,
				company_name: formData.company_name,
				translations: [
					{
						language_id: 1,
						description: formData.translations[0]?.description || "",
					},
				],
			};

			try {
				const profileResponse = await fetch(apiPath, {
					method: "PUT",
					headers: headers,
					body: JSON.stringify(updatedData),
				});

				if (profileResponse.ok) {
					consts.setAboutInfo(updatedData);
					window.location.reload();
				} else {
					const errorData = await profileResponse.json();
					setErrors((prevErrors) => ({
						...prevErrors,
						api: errorData.message,
					}));
				}
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			}
		}
	};

	return (
		<div className="groups">
			<div className="groups-header">
				<Form className='about-form'>
					<div className="pi-content-img d-flex">
						<div className="pi-item d-flex">
							<div className="pi-c-image about-logo-img">
								{/* <img src={consts.LOGO_PATH_NAVBAR}
                                alt="Profile" /> */}
								<img src={imageUrls.logo_white} alt="White Logo" />
							</div>
							<div className="buttons d-flex align-items-center about-file-button">
								<div className="file-inputs">
									<input
										type="file"
										name="logo_white"
										id="whiteLogoFile"
										onChange={handleLogoWhiteChange}
									/>
									<label htmlFor="whiteLogoFile">
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9 1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1V7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H7V15C7 15.5523 7.44771 16 8 16C8.55228 16 9 15.5523 9 15V9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H9V1Z"
												fill="#101110"
											/>
										</svg>
										<span>Ağ logo yüklə</span>
									</label>
								</div>
								{/* <button type="button" className="outlined-btn">
								Sil
							</button> */}
							</div>
						</div>
						<div className="pi-item d-flex">
							<div className="pi-c-image about-logo-img-black">
								{/* <img src={consts.LOGO_PATH_LOGIN}
                                alt="Profile" /> */}
								<img src={imageUrls.logo_black} alt="Black Logo" />
							</div>
							<div className="buttons d-flex align-items-center about-file-button">
								<div className="file-inputs">
									<input
										type="file"
										name="logo_black"
										id="blackLogoFile"
										onChange={handleLogoBlackChange}
									/>
									<label htmlFor="blackLogoFile">
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9 1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1V7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H7V15C7 15.5523 7.44771 16 8 16C8.55228 16 9 15.5523 9 15V9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H9V1Z"
												fill="#101110"
											/>
										</svg>
										<span>Qara logo yüklə</span>
									</label>
								</div>
								{/* <button type="button" className="outlined-btn">
								Sil
							</button> */}
							</div>
						</div>
					</div>
					<Form.Group controlId="formInput1">
						<Form.Label>Şirkət adı</Form.Label>
						<Form.Control
							type="text"
							name="company_name"
							value={formData.company_name}
							onChange={handleChange}
							placeholder="Şirkət adı daxil edin"
							isInvalid={!!errors.company_name}
							className={errors.company_name ? "input-error" : ""}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.company_name}
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId="formInputDescription">
						<Form.Label>Şirkət haqqında</Form.Label>
						<Form.Control
							type="text"
							as="textarea"
							name="description"
							value={formData.translations[0]?.description}
							onChange={handleChange}
							placeholder="Şirkət haqqında daxil edin"
							isInvalid={!!errors.description}
							className={errors.description ? "input-error" : ""}
							style={{ height: "150px" }}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.description}
						</Form.Control.Feedback>
					</Form.Group>
					<div className="save-btn-container">
						<button className="save-btn" onClick={handleSubmit}>
							Yadda saxla
						</button>
					</div>
				</Form>
			</div>
		</div>
	);
}

export default AboutUs;
