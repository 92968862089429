import React from "react";
import { Offcanvas, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Main from "../assets/Main";
import Grade from "../assets/Grade";
import Task from "../assets/Task";
import Schedule from "../assets/Schedule";
import Activity from "../assets/Activity";
import Groups from "../assets/Groups";
import Teachers from "../assets/Teachers";
import Students from "../assets/Students";
import Graduates from "../assets/Graduates";
import Requests from "../assets/Requests";
import WaitingProject from "../assets/WaitingProject";
import Archive from "../assets/Archive";
import About from "../assets/About";
import Settings from "../assets/Settings";
import Templates from "../assets/Templates";
import TeachingDirection from "../assets/TeachingDirection";
import Deleted from "../assets/Deleted";
import * as consts from "../consts/Consts";

const BurgerMenu = ({ show, handleClose, role }) => {
	const location = useLocation();

	const teacherMenuItems = [
		{ path: "/teacher", label: "Əsas", icon: <Main /> },
		{ path: "/grade", label: "Qiymətlər", icon: <Grade /> },
		{ path: "/exercises", label: "Tapşırıqlar", icon: <Task /> },
		{ path: "/course-schedule", label: "Dərs cədvəli", icon: <Schedule /> },
		{ path: "/activity", label: "Aktivlik", icon: <Activity /> },
	];

	const studentMenuItems = [
		{ path: "/student", label: "Əsas", icon: <Main /> },
		{ path: "/grade-student", label: "Mənim qiymətlərim", icon: <Grade /> },
		{ path: "/exercises/current", label: "Tapşırıqlar", icon: <Task /> },
	];

	const adminMenuItems = [
		{ path: "/admin", label: "Əsas", icon: <Main /> },
		{ path: "/groups-admin", label: "Qruplar", icon: <Groups /> },
		{ path: "/teachers", label: "Müəllimlər", icon: <Teachers /> },
		{ path: "/students", label: "Tələbələr", icon: <Students /> },
		{ path: "/graduates", label: "Məzunlar", icon: <Graduates /> },
		{ path: "/waiting-projects", label: "Layihə gözləyənlər", icon: <WaitingProject /> },
		{ path: "/requests", label: "Gələn müraciətlər", icon: <Requests /> },
		{ path: "/teachers-activity", label: "Aktivlik", icon: <Activity /> },
		{ path: "/archived-groups", label: "Arxivləşdirilmiş qruplar", icon: <Archive /> },
		{ path: "/deleted", label: "Silinmişlər", icon: <Deleted /> },
		{ path: "/teaching-directions", label: "Fənnlər", icon: <TeachingDirection /> },
		{ path: "/templates", label: "Şablonlar", icon: <Templates /> },
		{ path: "/course-schedule", label: "Dərs cədvəli", icon: <Schedule /> },
		{ path: "/about-us", label: "Haqqımızda", icon: <About /> },
		{ path: "/settings", label: "Parametrlər", icon: <Settings /> },
	];

	const menuItems =
		role === "teacher"
			? teacherMenuItems
			: role === "student"
				? studentMenuItems
				: role === "admin"
					? adminMenuItems
					: [];

	return (
		<Offcanvas show={show} onHide={handleClose} placement="start" className="header__bm">
			<Offcanvas.Header closeButton>
				<div className="menu-logo">
					<img
						src={consts.getAboutInfo().logo_black}
						alt=""
						style={{width: 150}}
						className={`${consts.getAboutInfo().company_name === "Performance"
							? ""
							: ""
							}`}
					/>
				</div>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<Nav className="flex-column">
					{menuItems.map((item) => (
						<Nav.Link
							key={item.path}
							as={Link}
							to={item.path}
							onClick={handleClose}
							className={`ps-menu-button bm-menu ${location.pathname === item.path ? "active" : ""}`}
						>
							{item.icon}
							<span>{item.label}</span>
						</Nav.Link>
					))}
				</Nav>
			</Offcanvas.Body>
		</Offcanvas>
	);
};

export default BurgerMenu;
