import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { createHeaders } from "../../functions/apiService";
import * as consts from "../../consts/Consts";
import { useNavigate } from "react-router-dom";


const EditTemplate = ({ show, handleCloseModal, note }) => {
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		note: "",
	});


	const [errors, setErrors] = useState({});

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === "note") {
			setFormData((prev) => ({
				...prev,
				[name]: value,
			}));
		}
	};

	const validate = () => {
		let errors = {};
		if (!formData.note.trim()) {
			errors.note = "Şablon tələb olunur!";
		}

		setErrors(errors);

		return errors;
	};

	const resetData = () => {
		setFormData({
			note: note,
		});

		setErrors({
			note: "",
		});
	};

	const [loading, setLoading] = useState(true);

	const handleGetData = async () => {
		try {
			const headers = createHeaders();
			const response = await fetch(
				`${consts.API_URL}/admin/note-template/${note.id}`,
				{
					headers,
				}
			);
			if (!response.ok) {
				throw new Error("Ошибка при получении данных");
			}
			const result = await response.json();
			if (result.data && typeof result.data === "object") {
				const translation = result.data.translations.find(
					(t) => t.language_id === 1
				);
				const note = translation?.note || "";
				setFormData({
					note: note,
				});
			} else {
				//   console.error("Ожидался объект, но получен:", result.data);
			}
		} catch (error) {
			handleUnauthorizedError(error, navigate);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (show && note?.id) {
			handleGetData();
		}
	}, [show, note?.id]);

	const handleSubmit = async () => {
		if (validate()) {
			const headers = createHeaders();

			const dataToSend = {
				translations: [
					{
						language_id: 1,
						note: formData.note,
					},
				],
			};

			// console.log("Отправка данных:", dataToSend);

			try {
				const response = await fetch(
					`${consts.API_URL}/admin/note-template/${note.id}`,
					{
						method: "PUT",
						headers: headers,
						body: JSON.stringify(dataToSend),
					}
				);
				const responseData = await response.json();
				//   console.log("Ответ от API:", responseData);

				if (responseData.code === 200) {
					handleCloseModal()
					window.location.reload();
				}
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			}
		}
	};

	return (
		<>
			<Modal show={show} onHide={handleCloseModal} className="student-modal" backdrop="static">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="formInput1">
							<Form.Label>Şablon adı</Form.Label>
							<Form.Control
								type="text"
								as="textarea"
								name="note"
								value={formData.note}
								onChange={handleChange}
								placeholder="Şablon daxil edin"
								isInvalid={!!errors.note}
								className={errors.note ? "input-error" : ""}
								style={{ height: "150px" }}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.note}
							</Form.Control.Feedback>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button className="modal-btn btn-green" onClick={handleSubmit}>
						Düzəliş et
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default EditTemplate;
