import "./App.css";
import "./style.css";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import MainPageAdmin from "./pages/MainPageAdmin";
import MySidebar from "./components/admin/MySidebar";
import Header from "./components/admin/Header";
import { ProSidebarProvider, useProSidebar } from "react-pro-sidebar";
import GroupsAdmin from "./pages/GroupsAdmin";
import ArchivedGroups from "./pages/ArchivedGroups";
import Students from "./pages/Students";
import Footer from "./components/admin/Footer";
import GroupDetails from "./components/admin/GroupDetails";
import Teachers from "./pages/Teachers";
import Graduates from "./pages/Graduates";
import Deleted from "./pages/Deleted";
import Requests from "./pages/Requests";
import Profile from "./pages/Profile";
import WaitingProject from "./pages/WaitingProject";
import TeachersActivity from "./pages/TeachersActivity";
import TeachingDirections from "./pages/TeachingDirections";
import Exercises from "./components/teachers/Exercises";
import MainPageTeacher from "./pages/MainPageTeacher";
import MySidebarTeacher from "./components/teachers/MySidebarTeacher";
import Grade from "./pages/Grade";
import CurrentExercises from "./pages/CurrentExercises";
import FutureExercise from "./pages/FutureExercise";
import PastExercise from "./pages/PastExercise";
import DraftExercise from "./pages/DraftExercise";
import Settings from "./pages/Settings";
import AddGrade from "./pages/AddGrade";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import WatchingGroup from "./pages/WatchingGroup";
import { Helmet } from "react-helmet";
import MySidebarStudent from "./components/students/MySidebarStudent";
import MainPageStudent from "./pages/MainPageStudent";
import Templates from "./pages/Templates";
import About from "./pages/About";
import EditGradeGroup from "./components/admin/EditGradeGroup";
import AddGradeGroup from "./components/admin/AddGradeGroup";
import { fetchAboutUs } from "./functions/apiService";
import CourseSchedule from "./pages/CourseSchedule";
import GradeStudent from "./pages/GradeStudent";
import useAutoRefreshToken from './functions/useAutoRefreshToken';
import GroupsActivity from "./pages/GroupsActivity";
import Lessons from "./pages/Lessons";
import EditGradeTeacher from "./pages/EditGradeTeacher";
import ActivityTeacher from "./pages/ActivityTeacher";
import { getAccessTokenFromCookie } from "./functions/tokenService";

function PrivateRoute({ element, role, allowedRoles }) {
	if (!role) {
		return <Navigate to="/" replace />;
	}

	if (allowedRoles && !allowedRoles.includes(role)) {
		return <Navigate to="/" replace />;
	}

	return element;
}

function App() {
	useAutoRefreshToken();
	const [collapsed, setIsSidebarCollapsed] = useState(false);
    
    const { collapseSidebar, expandSidebar } = useProSidebar();
	const [isExpanded, setIsExpanded] = useState(false);

    const toggleSidebar = () => {
        if (collapsed) {
            setIsExpanded(false);
        } else {
            collapseSidebar();
			setIsExpanded(true);
        }
        setIsSidebarCollapsed(!collapsed);
    }
	const { pathname } = useLocation();
	let token = getAccessTokenFromCookie();
	const isLoginPage =
		pathname === "/" ||
		pathname === "/login" ||
		pathname === "/forget-password" ||
		pathname === "/reset-password";
	const navigate = useNavigate()
	const [role, setRole] = useState(localStorage.getItem("role"));
	useEffect(() => {
		if (pathname === "/" && token && role) {
			navigate(`/${role}`);
		}
	}, [pathname, token, role, navigate]);

	useEffect(() => {
		if (role) {
			localStorage.setItem("role", role);
		} else {
			localStorage.removeItem("role");
		}
	}, [role]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
		});
	}, [pathname]);

	const [companyName, setCompanyName] = useState("");
	const [logoWhite, setLogoWhite] = useState("");

	useEffect(() => {
		const fetchCompanyName = async () => {
			try {
				const data = await fetchAboutUs();
				setCompanyName(data.company_name);
				setLogoWhite(data.logo_white);
			} catch (error) { }
		};

		fetchCompanyName();
	}, []);

	useEffect(() => {
		document.title = companyName ? `${companyName}` : "Performance";
	}, [companyName]);

	

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<link id="favicon" rel="icon" href="/favicon.ico" />
				<title>{companyName || "Performance"}</title>
			</Helmet>

			<ProSidebarProvider>
				{!isLoginPage && <Header setRole={setRole} role={role} />}
				<div
					className="app-container"
					style={{ display: isLoginPage ? "block" : "flex" }}
				>
					{!isLoginPage ? (
						<>
							<div
								className={`sidebar ${collapsed ? "collapsed" : "expanded"}`}
							>
								{role === "admin" ? (
									<MySidebar toggleSidebar={toggleSidebar} collapsed={collapsed} />
								) : role === "teacher" ? (
									<MySidebarTeacher toggleSidebar={toggleSidebar} collapsed={collapsed}/>
								) : role === "student" ? (
									<MySidebarStudent toggleSidebar={toggleSidebar}  collapsed={collapsed}/>
								) : null}
							</div>
							<div
								className={`main-content ${collapsed ? "collapsed" : "expanded"
									}`}
									>
								<Routes>
									{/* Административные маршруты */}
									<Route
										path="/admin"
										element={
											<PrivateRoute
												element={<MainPageAdmin />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/groups-admin"
										element={
											<PrivateRoute
												element={<GroupsAdmin />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/groups-admin/:id/lessons"
										element={
											<PrivateRoute
												element={<Lessons />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/archived-groups"
										element={
											<PrivateRoute
												element={<ArchivedGroups />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									{/* <Route
										path="/lessons"
										element={
											<PrivateRoute
												element={<Lessons />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/> */}
									<Route
										path="/students"
										element={
											<PrivateRoute
												element={<Students />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/teachers"
										element={
											<PrivateRoute
												element={<Teachers />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/graduates"
										element={
											<PrivateRoute
												element={<Graduates />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/deleted"
										element={
											<PrivateRoute
												element={<Deleted />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/requests"
										element={
											<PrivateRoute
												element={<Requests />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/waiting-projects"
										element={
											<PrivateRoute
												element={<WaitingProject />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/teachers-activity"
										element={
											<PrivateRoute
												element={<TeachersActivity />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/groups-activity"
										element={
											<PrivateRoute
												element={<GroupsActivity />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/teaching-directions"
										element={
											<PrivateRoute
												element={<TeachingDirections />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/templates"
										element={
											<PrivateRoute
												element={<Templates />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/about-us"
										element={
											<PrivateRoute
												element={<About />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/watching-group"
										element={
											<PrivateRoute
												element={<WatchingGroup role={role} />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/group-details/:groupId"
										element={
											<PrivateRoute
												element={<GroupDetails role={role} />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>

									{/* Маршруты для учителей */}
									<Route
										path="/teacher"
										element={
											<PrivateRoute
												element={<MainPageTeacher />}
												role={role}
												allowedRoles={["teacher"]}
											/>
										}
									/>
									<Route
										path="/grade"
										element={
											<PrivateRoute
												element={<Grade role={role} />}
												role={role}
												allowedRoles={["teacher", "student"]}
											/>
										}
									/>
									<Route
										path="/course-schedule"
										element={
											<PrivateRoute
												element={<CourseSchedule role={role} />}
												role={role}
												allowedRoles={["teacher", "admin"]}
											/>
										}
									/>
									<Route
										path="/activity"
										element={
											<PrivateRoute
												element={<ActivityTeacher role={role} />}
												role={role}
												allowedRoles={["teacher"]}
											/>
										}
									/>
									<Route
										path="/exercises"
										element={
											<PrivateRoute
												element={<Exercises role={role} />}
												role={role}
												allowedRoles={["teacher", "student"]}
											/>
										}
									>
										<Route index element={<Navigate to="current" replace />} />
										<Route path="current" element={<CurrentExercises role={role} />} />
										{role === "teacher" && <Route path="future" element={<FutureExercise role={role} />} />}
										<Route path="past" element={<PastExercise role={role} />} />
										{role === "teacher" && <Route path="draft" element={<DraftExercise role={role} />} />}
									</Route>
									<Route
										path="/settings"
										element={
											<PrivateRoute
												element={<Settings />}
												role={role}
												allowedRoles={["admin", "teacher"]}
											/>
										}
									/>
									<Route
										path="/add-grade/:groupId"
										element={
											<PrivateRoute
												element={<AddGrade role={role} />}
												role={role}
												allowedRoles={["teacher"]}
											/>
										}
									/>
									<Route
										path="/edit-grade/:groupId"
										element={
											<PrivateRoute
												element={<EditGradeTeacher role={role} />}
												role={role}
												allowedRoles={["teacher"]}
											/>
										}
									/>
									<Route
										path="/edit-grades/:groupId"
										element={
											<PrivateRoute
												element={<EditGradeGroup role={role} />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/add-grades/:groupId"
										element={
											<PrivateRoute
												element={<AddGradeGroup role={role} />}
												role={role}
												allowedRoles={["admin"]}
											/>
										}
									/>
									<Route
										path="/edit-profile"
										element={
											<PrivateRoute
												element={<Profile />}
												role={role}
												allowedRoles={["admin", "teacher", "student"]}
											/>
										}
									/>

									{/* Student */}
									<Route
										path="/student"
										element={
											<PrivateRoute
												element={<MainPageStudent />}
												role={role}
												allowedRoles={["student"]}
											/>
										}
									/>
									<Route
										path="/grade-student"
										element={
											<PrivateRoute
												element={<GradeStudent />}
												role={role}
												allowedRoles={["student"]}
											/>
										}
									/>
								</Routes>
							</div>
						</>
					) : (
						<Routes>
							<Route path="/" element={<Login setRole={setRole} />} />
							<Route path="/forget-password" element={<ForgetPassword />} />
							<Route path="/reset-password" element={<ResetPassword />} />
							{/* Любые другие публичные маршруты можно добавить здесь */}
						</Routes>
					)}
				</div>
				{!isLoginPage && <Footer />}
			</ProSidebarProvider>
		</>
	);
}

export default App;
