import { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import WeekPagination from "../components/teachers/WeekPagination";
import * as consts from "../consts/Consts";
import { handleUnauthorizedError } from "../functions/authUtils";
import { createHeaders, fetchAboutMe, fetchGroups, fetchLessons, fetchTeachers, fetchTeacherGroups, fetchTeacherLessons } from "../functions/apiService";
import CustomSelect from '../components/CustomSelect';
import Loading from "../assets/Loading"

const CourseSchedule = () => {
	const navigate = useNavigate();
	const [subjects, setSubjects] = useState([]);
	const [groups, setGroups] = useState([]);
	const [lessons, setLessons] = useState([]);
	const [teachers, setTeachers] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [selectedLesson, setSelectedLesson] = useState(null);
	const [selectedTeacher, setSelectedTeacher] = useState(null);
	const [loadingLessons, setLoadingLessons] = useState(true);
	const [loadingTeachers, setLoadingTeachers] = useState(true);
	const [loadingGroups, setLoadingGroups] = useState(true);
	const [loading, setLoading] = useState(true);
	const [role, setRole] = useState("")



	useEffect(() => {
		const fetchData = async () => {
			try {
				let groupsData = [];
				let teachersData = [];
				let teacherGroupData = [];
				let teacherLessonsData = [];
				let currentPage = 1;
				let totalPages = 1;

				// Set loading states to true at the beginning
				setLoadingGroups(true);
				setLoadingTeachers(true);

				if (role === "admin") {
					while (currentPage <= totalPages) {
						const data = await fetchGroups(null, 50, currentPage);
						if (data?.items) {
							groupsData = [...groupsData, ...data.items];
						}
						totalPages = data.totalPages;
						currentPage++;
					}

					currentPage = 1;
					totalPages = 1;

					while (currentPage <= totalPages) {
						const data = await fetchTeachers(null, 50, currentPage);
						if (data?.items) {
							teachersData = [...teachersData, ...data.items];
						}
						totalPages = data.totalPages;
						currentPage++;
					}
				} else if (role === "teacher") {
					currentPage = 1;
					totalPages = 1;

					while (currentPage <= totalPages) {
						const data = await fetchTeacherGroups(navigate, currentPage);
						if (data?.items) {
							teacherGroupData = [...teacherGroupData, ...data.items];
						}
						totalPages = data.totalPages;
						currentPage++;
					}

					currentPage = 1;
					totalPages = 1;

					while (currentPage <= totalPages) {
						const data = await fetchTeacherLessons(navigate, currentPage);
						if (data?.items) {
							teacherLessonsData = [...teacherLessonsData, ...data.items];
						}
						totalPages = data.totalPages;
						currentPage++;
					}
				}

				if (role === "admin") {
					const groupsMap = new Map();
					groupsData.forEach(item => groupsMap.set(item.id, item));
					const teachersMap = new Map();
					teachersData.forEach(item => teachersMap.set(item.id, item));

					const uniqueGroups = Array.from(groupsMap.values()).sort((a, b) => {
						const nameA = a.name ? a.name.toUpperCase() : "";
						const nameB = b.name ? b.name.toUpperCase() : "";
						return nameA.localeCompare(nameB);
					});

					const uniqueTeachers = Array.from(teachersMap.values()).sort((a, b) => {
						const teacherNameA = a.full_name ? a.full_name.toUpperCase() : "";
						const teacherNameB = b.full_name ? b.full_name.toUpperCase() : "";
						return teacherNameA.localeCompare(teacherNameB);
					});

					setGroups(uniqueGroups);
					setTeachers(uniqueTeachers);

				} else if (role === "teacher") {
					const teacherGroupsMap = new Map();
					teacherGroupData.forEach(item => teacherGroupsMap.set(item.id, item));
					const teacherLessonsMap = new Map();
					teacherLessonsData.forEach(item => teacherLessonsMap.set(item.id, item));

					const uniqueTeacherGroups = Array.from(teacherGroupsMap.values()).sort((a, b) => {
						const groupNameA = a.name ? a.name.toUpperCase() : "";
						const groupNameB = b.name ? b.name.toUpperCase() : "";
						return groupNameA.localeCompare(groupNameB);
					});

					const uniqueTeacherLessons = Array.from(teacherLessonsMap.values()).sort((a, b) => {
						const lessonNameA = a.subject?.translations?.[0]?.name || "";
						const lessonNameB = b.subject?.translations?.[0]?.name || "";
						return lessonNameA.localeCompare(lessonNameB);
					});

					setGroups(uniqueTeacherGroups);
					setLessons(uniqueTeacherLessons);
				}

				setLoadingGroups(false);
				setLoadingTeachers(false);

			} catch (error) {
				console.error("Veri çekilirken bir hata oluştu:", error);

				setLoadingGroups(false);
				setLoadingTeachers(false);
			}
		};

		if (role) {
			fetchData();
		}
	}, [role, navigate]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchAboutMe(navigate);
				setRole(data.role);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			}
		};

		fetchData();
	}, [navigate]);

	useEffect(() => {
		const fetchData = async () => {
		  try {
			const data = await fetchLessons(navigate);
			
			const sortedData = data.sort((a, b) => {
			  const nameA = a.subject?.translations?.[0]?.name?.toLowerCase() || '';
			  const nameB = b.subject?.translations?.[0]?.name?.toLowerCase() || '';
			  return nameA.localeCompare(nameB); 
			});
	  
			setLessons(sortedData);
			setLoadingLessons(false);
		  } catch (error) {
			handleUnauthorizedError(error, navigate);
			setLoadingLessons(false);
		  }
		};
	  
		fetchData();
	  }, [navigate]);
	  

	const weekDayName = [
		"Bazar ertəsi",
		"Çərşənbə axşamı",
		"Çərşənbə",
		"Cümə axşamı",
		"Cümə",
		"Şənbə",
		"Bazar",
	];

	const hoursList = [
		"07:00",
		"08:00",
		"09:00",
		"10:00",
		"11:00",
		"12:00",
		"13:00",
		"14:00",
		"15:00",
		"16:00",
		"17:00",
		"18:00",
		"19:00",
		"20:00",
		"21:00",
		"22:00",
		"23:00",
	];

	const defaultColors = [
		"#F1F1FA",
		"#FFDFFD",
		"#FFD3B4",
		"#CDF0D5",
		"#F9F19D",
	];

	const getRandomColor = () => {
		const randomIndex = Math.floor(Math.random() * defaultColors.length);
		return defaultColors[randomIndex];
	};

	const getCurrentDay = () => {
		const day = new Date().getDay();
		return day === 0 ? 7 : day;
	};

	const [activeDay, setActiveDay] = useState(getCurrentDay());
	const [scheduleData, setScheduleData] = useState([]);

	const getDateByActiveDay = (activeDay) => {
		const current = new Date();
		const difference = activeDay - getCurrentDay();
		current.setDate(current.getDate() + difference);
		return current;
	};

	const currentTime = (activeDay) => {
		const date = getDateByActiveDay(activeDay);
		const weekDay = activeDay;
		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const dateTime = date.getDate().toString().padStart(2, "0");
		const year = date.getFullYear();

		return `${weekDayName[weekDay - 1]} —  ${dateTime}.${month}.${year}`;
	};

	const start = "10:12";
	const end = "12:00";

	function timeToSeconds(time) {
		if (!time) return 0;
		const [hours, minutes] = time.split(":").map(Number);
		return hours * 3600 + minutes * 60;
	}

	const startInSeconds = timeToSeconds(start);
	const endInSeconds = timeToSeconds(end);

	const [width, setWidth] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const ref = useRef(null);

	useEffect(() => {
		setWidth(ref.current.scrollWidth);
	}, [width]);

	useEffect(() => {
		const timeDifferenceInSeconds = endInSeconds - startInSeconds;
		const hours = Math.floor(timeDifferenceInSeconds / 3600);
		const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);

		setHours(hours);
		setMinutes(minutes);
	}, [hours, minutes]);

	const groupScheduleByColumns = (schedule) => {
		const columns = [];

		schedule.forEach((item) => {
			let placed = false;

			for (let i = 0; i < columns.length; i++) {
				const column = columns[i];

				const overlaps = column.some(existingItem =>
					timeToSeconds(item.schedule.start_time) < timeToSeconds(existingItem.schedule.end_time) &&
					timeToSeconds(item.schedule.end_time) > timeToSeconds(existingItem.schedule.start_time)
				);

				if (!overlaps) {
					column.push(item);
					placed = true;
					break;
				}
			}

			if (!placed) {
				columns.push([item]);
			}
		});

		return columns;
	};

	const fetchSchedule = async (day) => {
		if (!role) return;

		const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
		const dayParam = weekDays[day - 1];

		const groupParam = selectedGroup ? `&group_id=${selectedGroup}` : '';
		const lessonParam = selectedLesson ? `&subject_id=${selectedLesson}` : '';
		const teacherParam = selectedTeacher ? `&user_id=${selectedTeacher}` : '';

		const apiURL = role === 'admin'
			? `${consts.API_URL}/admin/group-subject?schedules_week_day=${dayParam}${groupParam}${lessonParam}${teacherParam}`
			: `${consts.API_URL}/teacher/group-subject?schedules_week_day=${dayParam}${groupParam}${lessonParam}${teacherParam}`;

		try {
			setLoading(true)
			const response = await fetch(apiURL, {
				method: "GET",
				headers: createHeaders(),
			});

			if (!response.ok) {
				if (response.status === 401) {
					handleUnauthorizedError(response, navigate);
				} else {
					throw new Error(`Error: ${response.statusText}`);
				}
			}

			let data = await response.json();
			if (!data || !data.data) {
				setScheduleData([]);
				return;
			}

			const filteredData = data.data.flatMap(item => {
				const daySchedules = item.schedules.filter(schedule => schedule.week_day === dayParam);

				return daySchedules.filter(schedule => {
					const startTimeInSeconds = timeToSeconds(schedule.start_time);
					const endTimeInSeconds = timeToSeconds(schedule.end_time);
					const minTime = timeToSeconds("07:00");
					const maxTime = timeToSeconds("23:00");

					return startTimeInSeconds >= minTime && endTimeInSeconds <= maxTime;
				}).map(schedule => ({
					...item,
					schedule: {
						...schedule,
						start_time: schedule.start_time.slice(0, 5),
						end_time: schedule.end_time.slice(0, 5),
					},
				}));
			});

			setScheduleData(filteredData);
			setLoading(false)
		} catch (error) {
			setScheduleData([]);
			setLoading(false)
		} finally {
			setLoading(false)
		}
	};

	useEffect(() => {
		fetchSchedule(activeDay);
	}, [activeDay, role, selectedGroup, selectedLesson, selectedTeacher]);

	const calculateHeight = (startTime, endTime) => {
		const startInSeconds = timeToSeconds(startTime);
		const endInSeconds = timeToSeconds(endTime);
		const timeDifferenceInSeconds = endInSeconds - startInSeconds;

		const height = (timeDifferenceInSeconds / 3600) * 62;
		return height;
	};

	useLayoutEffect(() => {
		if (ref.current) {
			const computedWidth = ref.current.scrollWidth;
			setWidth(computedWidth);
		}
	}, [scheduleData]);

	useEffect(() => {
		if (role) {
			fetchSchedule(activeDay);
		}
	}, [activeDay, role]);

	const recalculateTableWidth = () => {
		const tableElement = ref.current;
		if (tableElement) {
			const computedWidth = tableElement.scrollWidth;
			setWidth(computedWidth);
		}
	};

	useEffect(() => {
		recalculateTableWidth();
	}, [activeDay]);

	useEffect(() => {
		if (ref.current) {
			ref.current.scrollLeft = 0;
		}
	}, [activeDay]);

	useEffect(() => {
		const tableElement = ref.current;
		const cardElements = tableElement?.getElementsByClassName('card-content-wrap') || [];

		if (tableElement) {
			const computedWidth = tableElement.scrollWidth;
			setWidth(computedWidth);

			if (computedWidth > tableElement.clientWidth && cardElements.length > 0) {
				tableElement.style.overflowX = 'auto';
			} else {
				tableElement.style.overflowX = 'hidden';
			}
		}
	}, [scheduleData, activeDay, selectedGroup, selectedLesson, selectedTeacher]);


	useEffect(() => {
		recalculateTableWidth();
	}, []);

	const handleGroupClick = (groupId) => {
		if (role === 'admin') {
			navigate(`/group-details/${encodeURIComponent(groupId)}`);
		}
		else {
			navigate("/grade", { state: { group_id: groupId } });
		}
	};
	

	return (
		<>
			<div className="course-filters d-flex mb-2">
				<div className="cs-filters" style={{ width: "20%" }}>
					<div className="cs-filter">
						<CustomSelect
							options={[
								{ value: null, label: "Bütün qruplar" },
								...(loadingGroups
									? [{ value: null, label: "Yüklənir" }]
									: groups?.map(group => ({
										value: role === "teacher" ? group?.id : group?.id,
										label: group?.name || group.name,
									})))
							]}
							value={selectedGroup}
							onChange={(option) => {
								setSelectedGroup(option?.value || null);
								if (!option?.value) {
									setSelectedLesson(null);
									setSelectedTeacher(null);
								}
							}}
							placeholder={
								loadingGroups
									? "Yüklənir"
									: selectedGroup === null
										? "Bütün qruplar"
										: groups.find(group => group.id === selectedGroup)?.name || "Qrup"
							}
							isInvalid={false}
						/>
					</div>
				</div>

				<div className="cs-filters" style={{ width: "20%" }}>
					<div className="cs-filter">
						<CustomSelect
							options={[
								{ value: null, label: "Bütün dərslər" },
								...(loadingLessons
									? [{ value: null, label: "Yüklənir" }]
									: (lessons && Array.isArray(lessons)
										? lessons.filter(lesson =>
											selectedGroup ? lesson.group?.id === selectedGroup : true
										).map(lesson => ({
											value: lesson.subject?.id || null,
											label: lesson.subject?.translations?.[0]?.name || "Məlumat tapılmadı",
										}))
										: []
									)
								),
							]}
							value={selectedLesson}
							onChange={(option) => {
								setSelectedLesson(option?.value || null);
							}}
							placeholder={
								loadingLessons
									? "Yüklənir"
									: selectedLesson === null
										? "Bütün dərslər"
										: (lessons && lessons.find(lesson => lesson.subject.id === selectedLesson)?.subject?.translations?.[0]?.name) || ""
							}
							isInvalid={false}
							isDisabled={!selectedGroup}
						/>

					</div>
				</div>

				{role === 'admin' && (
					<div className="cs-filters" style={{ width: "20%" }}>
						<div className="cs-filter">
							<CustomSelect
								options={[
									{ value: null, label: "Bütün müəllimlər" },
									...(loadingTeachers
										? [{ value: null, label: "Yüklənir" }]
										: teachers?.map(teacher => ({
											value: teacher.id,
											label: teacher.full_name,
										})))
								]}
								value={selectedTeacher}
								onChange={(option) => {
									setSelectedTeacher(option?.value || null);
								}}
								placeholder={
									loadingTeachers
										? "Yüklənir"
										: selectedTeacher === null
											? "Bütün müəllimlər"
											: teachers.find(teacher => teacher.id === selectedTeacher)?.full_name || "Müəllim"
								}
								isInvalid={false}
							/>
						</div>
					</div>
				)}
			</div>

			<div className="course-schedule">
				<div className="cs_container">
					<div className="cs_heading">
						<div>
							<div className="d-flex items-center">
								<h3 className="cs_title">Dərs cədvəli</h3>
								{loading && (
									<span><Loading /></span>
								)}
							</div>
							<span className="cs_date">{currentTime(activeDay)}</span>
						</div>

						<WeekPagination active={activeDay} setActiveDay={setActiveDay} />
					</div>

					<div className="cs_table-container">
						<div className="cs_table" ref={ref}>
							{hoursList?.map((hourItem, hourIndex) => (
								<div className="cs_table-inner" key={hourIndex} style={{ width: `${width}px` }}>
									<div className="cs_table-time">
										<span>{hourItem}</span>
									</div>

									<div className="cs_table-card">
										<div className="cs_table-card-wrap">
											{groupScheduleByColumns(scheduleData)?.map((column, columnIndex) => (
												<div
													className="cs_column"
													key={`column-${columnIndex}`}
													style={{ display: 'inline-block', width: '411px' }}
												>
													{column?.map((scheduleItem, scheduleIndex) => {
														const schedule = scheduleItem.schedule;
														const uniqueKey = `group-${scheduleItem.group?.id || `item-${scheduleIndex}`}-column-${columnIndex}-schedule-${scheduleIndex}`;

														return (
															hourItem &&
															hourItem.slice(0, 2).includes(schedule?.start_time.slice(0, 2)) && (
																<div
																	className="card-content-wrap"
																	key={uniqueKey}
																	style={{
																		top: `${(((+schedule.start_time.slice(3) / 62) * 100) / 100) * 62}px`,
																		height: `${calculateHeight(schedule.start_time, schedule.end_time)}px`,
																		backgroundColor: getRandomColor(),
																		cursor: 'pointer',
																	}}
																	onClick={() => handleGroupClick(scheduleItem.group?.id)}
																>
																	<div className="cc_content cc_title">
																		<div className="cc_content-inner">
																			<span>Qrup: </span>
																			<span>{scheduleItem.group?.name || "Qrup adı yoxdur"}</span>
																		</div>
																		<span>|</span>
																		<div className="cc_content-inner">
																			<span>Müəllim: </span>
																			<span>{scheduleItem.teacher?.full_name || "Müəllim məlumatı yoxdur"}</span>
																		</div>
																	</div>
																	<div className="cc_content cc_content-container">
																		<div className="cc_content-inner">
																			<span>Saat: </span>
																			<span>
																				{schedule.start_time} - {schedule.end_time || "Bitmə zamanı qeyd olunmadı"}
																			</span>
																		</div>
																		<span>|</span>
																		<div className="cc_content-inner">
																			<span>Dərs: </span>
																			<span>{scheduleItem.subject?.translations?.[0]?.name || "Dərс adı yoxdur"}</span>
																		</div>
																	</div>
																</div>
															)
														);
													})}
												</div>
											))}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>

	);
};

export default CourseSchedule;
