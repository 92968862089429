import React from 'react'

function Activity() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 46 46"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M41.9752 23.7039C43.0742 23.7039 43.9745 24.5967 43.8737 25.691C43.5437 29.2757 42.3244 32.7331 40.3128 35.7437C37.9315 39.3075 34.547 42.0852 30.5871 43.7254C26.6272 45.3656 22.2699 45.7948 18.0661 44.9586C13.8624 44.1224 10.001 42.0584 6.97021 39.0277C3.93946 35.9969 1.87549 32.1355 1.03931 27.9318C0.203125 23.728 0.632284 19.3707 2.27251 15.4108C3.91275 11.4509 6.69038 8.06637 10.2542 5.68513C13.2648 3.67351 16.7222 2.45422 20.307 2.12417C21.4012 2.02341 22.294 2.92374 22.294 4.02266C22.294 5.12157 21.4003 6.00085 20.3083 6.12419C17.5119 6.44004 14.8209 7.41986 12.4651 8.99398C9.55571 10.938 7.28815 13.701 5.94912 16.9337C4.61009 20.1664 4.25974 23.7236 4.94237 27.1554C5.625 30.5872 7.30995 33.7395 9.78416 36.2137C12.2584 38.688 15.4107 40.3729 18.8425 41.0555C22.2743 41.7382 25.8315 41.3878 29.0642 40.0488C32.2969 38.7098 35.06 36.4422 37.0039 33.5328C38.578 31.177 39.5579 28.486 39.8737 25.6896C39.9971 24.5976 40.8763 23.7039 41.9752 23.7039Z"
				fill="#92929D"
			/>
			<path
				d="M25.9419 4.75481C26.1518 1.95522 28.6263 -0.200823 31.3004 0.654054C34.5358 1.68834 37.5063 3.47638 39.953 5.90014C42.3998 8.32389 44.2158 11.2774 45.2806 14.5028C46.1607 17.1687 44.0282 19.6635 41.2307 19.8998L30.6447 20.7941C27.527 21.0575 24.9138 18.4688 25.1477 15.3488L25.9419 4.75481Z"
				fill="#92929D"
			/>
		</svg>
	)
}

export default Activity
