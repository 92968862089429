import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import InputMask from "react-input-mask";

function AddTeacher({ show, handleClose }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone: "+994",
    birth_day: "",
    specialty: "",
    password: "",
    reset_email: "",
  });

  const [errors, setErrors] = useState({});

  const resetFormData = () => {
    setFormData({
      full_name: "",
      email: "",
      phone: "+994",
      birth_day: "",
      specialty: "",
      reset_email: "",
      password: "",
    });
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      const emailRegex = /^[A-Za-z0-9]*$/; 
      if (emailRegex.test(value)) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if (value.trim() && errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };


  const validateForm = () => {
    const newErrors = {};

    if (!formData.full_name.trim()) {
      newErrors.full_name = "Ad və soyad daxil edin";
    } else if (formData.full_name.length > 64) {
      newErrors.full_name = "Ad və soyad 64 simvoldan çox olmamalıdır.";
    }

    if (!formData.email) {
      newErrors.email = "İstifadəçi adı daxil edin.";
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&.-]{8,}$/;
    if (!formData.password) {
      newErrors.password = "Şifrəni daxil edin.";
    } else if (!passwordRegex.test(formData.password)) {
      newErrors.password =
        "Şifrə minimum 8 simvol, 1 böyük hərf, 1 kiçik hərf, 1 rəqəm və 1 xüsusi simvol(@$!%*?&.-) olmalıdır.";
    }

    if (!formData.phone) {
      newErrors.phone = "Telefon nömrəsini daxil edin.";
    } else if (formData.phone && formData.phone.replace(/[^\d+]/g, "").length < 13) {
      newErrors.phone = "Telefon nömrəsi doğru deyil.";
    }



		if (formData.birth_day) {
			const today = new Date();
			const birthDate = new Date(formData.birth_day);
			const minDate = new Date("01-01-1900");

			const fiveYearsAgo = new Date(today.setFullYear(today.getFullYear() - 5));
			if (isNaN(birthDate.getTime())) {
				newErrors.birth_day = "Doğum tarixi düzgün formatda deyil.";
			} else if (birthDate > fiveYearsAgo) {
				newErrors.birth_day = "Tələbə 5 yaşından kiçik ola bilməz!";
			} else if (birthDate < minDate) {
				newErrors.birth_day = "Doğum tarixi düzgün daxil edilməmişdir!";
			}
		}

    if (!formData.specialty) {
      newErrors.specialty = "İxtisası daxil edin.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCloseModal = () => {
    resetFormData();
    handleClose();
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const headers = createHeaders();

      const formattedPhone = formData.phone.replace(/[^\d+]/g, "");

      const dataToSend = {
        ...formData,
        phone: formattedPhone,
        email: formData.email + consts.domain
      };

      try {
        const response = await fetch(`${consts.API_URL}/admin/teacher`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(dataToSend),
        });

        const data = await response.json();
        if (response.ok) {
          resetFormData();
          handleClose();
          window.location.reload();
        } else {
          if (data.errors && data.errors.email && data.errors.email.includes("The email has already been taken.")) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              email: "Bu istifadəçi adı artıq istifadə olunur.",
            }));
          } else {
            console.error("Error adding Teacher:", data.message, data.errors);
          }
        }
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      }
    }
  };

  const currentDate = new Date();
	const fiveYearsAgo = new Date(currentDate.setFullYear(currentDate.getFullYear() - 5));
	const maxBirthDate = fiveYearsAgo.toISOString().split("T")[0];
	

  

  return (
    <Modal show={show} onHide={handleCloseModal} backdrop="static">
      <Modal.Header closeButton>
        <div className="modal-title">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 9.48513L8.57372 14L16 5"
              stroke="#696974"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Yeni müəllimin məlumatları</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-4">
            <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>Ad və Soyad</Form.Label>
                <Form.Control
                  type="text"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  placeholder="Ad və Soyad daxil edin"
                  isInvalid={!!errors.full_name}
                  className={errors.full_name ? "input-error" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.full_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>İstifadəçi adı</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="İstifadəçi adı daxil edin"
                    isInvalid={!!errors.email}
                    className={errors.email ? "input-error" : ""}
                    maxLength={64}
                  />
                  <InputGroup.Text>{consts.domain}</InputGroup.Text>
                </InputGroup>

                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="reset_email"
                  value={formData.reset_email}
                  onChange={handleChange}
                  placeholder="Email daxil edin"
                  isInvalid={!!errors.reset_email}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formInput4">
                <Form.Label>Telefon</Form.Label>
                <InputMask
                  value={formData.phone}
                  mask="+\9\94 (99) 999-99-99"
                  maskChar="_"
                  placeholder="+994 (99) 999-99-99"
                  onChange={(e) => handleChange(e)}
                  name="phone"
                  type="text"
                  className={`form-control ${errors.phone ? "input-error" : ""}`}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <Form.Group controlId="formDate" className="select-form">
                <Form.Label>Doğum tarixi</Form.Label>
                <Form.Control
                  type="date"
                  name="birth_day"
                  value={formData.birth_day}
                  onChange={handleChange}
                  isInvalid={!!errors.birth_day}
                  className={`pr-5 ${errors.birth_day ? "is-invalid input-error" : ""}`}
                  lang="en"
                  min="1900-01-01"
									max={maxBirthDate}
                  inputMode="numeric"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.birth_day}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>Şifrə</Form.Label>
                <Form.Control
                  type="text"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Şifrəni daxil edin"
                  isInvalid={!!errors.password}
                  className={errors.password ? "input-error" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>İxtisas</Form.Label>
                <Form.Control
                  type="text"
                  name="specialty"
                  value={formData.specialty}
                  onChange={handleChange}
                  placeholder="İxtisası daxil edin"
                  isInvalid={!!errors.specialty}
                  className={errors.specialty ? "input-error" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.specialty}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="modal-btn btn-green" onClick={handleSubmit}>
          Müəllimi əlavə et
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddTeacher;
