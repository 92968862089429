import TeachersActivityTable from "../components/admin/TeachersActivityTable";

function TeachersActivity() {
	return (
		<>
			<section>
				<h3 className="title mb-3">Aktivlik</h3>
				<TeachersActivityTable />
			</section>
		</>
	);
}

export default TeachersActivity;
