import { Pagination, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import {
	teachersActivityTable,
	teachersActivityTableHeading,
	teachersActivityTableSelect,
} from "../../utils/data";
import ActivityTableItem from "./ActivityTableItem";
import Form from "react-bootstrap/Form";
import FilterSelect from "../../components/admin/FilterSelect";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchActivity, fetchLeaderList } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import InboxIcon from '@mui/icons-material/Inbox';


function ActivityTable() {
	const [openSelectTwo, setOpenSelectTwo] = useState(false);
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const currentPage = parseInt(searchParams.get('page')) || 1;
	const pagination = parseInt(searchParams.get('pagination')) || 25;
	const [totalPages, setTotalPages] = useState(0);
	const [getData, setGetData] = useState([]);
	const [groups, setGroups] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState('');
	const [openSelectGroup, setOpenSelectGroup] = useState(false);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');
	const [sortField, setSortField] = useState('created_at');
	const [sortOrder, setSortOrder] = useState('desc');
	const [openSelectType, setOpenSelectType] = useState(false);
	const [type, setType] = useState("");

	useEffect(() => {
		if (!searchParams.get('page') || !searchParams.get('pagination')) {
			setSearchParams({
				page: currentPage,
				pagination: pagination,
			});
		}
	}, [searchParams, setSearchParams, currentPage, pagination]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true)
				const data = await fetchActivity(navigate, pagination, currentPage, search, sortField, sortOrder, type, selectedGroup);
				setGetData(data.items || []);
				setTotalPages(data.totalPages);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [pagination, currentPage, search, navigate, sortField, sortOrder, type, selectedGroup]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchLeaderList(navigate, pagination, currentPage);
				const sortData = data.items.sort((a, b) => a.name.localeCompare(b.name));
				setGroups(sortData || []);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [pagination, currentPage, navigate]);

	const handlePageChange = (event, value) => {
		setSearchParams({ page: value, pagination });
	};

	const handlepaginationChange = (event) => {
		setSearchParams({ page: 1, pagination: Number(event.target.value) });
	};

	const handleSortChange = (value) => {
		const [field, order] = value.split(' ');
		setSortField(field);
		setSortOrder(order);
	};

	const handleSearchChange = (event) => {
		setSearch(event.target.value);
		setSearchParams({ page: 1, pagination });
	};

	const handleTypeChange = (e) => {
		setType(e.target.value);
	};

	const handleGroupChange = (e) => {
		setSelectedGroup(e.target.value);
	};

	return (
		<>
			<div className="groups no-add-layer">
				<div className="groups-header">
					<div className="header-right">
						<Form.Select
							className={`selection ${openSelectGroup ? "active" : ""}`}
							onClick={() => setOpenSelectGroup((prev) => !prev)}
							onChange={handleGroupChange}
							value={selectedGroup || ""}
						>
							<option value="">Bütün qruplar</option>
							{groups.map((group) => (
								<option key={group.id} value={group.id}>
									{group.name}
								</option>
							))}
						</Form.Select>
						<Form.Select
							className={`selection ${openSelectType ? "active" : ""}`}
							onClick={() => setOpenSelectType((prev) => !prev)}
							onChange={handleTypeChange}
						>
							<option value="">Ümumi</option>
							<option value="login">Giriş</option>
							<option value="logout">Çıxış</option>
							<option value="task">Tapşırıq</option>
							<option value="grade">Qiymət</option>
						</Form.Select>
						<FilterSelect data={teachersActivityTableSelect} onSelect={handleSortChange} />

						<div className="search-box">
							<input type="text" placeholder="Axtar" onChange={handleSearchChange} />
						</div>
					</div>
				</div>
				<div className="groups-body">
					<table>
						<thead>
							<tr>
								{teachersActivityTableHeading.map((item, i) => (
									<th key={item.value}>
										<div
											className={`${i === 0 ? "left" : ""} column-content ${i === teachersActivityTableHeading.length - 1 ? "right" : ""}`}
										>
											{item.name}
											{sortField === item.value && (
												<span>{sortOrder === 'asc' ? '↓' : '↑'}</span>
											)}
										</div>
									</th>

								))}
							</tr>
						</thead>
						<tbody>
							{loading ? (
								Array.from({ length: 10 })?.map((_, i) => (
									<tr key={i}>
										{teachersActivityTableHeading?.map((item, index) => (
											<td key={index}>
												<Skeleton variant="text" width="100%" height={50} />
											</td>
										))}
										<td>
											<Skeleton variant="text" width="100%" height={50} />
										</td>
									</tr>
								))
							) : getData?.length > 0 && (
								getData && getData?.map((item, i) => (
									<ActivityTableItem data={item} key={i} />
								))
							)}
						</tbody>
					</table>
					{getData?.length === 0 && !loading && (
						<>
							<div className="request__message d-flex justify-content-center align-items-center flex-column" style={{ height: "100%" }}>
								<InboxIcon className="icon-box" />
								<p className="icon-box-p">Məlumat yoxdur!</p>
							</div>
						</>
					)}
				</div>
				<div className="groups-footer">
					<Pagination
						count={totalPages}
						page={currentPage}
						onChange={handlePageChange}
						className="pagination"
						variant="outlined"
						shape="rounded"
					/>
					<Form.Select
						className={`selection ${openSelectTwo ? "active" : ""}`}
						value={pagination}
						onChange={handlepaginationChange}
					>
						<option value="10">10</option>
						<option value="25">25</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</Form.Select>
				</div>
			</div>
		</>
	);
}

export default ActivityTable;
