import React from 'react'

function SideBarArrowLeft() {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.23999 7.035L7.31999 8.955L9.23999 10.875"
				stroke="#98ABFF"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 8.95508L7.3725 8.95508"
				stroke="#98ABFF"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9 15C5.685 15 3 12.75 3 9C3 5.25 5.685 3 9 3"
				stroke="#98ABFF"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export default SideBarArrowLeft
