import React from "react";
import {
	LineChart,
	Line,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
} from "recharts";

const LineChartStudent = ({ data }) => {
	// Əgər `data` mövcud deyilsə və ya `grades` boşdursa, boş massiv qaytar
	const grades = data?.flatMap((item) => item.grades || []) || [];
	// Qiymətləri və tarixi düzgün formatda alırıq
	const chartData = grades
		.filter((grade) => grade?.grade !== null) // `null` olmayan qiymətləri götür
		.map((grade) => ({
			name: grade.date, // Tarix
			uv: grade.grade, // Qiymət
		}));

console.log(data);

	return (
		<div style={{ backgroundColor: "#FFFFFF", padding: "20px", borderRadius: "20px" }}>
			<h3 style={{ color: "#171725", fontSize: "16px", fontWeight: "600" }}>
				Akademik irəliləyiş
			</h3>
			<ResponsiveContainer width="100%" height={400}>
				<LineChart
					data={chartData}
					margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
					style={{ backgroundColor: "#FFFFFF" }}
				>
					<CartesianGrid stroke="#f5f5f5" horizontal={false} />
					<XAxis
						dataKey="name"
						tick={{ fill: "#92929D" }}
						axisLine={false}
						tickLine={false}
					/>
					<YAxis
						ticks={[0, 20, 40, 60, 80, 100]}
						tick={{ fill: "#92929D" }}
						axisLine={false}
						tickLine={false}
						padding={{ bottom: 20 }}
					/>
					<Tooltip
						cursor={{ stroke: "#0062FF", strokeWidth: 3 }}
						contentStyle={{ borderRadius: "8px", border: "1px solid #0062FF" }}
						itemStyle={{ color: "#0062FF" }}
					/>
					<Line
						type="monotone"
						dataKey="uv"
						stroke="#17E5BE"
						strokeWidth={3}
						activeDot={{ r: 6 }}
					/>
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
};

export default LineChartStudent;
