import React from 'react'

function DotMenu() {
	return (
		<svg width="4" height="17" viewBox="0 0 4 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="1.84783" cy="1.84783" r="1.84783" fill="white" />
			<circle cx="1.84783" cy="8.50005" r="1.84783" fill="white" />
			<circle cx="1.84783" cy="15.1521" r="1.84783" fill="white" />
		</svg>
	)
}

export default DotMenu
