import React from 'react'

function WaitingProject() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 63 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.5 12.7313C21.1343 12.7313 12.7312 21.1344 12.7312 31.5C12.7312 41.8657 21.1343 50.2688 31.5 50.2688C41.8657 50.2688 50.2687 41.8657 50.2687 31.5C50.2687 21.1344 41.8657 12.7313 31.5 12.7313ZM8.26874 31.5C8.26874 18.6698 18.6697 8.2688 31.5 8.2688C44.3303 8.2688 54.7312 18.6698 54.7312 31.5C54.7312 44.3303 44.3303 54.7313 31.5 54.7313C18.6697 54.7313 8.26874 44.3303 8.26874 31.5Z"
                fill="#92929D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.25 24.0188C27.4823 24.0188 28.4812 25.0178 28.4812 26.25L28.4812 36.75C28.4812 37.9823 27.4823 38.9813 26.25 38.9813C25.0177 38.9813 24.0187 37.9823 24.0187 36.75L24.0187 26.25C24.0187 25.0178 25.0177 24.0188 26.25 24.0188Z"
                fill="#92929D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.75 24.0188C37.9823 24.0188 38.9812 25.0178 38.9812 26.25L38.9812 36.75C38.9812 37.9823 37.9823 38.9813 36.75 38.9813C35.5177 38.9813 34.5187 37.9823 34.5187 36.75L34.5187 26.25C34.5187 25.0178 35.5177 24.0188 36.75 24.0188Z"
                fill="#92929D"
            />
        </svg>
    )
}

export default WaitingProject