import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import AddExerciseModal from "./AddExerciseModal";
import { Pagination, Skeleton } from "@mui/material";
import Form from "react-bootstrap/Form";
import GroupDetailsItem from "./GroupDetailsItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as consts from "../../consts/Consts";
import { fetchGroups, fetchGroupSubjects } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { createHeaders } from "../../functions/apiService";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedGroup, setSelectedGroupSubject } from "../../store/action";
import { OverlayTrigger, Popover } from "react-bootstrap";
import NoteTable from "./NoteTable";
import InboxIcon from '@mui/icons-material/Inbox';


function GroupDetails({ role }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const selectedGroup = useSelector((state) => state.selectedGroup);
	const selectedGroupSubject = useSelector((state) => state.selectedGroupSubject);
	const [selectedSubject, setSelectedSubject] = useState('')

	const [getData, setGetData] = useState(null);
	const [allStudents, setAllStudents] = useState(null);

	const daysOfWeek = ["B.e.", "Ç.a.", "Ç.", "C.a.", "C.", "Ş.", "B."];
	const [openSelectOne, setOpenSelectOne] = useState(false);
	const [openSelectTwo, setOpenSelectTwo] = useState(false);
	const [openSelectSubject, setOpenSelectSubject] = useState(false);
	const [openSelectGroup, setOpenSelectGroup] = useState(false);
	const [openSelectLesson, setOpenSelectLesson] = useState(false);

	const [showCalendar, setShowCalendar] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const calendarRef = useRef(null);
	const [loading, setLoading] = useState(true);
	const [groups, setGroups] = useState([]);
	const [lessons, setLessons] = useState([]);
	const [schedule, setSchedule] = useState([]);
	const [sortBy, setSortBy] = useState("sort[user_full_name]");
	const [sortDirection, setSortDirection] = useState("asc");

	const [modalShowAdd, setModalShowAdd] = useState(false);
	// const handleModalShowAdd = () => setModalShowAdd(true);
	const handleModalCloseAdd = () => setModalShowAdd(false);
	const [lessonClicked, setLessonClicked] = useState(false)

	useEffect(() => {
		const state = location.state;
		if (state && state.group_id && state.group_subject_id) {
			dispatch(setSelectedGroup(state.group_id));
			dispatch(setSelectedGroupSubject(state.group_subject_id));
		}
	}, [dispatch, location.state]);

	const queryParams = new URLSearchParams(location.search);
	const [loadingGroups, setLoadingGroups] = useState(true);
	const [loadingSubjects, setLoadingSubjects] = useState(true);

	const initialPage = queryParams.get('page') ? parseInt(queryParams.get('page')) : 1;
	const initialPerPage = queryParams.get('pagination') ? parseInt(queryParams.get('pagination')) : 25;

	const [currentPage, setCurrentPage] = useState(initialPage);
	const [perPage, setPerPage] = useState(initialPerPage);
	const [totalPages, setTotalPages] = useState(0);

	// const page = queryParams.get('page') ? parseInt(queryParams.get('page')) : 1;
	// const pagination = queryParams.get('pagination') ? parseInt(queryParams.get('pagination')) : 25;
	const initialDate = queryParams.get('date') ? queryParams.get('date') : '';
	const initialType = queryParams.get('type') ? queryParams.get('type') : '';
	const initialGroupSubjectIDD = parseInt(queryParams.get('group_subject_id')) || location.state?.group_subject_id || '';
	const initialStatus = queryParams.get("status") ? queryParams.get("status") : "daily";

	const [selectedType, setSelectedType] = useState(initialStatus);
	const savedState = localStorage.getItem('isToggled');
	const [isToggled, setIsToggled] = useState(savedState === "true" ? true : false);

	useEffect(() => {
		if (queryParams.get("status") && queryParams.get("status") !== selectedType) {
			setSelectedType(queryParams.get("status"));
		}
	}, [location.search]);

	const { groupId } = useParams()
	const [date, setDate] = useState(initialDate);
	const [type, setType] = useState(initialType);
	// const [groupId, setGroupId] = useState(initialGroupId);
	const [groupSubjectIDD, setGroupSubjectIDD] = useState(initialGroupSubjectIDD);


	const [currentWeek, setCurrentWeek] = useState(date ? date :
		getCurrentWeekRange(new Date())
	);

	useEffect(() => {
		if (date) {
			const parsedDate = parseDate(date);
			setCurrentWeek(getCurrentWeekRange(parsedDate));
		} else {
			setCurrentWeek(prevWeek => prevWeek || getCurrentWeekRange(new Date()));
		}
	}, [date]);

	// console.log("Параметры из URL:", { currentPage, perPage, date, type, groupId, groupSubjectIDD });
	const handlePageChange = (event, value) => {
		setCurrentPage(value);
	};

	const handlePerPageChange = (event) => {
		setPerPage(Number(event.target.value));
		setCurrentPage(1);
	};

	useEffect(() => {
		const params = new URLSearchParams();
		params.set("page", currentPage);
		params.set("pagination", perPage);
		navigate({ search: params.toString() });
	}, [currentPage, perPage, navigate]);

	const handlePrevWeek = () => {
		const prevWeekStart = parseDate(currentWeek.start);
		prevWeekStart.setDate(prevWeekStart.getDate() - 7);
		setCurrentWeek(getCurrentWeekRange(prevWeekStart));
	};

	const handleNextWeek = () => {
		const nextWeekStart = parseDate(currentWeek.start);
		nextWeekStart.setDate(nextWeekStart.getDate() + 7);
		setCurrentWeek(getCurrentWeekRange(nextWeekStart));
	};

	function getCurrentWeekRange(date) {
		const today = new Date(date);
		const dayOfWeek = today.getDay();

		const startOfWeek = new Date(today);
		startOfWeek.setDate(
			today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
		);

		const endOfWeek = new Date(startOfWeek);
		endOfWeek.setDate(startOfWeek.getDate() + 6);

		return {
			start: formatDate(startOfWeek),
			end: formatDate(endOfWeek),
		};
	}

	function formatDate(date) {
		return date.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
	}

	function parseDate(dateString) {
		const [day, month, year] = dateString.split(".");
		return new Date(`${year}-${month}-${day}`);
	}

	const handleDateChange = (date) => {
		setSelectedDate(date);
		setCurrentWeek(getCurrentWeekRange(date));
		setShowCalendar(false);
	};

	const handleToggleCalendar = () => {
		setShowCalendar((prev) => !prev);
	};

	const handleClickOutside = (event) => {
		if (calendarRef.current && !calendarRef.current.contains(event.target)) {
			setShowCalendar(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (groupId) dispatch(setSelectedGroup(groupId));
		if (groupSubjectIDD) {
			dispatch(setSelectedGroupSubject(groupSubjectIDD));
			setSelectedSubject(groupSubjectIDD)
		}

	}, [groupId, groupSubjectIDD, dispatch]);

	useEffect(() => {		
		if (groups && groups.length > 0) {
			if (lessonClicked) {
				if (groupSubjectIDD && isToggled) {
					dispatch(setSelectedGroupSubject(groupSubjectIDD));
					setSelectedSubject(groupSubjectIDD)
				}
				else {
					dispatch(setSelectedGroupSubject(lessons[0]?.groupSubjectID));
					setSelectedSubject(lessons[0]?.subject.id)
				}
			}
			if (!selectedGroup) {
				dispatch(setSelectedGroup(groups[0]?.id));
			}
			if (!groupSubjectIDD && !selectedGroupSubject) {
				if (groupSubjectIDD) {
					dispatch(setSelectedGroupSubject(groupSubjectIDD));
					setSelectedSubject(groupSubjectIDD)
				}
				else {
					dispatch(setSelectedGroupSubject(lessons[0]?.groupSubjectID));
					setSelectedSubject(lessons[0]?.subject.id)
				}
			}
		}
	}, [groups, lessons, selectedGroup, selectedGroupSubject, groupSubjectIDD, dispatch]);

	const handleGroupChange = (e) => {
		setLessonClicked(false)
		const groupId = Number(e.target.value);
		dispatch(setSelectedGroup(groupId));

		const selectedGroupData = groups.find((group) => group.id === groupId);
		if (selectedGroupData) {
			dispatch(setSelectedGroupSubject(lessons[0].groupSubjectID));
			setSelectedSubject(lessons[0]?.subject.id)
		}
	};

	const handleLessonChange = (e) => {
		const groupSubjectId = Number(e.target.value);

		if (!lessonClicked) {
			setLessonClicked(true);
		}

		const selectedLesson = lessons.find(lesson => lesson.groupSubjectID === groupSubjectId);


		if (selectedLesson) {
			setSelectedSubject(selectedLesson.subject.id);
		}

		dispatch(setSelectedGroupSubject(groupSubjectId));
	};

	const handleTypeChange = (e) => {
		setSelectedType(e.target.value);
	};

	const handleSortChange = (e) => {
		const value = e.target.value;
		const [field, direction] = value.split("=");
		if (field === sortBy) {
			setSortDirection((prevDirection) =>
				prevDirection === "asc" ? "desc" : "asc"
			);
		} else {
			setSortBy(field);
			setSortDirection(direction);
		}
	};

	useEffect(() => {
		const fetchAllPages = async () => {
			try {
				setLoadingGroups(true)
				const firstResponse = await fetchGroups(navigate, 5, 1);
				const totalPages = firstResponse.totalPages;

				const groupMap = new Map();

				firstResponse.items.forEach(item => {
					groupMap.set(item.id, { id: item.id, name: item.name });
				});

				if (totalPages > 1) {
					const requests = [];
					for (let page = 2; page <= totalPages; page++) {
						requests.push(fetchGroups(navigate, 5, page));
					}

					const responses = await Promise.all(requests);

					responses.forEach(response => {
						response.items.forEach(item => {
							groupMap.set(item.id, { id: item.id, name: item.name });
						});
					});
				}

				const sortedData = Array.from(groupMap.values()).sort((a, b) =>
					a.name.localeCompare(b.name)
				);

				setGroups(sortedData);
				setLoadingGroups(false)
			} catch (error) {
				handleUnauthorizedError(error, navigate);
				setLoadingGroups(false)
			} finally {
				setLoading(false);
				setLoadingGroups(false)
			}
		};

		fetchAllPages();
	}, [navigate]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoadingSubjects(true)
				const response = await fetchGroupSubjects(navigate, groupId ? groupId : selectedGroup);
				const extractedSchedule = Array.isArray(response.data)
					? response.data.map(item => ({
						groupId: item.group.id,
						schedules: item.schedules,
						groupSubjectID: item.id,
					}))
					: [];


				const extractedLesson = Array.isArray(response.data)
					? response.data.map(item => ({
						groupSubjectID: item.id,
						subject: {
							id: item.subject.id,
							translations: item.subject.translations || [],
						},
					}))
					: [];

				const sortedLessons = extractedLesson.sort((a, b) => {
					const nameA = a.subject.translations.find(t => t.language_id === 1)?.name || "";
					const nameB = b.subject.translations.find(t => t.language_id === 1)?.name || "";
					return nameA.localeCompare(nameB);
				});

				setSchedule(extractedSchedule);
				setLessons(sortedLessons);
				setLoadingSubjects(false)
			} catch (error) {
				handleUnauthorizedError(error, navigate);
				setLoadingSubjects(false)
			} finally {
				setLoading(false);
				setLoadingSubjects(false)
			}
		};

		fetchData();
	}, [navigate, selectedGroup, groupId]);


	const getFormattedDate = () => {
		const dateToFormat = selectedDate;

		return dateToFormat.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
	};
	useEffect(() => {
		if (selectedGroup && selectedType && selectedGroupSubject) {
			setLoading(true)
			const formattedDate = getFormattedDate();
			const headers = createHeaders();

			const baseUrl = `${consts.API_URL}/admin/group/get/student`;

			const queryParams = new URLSearchParams({
				pagination: perPage,
				page: currentPage,
				group_id: selectedGroup,
				group_subject_id: selectedGroupSubject,
				date_from: currentWeek.start,
				date_to: currentWeek.end,
				status: selectedType,
			});

			if (sortBy) {
				queryParams.append(sortBy, sortDirection);
			}

			fetch(`${baseUrl}?${queryParams.toString()}`, {
				method: "GET",
				headers: headers,
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Ошибка сети");
					}
					return response.json();
				})
				.then(async (data) => {
					setGetData(data);
					setTotalPages(data.pagination.total_pages);

					const totalPages = data.pagination.total_pages;
					let allStudents = [...data.items];

					if (totalPages > 1) {
						const requests = [];
						for (let page = 2; page <= totalPages; page++) {
							const allStudentsParams = new URLSearchParams({
								pagination: perPage,
								page: page,
								group_id: selectedGroup,
								group_subject_id: selectedGroupSubject,
								date_from: currentWeek.start,
								date_to: currentWeek.end,
								status: selectedType,
							});
							requests.push(fetch(`${baseUrl}?${allStudentsParams.toString()}`, {
								method: "GET",
								headers: headers,
							}).then(res => res.json()));
						}

						const responses = await Promise.all(requests);

						responses.forEach(response => {
							allStudents = allStudents.concat(response.items);
						});
					}

					const uniqueStudents = Array.from(new Map(allStudents.map(item => [item.id, item])).values());

					setAllStudents(uniqueStudents);
					setLoading(false)
				})
				.catch((error) => {
					// console.error("Ошибка при отправке запроса:", error);
					setLoading(false)
				});
		}
	}, [
		selectedGroup,
		selectedGroupSubject,
		selectedType,
		selectedDate,
		currentWeek.start,
		currentWeek.end,
		perPage,
		currentPage,
		sortBy,
		sortDirection,
		date
	]);


	const handleNavigateToAddGrade = () => {
		navigate(`/add-grades/${groupId}`, {
			state: {
				getData: getData,
				selectedGroup: selectedGroup,
				selectedGroupSubject: selectedGroupSubject,
				groups: groups,
			},
		});
	};


	const handleNavigateToEditGrade = () => {
		navigate(`/edit-grades/${groupId}`, {
			state: {
				getData: getData,
				selectedGroup: selectedGroup,
				selectedGroupSubject: selectedGroupSubject,
				groups: groups,
			},
		});
	};

	const dateRange = useRef();

	useEffect(() => {
		function handleClickOutside(event) {
			if (dateRange.current && !dateRange.current.contains(event.target)) {
				setShowCalendar(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);
	const renderSchedule = (groupSubjectID) => {
		const weekDayTranslations = {
			monday: "Bazar ertəsi",
			tuesday: "Çərşənbə axşamı",
			wednesday: "Çərşənbə",
			thursday: "Cümə axşamı",
			friday: "Cümə",
			saturday: "Şənbə",
			sunday: "Bazar",
		};

		if (!schedule || schedule.length === 0) {
			return <p>Cədvəl mövcud deyil</p>;
		}

		const selectedSchedule = schedule.find(item => item.groupSubjectID === groupSubjectID);

		if (!selectedSchedule || !selectedSchedule.schedules.length) {
			return <p>Seçilmiş dərs üçün cədvəl mövcud deyil</p>;
		}

		return (
			<table className="turn-wrapper">
				<tbody>
					{selectedSchedule.schedules.map((schedule, idx) => (
						<tr key={idx}>
							<td>
								<span className="turn-left">
									{weekDayTranslations[schedule.week_day] || schedule.week_day}:
								</span>
							</td>
							<td>
								<span className="turn-right">
									<div>
										{schedule.start_time?.slice(0, 5)} - {schedule.end_time?.slice(0, 5)}
									</div>
								</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	};


	const handleToggle = () => {
		setIsToggled((prevState) => {
			const newState = !prevState;
			localStorage.setItem('isToggled', newState.toString());
			return newState;
		});
	};

	return (
		<>
			{/* {role !== "student" && (
				<AddExerciseModal show={modalShowAdd} handleClose={handleModalCloseAdd} initialSelectedGroup={selectedGroup} initialSelectedSubject={selectedSubject} lessonClicked={lessonClicked} />
			)} */}
			<div className="groups">
				<div className="groups-header groups-header-grade">
					<div className="header-left">
						<div className="group-subject group-subject-selection d-flex align-items-center">
							<div className="group-subject-item d-flex align-items-center">
								<p className="group-subject-name">Qrup:</p>
								<Form.Select
									className={`selection ${openSelectGroup ? "active" : ""}`}
									onClick={() => setOpenSelectGroup((prev) => !prev)}
									onChange={handleGroupChange}
									value={loadingGroups ? "Yüklənir" : selectedGroup || ""}
								>
									{loadingGroups ? (
										<option disabled>Yüklənir</option>
									) : (
										groups.map((group) => (
											<option key={group.id} value={group.id}>
												{group.name}
											</option>
										))
									)}
								</Form.Select>
							</div>
							{!isToggled && (
								<div className="d-flex align-items-center group-subject-schedules">
									<div className="group-subject-item d-flex align-items-center">
										<p className="group-subject-name">Dərs:</p>
										<Form.Select
											className={`selection ${openSelectLesson ? "active" : ""}`}
											onClick={() => setOpenSelectLesson((prev) => !prev)}
											onChange={handleLessonChange}
											value={loadingSubjects ? "Yüklənir" : selectedGroupSubject || ""}
											>
										
										{loadingSubjects ? (
											<option disabled>Yüklənir</option>
										) : (
											lessons.map((lesson) => (
												<option key={lesson.groupSubjectID} value={lesson.groupSubjectID}>
													{lesson.subject.translations[0]?.name || 'No Name'}
												</option>
											))
										)}

										</Form.Select>
									</div>
									<div className="group-schedules">
										<OverlayTrigger
											placement="right"
											rootClose
											trigger="hover"
											overlay={
												<Popover id="popover-positioned-right">
													<Popover.Body style={{ padding: "5px" }}>
														{renderSchedule(selectedGroupSubject)}
													</Popover.Body>
												</Popover>
											}
										>
											<span className="d-inline-block">
												<button className="operation-btn gray-btn text-white">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width="24"
														height="24"
														color="#fff"
														fill="none"
													>
														<circle
															cx="12"
															cy="12"
															r="10"
															stroke="currentColor"
															strokeWidth="1.5"
														/>
														<path
															d="M9.5 9.5L12.9999 12.9996M16 8L11 13"
															stroke="currentColor"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</button>
											</span>
										</OverlayTrigger>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="header-right">
						<div className="toggle-switch">
							Ümumi
							<label className="switch">
								<input type="checkbox" checked={isToggled} onChange={handleToggle} />
								<span className="slider"></span>
							</label>
						</div>
						<div className="group-subject d-flex align-items-center">
							<p className="group-subject-name">Qiymət tipi: </p>
							<Form.Select
								className={`selection ${openSelectSubject ? "active" : ""}`}
								onClick={() => setOpenSelectSubject((prev) => !prev)}
								onChange={handleTypeChange}
								value={selectedType}
							>
								<option value="daily">Günlük</option>
								<option value="exam">İmtahan</option>
								<option value="quiz">Quiz</option>
								<option value="project">Disiplin</option>
							</Form.Select>
						</div>
						<div className="week-navigation position-relative" ref={dateRange}>
							<button onClick={handlePrevWeek}>
								<svg
									width="7"
									height="12"
									viewBox="0 0 7 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M1.8938 6.11523L6.04779 1.93164C6.35407 1.62207 6.35407 1.12402 6.04779 0.814453C5.90137 0.666016 5.70148 0.582031 5.4928 0.582031C5.28412 0.582031 5.08423 0.666016 4.93781 0.814453L0.227783 5.55664C-0.0759277 5.86816 -0.0759277 6.36426 0.227783 6.67578L4.93781 11.418C5.08447 11.5654 5.2843 11.6494 5.4928 11.6494C5.70129 11.6494 5.90112 11.5654 6.04779 11.418C6.3515 11.1064 6.3515 10.6104 6.04779 10.2998L1.8938 6.11523Z"
										fill="#707070"
									/>
								</svg>
							</button>
							<button className="week-current" onClick={handleToggleCalendar}>
								{currentWeek.start} - {currentWeek.end}
							</button>
							<button onClick={handleNextWeek}>
								<svg
									width="7"
									height="12"
									viewBox="0 0 7 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M4.38379 6.11523L0.229736 1.93164C-0.0765381 1.62207 -0.0765381 1.12402 0.229736 0.814453C0.376221 0.666016 0.576172 0.582031 0.78479 0.582031C0.993408 0.582031 1.19336 0.666016 1.33972 0.814453L6.04968 5.55664C6.35339 5.86816 6.35339 6.36426 6.04968 6.6748L1.33972 11.418C1.19312 11.5654 0.993286 11.6494 0.78479 11.6494C0.576294 11.6494 0.376465 11.5654 0.229858 11.418C-0.0737305 11.1064 -0.0738526 10.6104 0.229858 10.2998L4.38379 6.11523Z"
										fill="#707070"
									/>
								</svg>
							</button>
							{showCalendar && (
								<div className="calendar-popup">
									<DatePicker
										selected={selectedDate}
										onChange={handleDateChange}
										inline
									/>
								</div>
							)}
						</div>
						<Form.Select
							className={`selection ${openSelectOne ? "active" : ""}`}
							onClick={() => setOpenSelectOne((prev) => !prev)}
							onChange={handleSortChange}
							value={`${sortBy}${sortDirection ? `=${sortDirection}` : ""}`}
						>
							<option value="">Sırala</option>
							<option value="sort[user_full_name]=asc">Tələbə (A-Z)</option>
							<option value="sort[user_full_name]=desc">
								Tələbə (Z-A)
							</option>
						</Form.Select>
					</div>
				</div>
				<div className="groups-body">
					<table className="grade-table">
						<thead style={{ textAlign: "center" }}>
							<tr>
								<th style={{ width: "500px" }}>
									<div
										className="left column-content"
										style={{ width: "500px", paddingLeft: "20px" }}
									>
										Tələbə
									</div>
								</th>
								<th style={{ width: "120px" }}>
									<div className="column-content" style={{ width: "120px" }}>İstifadəçi adı</div>
								</th>
								<th style={{ width: "120px" }}>
									<div className="column-content" style={{ width: "120px" }}>Telefon</div>
								</th>
								{!isToggled ? (
									<>
										{daysOfWeek.map((day) => (
											<th key={day}>{day}</th>
										))}
									</>
								) : (
									<>
										<th>
											<div className="column-content">Q.S.</div>
										</th>
										<th>
											<div className="right column-content">Q.F.</div>
										</th>
									</>
								)}
								<th>
									<div className="right column-content">H.O.</div>
								</th>
								<th>
									<div className="column-content">Ü.O.</div>
								</th>
							</tr>
						</thead>

						<tbody>
							{loadingGroups ? (
								Array.from(new Array(5)).map((_, index) => (
									<tr key={index}>
										<td>
											<Skeleton variant="text" width={500} height={30} />
										</td>
										<td>
											<Skeleton variant="text" width={120} height={30} />
										</td>
										<td>
											<Skeleton variant="text" width={120} height={30} />
										</td>
										{!isToggled ? (
											daysOfWeek.map((_, idx) => (
												<td key={idx}>
													<Skeleton variant="text" width={40} height={30} />
												</td>
											))
										) : (
											<>
												<td>
													<Skeleton variant="text" width={40} height={30} />
												</td>
												<td>
													<Skeleton variant="text" width={40} height={30} />
												</td>
											</>
										)}
										<td>
											<Skeleton variant="text" width={40} height={30} />
										</td>
										<td>
											<Skeleton variant="text" width={40} height={30} />
										</td>
									</tr>
								))
							) : getData?.data?.students?.length > 0 && (
								getData?.data?.students?.map((student, i) => (
									<GroupDetailsItem
										data={student}
										key={i}
										grades={student.grades}
										isToggled={isToggled}
									/>
								))
							)}
						</tbody>
					</table>
					{getData?.data?.students?.length === undefined && !loadingGroups && (
						<>
							<div className="request__message d-flex justify-content-center align-items-center flex-column" style={{ height: "100%" }}>
								<InboxIcon className="icon-box" />
								<p className="icon-box-p">Məlumat yoxdur!</p>
							</div>
						</>
					)}
				</div>
				<div className="groups-footer justify-content-between">
					<div className="groups-average d-flex justify-content-between">
						{getData && getData.data && getData.data.averages && (
							<>
								<p className="group-overall-average">
									Qrup ortalaması (ümumi):{" "}
									<span>{parseFloat(getData.data.averages.all_average).toFixed(2)}</span>
								</p>
								<p className="group-weekly-average">
									Qrup ortalaması (həftəlik):{" "}
									<span>{parseFloat(getData.data.averages.week_average).toFixed(2)}</span>
								</p>
							</>
						)}
					</div>
					<div className="groups-pagination d-flex">
						<Pagination
							count={totalPages}
							page={currentPage}
							onChange={handlePageChange}
							className="pagination"
							variant="outlined"
							shape="rounded"
						/>

						<Form.Select
							className={`selection ${openSelectTwo ? "active" : ""}`}
							onClick={() => setOpenSelectTwo((prev) => !prev)}
							onChange={handlePerPageChange}
							value={initialPerPage}
						>
							<option value="10">10</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</Form.Select>
					</div>
				</div>
			</div>
			<div className="grade-btns grade-btns-3 mb-5">
				{/* <button className="btn-grey button" onClick={handleModalShowAdd}>Tapşırıq əlavə et</button> */}
				<button className="btn-add button" onClick={handleNavigateToAddGrade} disabled={loadingGroups}>
					<span>Qiymət yaz</span>
				</button>
				<button className="btn-add button" onClick={handleNavigateToEditGrade} disabled={loadingGroups}>
					<span>Qiymətə düzəliş et</span>
				</button>
			</div>
			<NoteTable role={role} />
		</>
	);
}

export default GroupDetails;
