import React from "react";
import {
	Sidebar,
	Menu,
	MenuItem,
	SubMenu,
	useProSidebar,
} from "react-pro-sidebar";
import { NavLink } from "react-router-dom";
import Main from "../../assets/Main";
import Grade from "../../assets/Grade";
import Task from "../../assets/Task";
import Schedule from "../../assets/Schedule";
import SideBarArrowRight from "../../assets/SideBarArrowRight";
import SideBarArrowLeft from "../../assets/SideBarArrowLeft";

const MySidebarStudent = ({toggleSidebar, collapsed}) => {

	return (
		<Sidebar
			collapsed={collapsed}
			style={{
				display: "flex",
				flexDirection: "column",
				height: "100vh",
				width: collapsed ? "80px" : "250px",
				background: "#fff",
				position: "fixed",
				top: "94px",
				bottom: "58px",
			}}
		>
			<div style={{ flex: 1, background: "#fff", height: "100%", overflowY: "auto"}}>
			<Menu>
					<MenuItem>
						<NavLink to="/student">
							<Main />
							{!collapsed && <span>Əsas</span>}
						</NavLink>
					</MenuItem>
					<MenuItem>
						<NavLink to="/grade-student">
							<Grade />
							<span>Qiymətlər</span>
						</NavLink>
					</MenuItem>
					<MenuItem>
						<NavLink to="/exercises">
							<Task />
							<span>Tapşırıqlar</span>
						</NavLink>
					</MenuItem>
				</Menu>
			</div>
			<div>
				<button
					onClick={() => toggleSidebar()}
					className="btn btn-light"
					style={{
						border: "none",
						background: "transparent",
						fontSize: "24px",
					}}
				>
					{collapsed ? (
						<div className="sidebar-btn">
							<SideBarArrowRight />
						</div>
					) : (
						<div className="sidebar-btn">
							<SideBarArrowLeft />
						</div>
					)}
				</button>
			</div>
		</Sidebar>
	);
};

export default MySidebarStudent;
