import React from 'react'

function NotificationTask() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17 4V3C17 2.44772 17.4477 2 18 2C18.5523 2 19 2.44772 19 3V4H20C21.6569 4 23 5.34315 23 7V10V12V17C23 20.3137 20.3137 23 17 23H7C3.68629 23 1 20.3137 1 17V12V10V7C1 5.34315 2.34315 4 4 4H6V3C6 2.44772 6.44772 2 7 2C7.55228 2 8 2.44772 8 3V4H17ZM3 10H21V7C21 6.44772 20.5523 6 20 6H19C19 6.55228 18.5523 7 18 7C17.4477 7 17 6.55228 17 6H8C8 6.55228 7.55228 7 7 7C6.44772 7 6 6.55228 6 6H4C3.44772 6 3 6.44772 3 7V10ZM3 12V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V12H3Z" fill="#9073AC" />
        </svg>
    )
}

export default NotificationTask