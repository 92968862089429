import { Outlet } from "react-router-dom";
import ExercisesNavbar from "./ExercisesNavbar";
import AddExerciseModal from "./AddExerciseModal";
import { useState } from "react";

const Exercises = ({ role }) => {
  const [modalShowAdd, setModalShowAdd] = useState(false);
  const handleModalShowAdd = () => setModalShowAdd(true);
  const handleModalCloseAdd = () => setModalShowAdd(false);

  return (
    <>
      {role !== "student" && modalShowAdd && (
        <AddExerciseModal show={modalShowAdd} handleClose={handleModalCloseAdd} />
      )}
      <div className="exercises">
        <div className="exercises-header">
          <h3 className="title mb-3">Tapşırıqlar</h3>
          <ExercisesNavbar role={role}/>
        </div>

        {role !== "student" && (
          <div className="buttons">
            <button
              type="button"
              className="primary-button"
              onClick={handleModalShowAdd}
            >
              Yeni tapşırıq yarat
            </button>
          </div>
        )}
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default Exercises;
