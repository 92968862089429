import React, { useState, useEffect, useRef } from "react";
import { Pagination, Skeleton } from "@mui/material";
import Form from "react-bootstrap/Form";
import NotesTableItem from "./NotesTableItem";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedGroup } from "../../store/action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService"
import InboxIcon from '@mui/icons-material/Inbox';


function NotesTable({ role }) {
	const [openSelectOne, setOpenSelectOne] = useState(false);
	const [openSelectTwo, setOpenSelectTwo] = useState(false);
	const [openSelectSubject, setOpenSelectSubject] = useState(false);
	const [currentWeek, setCurrentWeek] = useState(
		getCurrentWeekRange(new Date())
	);
	const [showCalendar, setShowCalendar] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const calendarRef = useRef(null);
	const dispatch = useDispatch();
	const selectedGroup = useSelector((state) => state.selectedGroup);
	const selectedGroupSubject = useSelector((state) => state.selectedGroupSubject);
	const [perPage, setPerPage] = useState(25);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [getData, setGetData] = useState(null);
	const [allStudents, setAllStudents] = useState(null);
	const [selectedType, setSelectedType] = useState("daily");
	const [sortBy, setSortBy] = useState("full_name");
	const [sortDirection, setSortDirection] = useState("asc");

	const handlePrevWeek = () => {
		const prevWeekStart = parseDate(currentWeek.start);
		prevWeekStart.setDate(prevWeekStart.getDate() - 7);
		setCurrentWeek(getCurrentWeekRange(prevWeekStart));
	};

	const handleNextWeek = () => {
		const nextWeekStart = parseDate(currentWeek.start);
		nextWeekStart.setDate(nextWeekStart.getDate() + 7);
		setCurrentWeek(getCurrentWeekRange(nextWeekStart));
	};

	function getCurrentWeekRange(date) {
		const today = new Date(date);
		const dayOfWeek = today.getDay();

		const startOfWeek = new Date(today);
		startOfWeek.setDate(
			today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
		);

		const endOfWeek = new Date(startOfWeek);
		endOfWeek.setDate(startOfWeek.getDate() + 6);

		return {
			start: formatDate(startOfWeek),
			end: formatDate(endOfWeek),
		};
	}

	function formatDate(date) {
		return date.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
	}

	function parseDate(dateString) {
		const [day, month, year] = dateString.split(".");
		return new Date(`${year}-${month}-${day}`);
	}

	const handleDateChange = (date) => {
		setSelectedDate(date);
		setCurrentWeek(getCurrentWeekRange(date));
		setShowCalendar(false);
	};

	const handleToggleCalendar = () => {
		setShowCalendar((prev) => !prev);
	};

	const handleClickOutside = (event) => {
		if (calendarRef.current && !calendarRef.current.contains(event.target)) {
			setShowCalendar(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const filteredNotes = (getData || []).filter(note =>
		(selectedGroup ? note.groupId === selectedGroup.id : true) && note.note !== null
	);

	const getFormattedDate = () => {
		const dateToFormat = selectedDate;

		return dateToFormat.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
	};


	const handleTypeChange = (e) => {
		setSelectedType(e.target.value);
	};


	useEffect(() => {
		if (selectedGroup && selectedType) {
			setLoading(true)
			const formattedDate = getFormattedDate();

			const dateToSend = currentWeek.start || formattedDate;
			const headers = createHeaders();

			fetch(
				`${consts.API_URL}/${role}/grade/get/note?pagination=${perPage}&page=${currentPage}&sort[${sortBy}]=${sortDirection}&group_id=${selectedGroup}&group_subject_id=${selectedGroupSubject}&date_from=${currentWeek.start}&date_to=${currentWeek.end}&status=${selectedType}`,
				{
					method: "GET",
					headers: headers,
				}
			)
				.then((response) => {
					if (!response.ok) {
						throw new Error("Ошибка сети");
					}
					return response.json();
				})
				.then((data) => {
					setGetData(data.data);
					setTotalPages(data.pagination.total_pages);
					setLoading(false)
				})
				.catch((error) => {
					setLoading(false)
					// console.error("Ошибка при отправке запроса:", error);
				});

		}
	}, [
		selectedGroup,
		selectedGroupSubject,
		selectedType,
		currentWeek.start,
		currentWeek.end,
		perPage,
		currentPage,
		sortBy,
		sortDirection,
	]);

	const handlePerPageChange = (event) => {
		setPerPage(Number(event.target.value));
		setCurrentPage(1);
	};

	const handlePageChange = (event, value) => {
		setCurrentPage(value);
	};


	const handleSortChange = (e) => {
		const value = e.target.value;
		const [field, direction] = value.split("=");
		if (field === sortBy) {
			setSortDirection((prevDirection) =>
				prevDirection === "asc" ? "desc" : "asc"
			);
		} else {
			setSortBy(field);
			setSortDirection(direction);
		}
	};

	const dateRange = useRef();

	useEffect(() => {
		function handleClickOutside(event) {
			if (dateRange.current && !dateRange.current.contains(event.target)) {
				setShowCalendar(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className="groups">
			<div className="groups-header groups-header-grade">
				<div className="header-left">
					<h3 className="title mb-3">Müəllimin qeydləri</h3>
				</div>
				<div className="header-right">
					<div className="group-subject d-flex align-items-center">
						<p className="group-subject-name">Qeydin tipi: </p>
						<Form.Select
							className={`selection ${openSelectSubject ? "active" : ""}`}
							onClick={() => setOpenSelectSubject((prev) => !prev)}
							onChange={handleTypeChange}
						>
							<option value="daily">Günlük</option>
							<option value="exam">İmtahan</option>
							<option value="quiz">Quiz</option>
							<option value="project">Disiplin</option>
						</Form.Select>
					</div>
					<div className="week-navigation position-relative" ref={dateRange}>
						<button onClick={handlePrevWeek}>
							<svg
								width="7"
								height="12"
								viewBox="0 0 7 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M1.8938 6.11523L6.04779 1.93164C6.35407 1.62207 6.35407 1.12402 6.04779 0.814453C5.90137 0.666016 5.70148 0.582031 5.4928 0.582031C5.28412 0.582031 5.08423 0.666016 4.93781 0.814453L0.227783 5.55664C-0.0759277 5.86816 -0.0759277 6.36426 0.227783 6.67578L4.93781 11.418C5.08447 11.5654 5.2843 11.6494 5.4928 11.6494C5.70129 11.6494 5.90112 11.5654 6.04779 11.418C6.3515 11.1064 6.3515 10.6104 6.04779 10.2998L1.8938 6.11523Z"
									fill="#707070"
								/>
							</svg>
						</button>
						<button className="week-current" onClick={handleToggleCalendar}>
							{currentWeek.start} - {currentWeek.end}
						</button>
						<button onClick={handleNextWeek}>
							<svg
								width="7"
								height="12"
								viewBox="0 0 7 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M4.38379 6.11523L0.229736 1.93164C-0.0765381 1.62207 -0.0765381 1.12402 0.229736 0.814453C0.376221 0.666016 0.576172 0.582031 0.78479 0.582031C0.993408 0.582031 1.19336 0.666016 1.33972 0.814453L6.04968 5.55664C6.35339 5.86816 6.35339 6.36426 6.04968 6.6748L1.33972 11.418C1.19312 11.5654 0.993286 11.6494 0.78479 11.6494C0.576294 11.6494 0.376465 11.5654 0.229858 11.418C-0.0737305 11.1064 -0.0738526 10.6104 0.229858 10.2998L4.38379 6.11523Z"
									fill="#707070"
								/>
							</svg>
						</button>
						{showCalendar && (
							<div className="calendar-popup">
								<DatePicker
									selected={selectedDate}
									onChange={handleDateChange}
									inline
								/>
							</div>
						)}
					</div>
					<Form.Select
						className={`selection ${openSelectOne ? "active" : ""}`}
						onClick={() => setOpenSelectOne((prev) => !prev)}
						onChange={handleSortChange}
						value={`${sortBy}${sortDirection ? `=${sortDirection}` : ""}`}
					>
						<option value="full_name=asc">Tələbə A-Z</option>
						<option value="full_name=desc">
							Tələbə Z-A
						</option>
						<option value="note=asc">Qeyd A-Z</option>
						<option value="note=desc">Qeyd Z-A</option>
						<option value="date=asc">Tarix A-Z</option>
						<option value="date=desc">Tarix Z-A</option>
					</Form.Select>
				</div>
			</div>
			<div className="groups-body">
				<table>
					<thead>
						<tr>
							<th>
								<div className="left column-content">
									Tələbə
								</div>
							</th>
							<th>
								<div className="column-content" >
									Qeyd
								</div>
							</th>
							<th>
								<div className="right column-content">Tarix</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{loading ? (
							Array.from({ length: 5 }).map((_, i) => (
								<tr key={i}>
									<td><Skeleton variant="text" width={800} /></td>
									<td><Skeleton variant="text" width={200} /></td>
									<td><Skeleton variant="text" width={100} /></td>
								</tr>
							))
						) : (
							filteredNotes.map((student, i) => (
								<NotesTableItem data={student} key={i} role={role} />
							))
						)}
					</tbody>
				</table>
					{filteredNotes?.length === 0 && !loading && (
						<>
							<div className="request__message d-flex justify-content-center align-items-center flex-column" style={{ height: "100%" }}>
								<InboxIcon className="icon-box" />
								<p className="icon-box-p">Məlumat yoxdur!</p>
							</div>
						</>
					)}
			</div>
			<div className="groups-footer">
				<div className="groups-pagination d-flex">
					<Pagination
						count={totalPages}
						page={currentPage}
						onChange={handlePageChange}
						className="pagination"
						variant="outlined"
						shape="rounded"
					/>

					<Form.Select
						className={`selection ${openSelectTwo ? "active" : ""}`}
						onClick={() => setOpenSelectTwo((prev) => !prev)}
						onChange={handlePerPageChange}
						value={perPage}
					>
						<option value="10">10</option>
						<option value="25">25</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</Form.Select>
				</div>
			</div>
		</div>
	);
}

export default NotesTable;
