



import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom'
import { Modal, Button, Form, Row, Col, Dropdown } from "react-bootstrap";
import InputMask from "react-input-mask";
import * as consts from "../../consts/Consts";
import {
	fetchSubjects,
	fetchTeachers,
	createHeaders,
} from "../../functions/apiService";
import CustomSelect from '../CustomSelect'
import CustomDropdown from '../CustomDropdown'
import {
	fetchStudentsGroups,
} from "../../functions/apiService";


const AddGroup = ({ show, handleClose, id, data, setSelectedGroup, openAddLesson }) => {
	const navigate = useNavigate();
	const [perPage, setPerPage] = useState(5);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [loadingMore, setLoadingMore] = useState(false);

	const [formData, setFormData] = useState({
		teacher_id: "",
		name: "",
		type: "",
		status: "",
		start_date: "",
		students: []
	});

	const [errors, setErrors] = useState({});

	const resetFormData = () => {
		setFormData({
			teacher_id: "",
			name: "",
			type: "",
			status: "",
			start_date: "",
			students: [
				// {
				// 	user_id: "",
				// },
			],

		});
		setErrors({});
	};

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target || { name: e.name, value: e.value, type: e.type, checked: e.checked };

		if (type === "checkbox") {
			setFormData((prevData) => {
				const newStudent = checked
					? [...prevData.students, { user_id: value }]
					: prevData.students.filter((item) => item.user_id !== value);
				return { ...prevData, students: newStudent };
			});
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}

		// if (name === "name" && value.includes('-')) {
		// 	return;
		// }

		setFormData((prevData) => {
			let updatedName = prevData.name;

			// if (name === "subject_id") {
			// 	const selectedSubject = getData.find(subject => subject.id === value);
			// 	const subjectName = selectedSubject?.translations[0]?.name || "";

			// 	const nameParts = prevData.name.split(' - ');
			// 	const manualName = nameParts[0] || "";

			// 	updatedName = `${manualName} - ${subjectName}`.trim();
			// }

			if (name === "name") {
				updatedName = value;
			}

			return {
				...prevData,
				[name]: value,
				name: updatedName,
			};
		});

		if (value && errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: undefined,
			}));
		}
	};

	const getDropdownLabel = () => {
		if (loading) {
			return "Yüklənir"
		}
		if (!formData.students || formData.students.length === 0) {
			return "Siyahıdan seçin";
		}

		const studentNames = formData.students
			.map((student) => {
				const foundStudent = students.find(
					(s) => s?.id?.toString() === student?.user_id?.toString()
				);
				return foundStudent ? foundStudent.full_name : null;
			})
			.filter((name) => !!name);


		return studentNames.length > 0
			? studentNames.join(", ")
			: "Siyahıdan seçin";
	};

	const validateForm = () => {
		const newErrors = {};

		if (!formData.name.trim()) {
			newErrors.name = "Qrupun adını daxil edin";
		} else if (formData.name.length > 64) {
			newErrors.name = "Qrupun adı 64 simvoldan çox olmamalıdır.";
		}

		if (!formData.type) {
			newErrors.type = "Tədrisin təşkilini seçin.";
		}

		if (!formData.status) {
			newErrors.status = "Qrupun növünü seçin";
		}

		// if (!formData.start_date) {
		// 	newErrors.start_date = "Başlanma tarixini seçin.";
		// }

		// if (!formData.teacher_id) {
		// 	newErrors.teacher_id = "Müəllimi seçin.";
		// }

		if (formData.students.length === 0) {
			newErrors.student = "Tələbə tələb olunur";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleCloseModal = () => {
		resetFormData();
		handleClose();
	};

	const [getData, setGetData] = useState([]);
	const [teachers, setTeachers] = useState([]);
	const [students, setStudents] = useState([]);
	const [loading, setLoading] = useState(true);

	const [loadingTeachers, setLoadingTeachers] = useState(false);
	const [dropDownStudent, setDropDownStudent] = useState(false);


	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchSubjects();
				setGetData(data);
			} catch (error) {
				// console.error("Ошибка при получении данных:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const fetchAllTeachers = async () => {
			let allTeachers = [];
			let currentPage = 1;
			let totalPages = 1;

			try {
				setLoadingTeachers(true)
				while (currentPage <= totalPages) {
					const data = await fetchTeachers(navigate, 15, currentPage, "", "full_name", "asc")
					if (data?.items) {
						allTeachers = [...allTeachers, ...data.items];
					}

					totalPages = data.totalPages;
					currentPage++;
				}

				setTeachers(allTeachers);
				setLoadingTeachers(false);
			} catch (error) {
				console.error("Error:", error);
				setLoadingTeachers(false);
			} finally {
				setLoadingTeachers(false);
			}
		};

		fetchAllTeachers();
	}, []);

	useEffect(() => {
		const fetchAllStudents = async () => {
			let allStudents = [];
			let currentPage = 1;
			let totalPages = 1;

			try {
				setLoading(true);
				while (currentPage <= totalPages) {
					const data = await fetchStudentsGroups(navigate, 15, currentPage, "", "created_at", "desc");
					if (data?.items) {
						allStudents = [...allStudents, ...data.items];
					}

					totalPages = data.totalPages;
					currentPage++;
				}

				setStudents(allStudents);
				setLoading(false);
			} catch (error) {
				console.error("Error:", error);
				setLoading(false);
			} finally {
				setLoading(false);
			}
		};

		fetchAllStudents();
	}, []);

	const handleSubmit = async () => {
		if (validateForm()) {

			const headers = createHeaders();

			try {
				const response = await fetch(`${consts.API_URL}/admin/group`, {
					method: "POST",
					headers: headers,
					body: JSON.stringify({
						status: formData.status,
						teacher_id: formData.teacher_id,
						name: formData.name,
						type: formData.type,
						start_date: formData.start_date,
						students: formData.students,
					}),
				});

				const data = await response.json();

				if (response.ok) {
					// console.log("Group added successfully:", data);
					openAddLesson();
					setSelectedGroup(data.data);
					resetFormData();
					handleClose();
					// window.location.reload();
				} else {
					if (
						data.errors &&
						data.errors.name &&
						data.errors.name.includes("The name has already been taken.")
					) {
						setErrors((prevErrors) => ({
							...prevErrors,
							name: "Bu qrup adı artıq istifadə olunur.",
						}));
					} else {
						// console.error("Error adding group:", data.message);
					}
				}
			} catch (error) {
				// console.error("Request failed:", error);
			}
		}
	};

	return (
		<Modal show={show} onHide={handleCloseModal} backdrop="static">
			<Modal.Header closeButton>
				<div className="modal-title mb-3">
					<span>Yeni qrup məlumatları</span>
				</div>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row className="mb-4">
						<Col>
							<Form.Group controlId="formName">
								<Form.Label>Qrupun adı</Form.Label>
								<Form.Control
									type="text"
									name="name"
									value={formData.name}
									onChange={handleChange}
									placeholder="Qrupun adını daxil edin"
									isInvalid={!!errors.name}
									className={errors.name ? "input-error" : ""}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.name}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>

						<Col>
							<Form.Group controlId="formTeacher" className="select-form">
								<Form.Label>Sinif rəhbərini seçin</Form.Label>
								<CustomSelect
									options={
										loadingTeachers
											? [{ value: "", label: "Yüklənir..." }]
											: teachers?.map(teacher => ({ value: teacher.id, label: teacher.full_name }))
									} value={
										formData.teacher_id
											? { value: formData.teacher_id, label: teachers.find(teacher => teacher.id === formData.teacher_id)?.full_name || "" }
											: null
									}
									onChange={(selected) => handleChange({ name: 'teacher_id', value: selected ? selected.value : "" })}
									isInvalid={!!errors.teacher_id}
									placeholder={loadingTeachers ? "Yüklənir" : "Siyahıdan seçin"}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.teacher_id}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>

					</Row>

					<Row className="mb-4">
						<Col>
							<Form.Group controlId="formType" className="select-form">
								<Form.Label>Tədrisin təşkili</Form.Label>
								<CustomSelect
									options={[
										{ value: 'group', label: 'Qrup' },
										{ value: 'individual', label: 'Fərdi' },
									]}
									value={
										formData.type
											? { value: formData.type, label: formData.type === 'group' ? 'Qrup' : 'Fərdi' }
											: null
									}
									onChange={(selected) => handleChange({ name: 'type', value: selected ? selected.value : '' })}
									isInvalid={!!errors.type}
									placeholder="Siyahıdan seçin"
								/>
								<Form.Control.Feedback type="invalid">
									{errors.type}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group controlId="formStatus" className="select-form">
								<Form.Label>Qrupun növü</Form.Label>
								<CustomSelect
									options={[
										{ value: 'online', label: 'Onlayn' },
										{ value: 'offline', label: 'Oflayn' },
										{ value: 'hybrid', label: 'Hibrid' },
									]}
									value={
										formData.status
											? {
												value: formData.type, label: formData.status === 'online' ? 'Onlayn'
													: formData.status === 'offline' ? 'Oflayn'
														: 'Hibrid'
											}
											: null
									}
									onChange={(selected) => handleChange({ name: 'status', value: selected ? selected.value : '' })}
									isInvalid={!!errors.status}
									placeholder="Siyahıdan seçin"
								/>
								<Form.Control.Feedback type="invalid">
									{errors.status}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>

					<Row className="mb-4">
						<Col style={{ width: "50%" }}>
							<Form.Group controlId="formGroupStudent" >
								<Form.Label>Tələbəni seç</Form.Label>
								<div className="position-relative">
									<Dropdown onClick={() => setDropDownStudent((prev) => !prev)}>
										<Dropdown.Toggle
											id="dropdown-basic"
											className={`select-multiple ${errors.student ? "border-red" : ""
												}`}
										>
											<div>
												{getDropdownLabel()}
											</div>
											{dropDownStudent ? (
												<svg
													width="14"
													height="8"
													viewBox="0 0 14 8"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M1 7L7 1L13 7"
														stroke="#6F767E"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											) : (
												<svg
													width="14"
													height="8"
													viewBox="0 0 14 8"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M1 1L7 7L13 1"
														stroke="#6F767E"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											)}
										</Dropdown.Toggle>

										<Dropdown.Menu className="px-2 add-dropdown-menu">
											{loading ? (
												<Dropdown.Item disabled>
													Yüklənir...
												</Dropdown.Item>
											) : students?.length > 0 ? (
												students.map((student) => (
													<Form.Check
														key={student.id}
														type="checkbox"
														id={`student-${student.id}`}
														label={student.full_name}
														name="student_id"
														value={student.id}
														className="custom-switch"
														checked={formData.students.some(
															(item) => item.user_id === student.id.toString()
														)}
														onChange={handleChange}
													/>
												))
											) : (
												<Dropdown.Item disabled>
													Tələbələr mövcud deyil
												</Dropdown.Item>
											)}
										</Dropdown.Menu>

									</Dropdown>
								</div>{" "}
								<div className="text-danger mt-2">
									{errors.student && <div>{errors.student}</div>}{" "}
								</div>
							</Form.Group>
						</Col>

						<Col>
							<Form.Group controlId="formStartDate" className="select-form">
								<Form.Label>Başlanma tarixi</Form.Label>
								<Form.Control
									type="date"
									name="start_date"
									value={formData.start_date}
									onChange={handleChange}
									isInvalid={!!errors.start_date}
									className={`pr-5 ${errors.start_date ? "input-error" : ""}`}
								/>

								<Form.Control.Feedback type="invalid">
									{errors.start_date}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>

					</Row>

				</Form>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex w-100">
					<Button className="modal-btn btn-green" onClick={handleSubmit}>
						Qrupu əlavə et
					</Button>
					{/* <Button className="modal-btn btn-green" onClick={handleLessonSubmit}>
						Dərs əlavə et
					</Button> */}
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default AddGroup;
