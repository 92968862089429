import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ProfileMenuDropdown from "./ProfileMenuDropdown";
import AddRequestModal from "../teachers/AddRequestModal";
import * as consts from "../../consts/Consts";
import useAboutUs from "../../hooks/useAboutUs";
import BurgerMenu from "../BurgerMenu";
import NotificationGrade from "../../assets/NotificationGrade";
import NotificationMessage from "../../assets/NotificationMessage";
import NotificationAppeal from "../../assets/NotificationAppeal";
import NotificationTask from "../../assets/NotificationTask";
import NotificationNote from "../../assets/NotificationNote";
import { createHeaders } from "../../functions/apiService";
import { useSelector } from "react-redux";


const Header = ({ setRole, role }) => {
	const dataStatus = useSelector(state => state.dataStatus);
	const [profileMenu, setProfileMenu] = useState(false);
	const handleToggleProfileMenu = () => setProfileMenu(!profileMenu);

	const [showModal, setShowModal] = useState(false);
	const handleShowModal = () => setShowModal(false);

	const [showBurger, setShowBurger] = useState(false);
	const handleCloseBurger = () => setShowBurger(false);
	const handleShowBurger = () => setShowBurger(true);

	const [showNotificationMenu, setShowNotificationMenu] = useState(false);
	const [notificationCount, setNotificationCount] = useState(0);
	const [notificationType, setNotificationType] = useState("grade");
	const { loading } = useAboutUs();
	const [notifications, setNotifications] = useState([]);
	const [error, setError] = useState(null);
	const [loadingNot, setLoading] = useState(null);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const notificationRef = useRef(null);

	const handleNotificationClick = () => {
		setShowNotificationMenu((prevState) => !prevState);
	};

	const fetchNotifications = async (pageNumber) => {
		if (!hasMore || loadingNot || pageNumber > totalPages) return;

		setLoading(true);
		const headers = createHeaders();
		try {
			const response = await fetch(
				`${consts.API_URL}/${role === "teacher" ? "teacher" : "student"}/notification?page=${pageNumber}&pagination=10&sort[created_at]=desc`,
				{ method: "GET", headers }
			);

			if (!response.ok) throw new Error("Failed to fetch notifications");

			const data = await response.json();

			if (data.code === 200 && data?.data !== null) {
				setNotifications((prev) => {
					const newNotifications = data?.data?.filter(
						(newNot) => !prev.some((oldNot) => oldNot.id === newNot.id)
					);

					const updatedNotifications = [...prev, ...newNotifications];

					const unseenCount = updatedNotifications?.filter(notif => notif.seen === 0).length;
					setNotificationCount(unseenCount);
					return updatedNotifications;
				});


				setTotalPages(data?.pagination?.total_pages || 1);

				if (data.data.length === 0 || pageNumber >= totalPages) {
					setHasMore(false);
				}
			} else {
				setError("Error fetching notifications.");
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (role !== "admin") {			
			setPage(1);
			setNotifications([]);
			setHasMore(true);
			fetchNotifications(1);
		}
	}, [role]);


	useEffect(() => {
		if (page > 1 && page <= totalPages) {
			fetchNotifications(page);
		}
	}, [page]);

	const handleScroll = () => {
		if (
			window.innerHeight + document.documentElement.scrollTop >=
			document.documentElement.offsetHeight - 10
		) {
			setPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);


	const handleClickOutside = (e) => {
		if (showNotificationMenu && !e.target.closest('.notification-menu') && !e.target.closest('.header-notification')) {
			setShowNotificationMenu(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [showNotificationMenu]);


	const getNotificationStyle = (type) => {
		switch (type) {
			case "grade":
				return {
					icon: <NotificationGrade />,
					backgroundColor: "#83BF6E",
				};
			case "message":
				return {
					icon: <NotificationMessage />,
					backgroundColor: "#B1E5FC",
				};
			case "appeal":
				return {
					icon: <NotificationAppeal />,
					backgroundColor: "#F99090",
				};
			case "schedule":
				return {
					icon: <NotificationTask />,
					backgroundColor: "#CABDFF",
				};
			case "task":
				return {
					icon: <NotificationNote />,
					backgroundColor: "#FFD88D",
				};
			default:
				return {
					icon: <NotificationGrade />,
					backgroundColor: "#FFFFFF",
				};
		}
	};

	const getNotificationLabel = (type) => {
		switch (type) {
			case "grade":
				return "Qiymət";
			case "message":
				return "Mesaj";
			case "appeal":
				return "Təklif";
			case "schedule":
				return "Dərs cədvəli";
			case "task":
				return "Tapşırıq";
			default:
				return "Bildiriş";
		}
	};



	return (
		<>
			<AddRequestModal
				showModal={showModal}
				handleShowModal={handleShowModal}
				role={role}
			/>
			<BurgerMenu show={showBurger} handleClose={handleCloseBurger} role={role} />

			<header className="d-flex justify-content-between align-items-center bg-light header">
				<div className="header__left d-flex justify-content-between align-items-center">
					<div className="header__bm-icon" onClick={handleShowBurger}>
						<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L13 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M1 8L17 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M1 15L9 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
					<Link
						to={role === "admin" ? "/admin" : role === "teacher" ? "/teacher" : "/student"}
						className="header__logo">
						<img
							src={consts.getAboutInfo().logo_white}
							alt=""
							className={`${consts.getAboutInfo().company_name === "Performance" ? "" : "logo-img"}`}
						/>
					</Link>
				</div>

				<div className="header-actions d-flex align-items-center gap-4 header-icon mr-2">
					{role === "admin" && (
						<Link to="/requests" className="header-message">
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7 9.5C7 8.94772 7.44772 8.5 8 8.5H16C16.5523 8.5 17 8.94772 17 9.5C17 10.0523 16.5523 10.5 16 10.5H8C7.44772 10.5 7 10.0523 7 9.5Z"
									fill="white"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7 13.5C7 12.9477 7.44772 12.5 8 12.5H12C12.5523 12.5 13 12.9477 13 13.5C13 14.0523 12.5523 14.5 12 14.5H8C7.44772 14.5 7 14.0523 7 13.5Z"
									fill="white"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M6.71963 17.9636C7.07906 17.664 7.53213 17.5 8 17.5H19C19.5523 17.5 20 17.0523 20 16.5V6.5C20 5.94771 19.5523 5.5 19 5.5H5C4.44772 5.5 4 5.94772 4 6.5V20.2299L6.71963 17.9636ZM8 19.5H19C20.6569 19.5 22 18.1569 22 16.5V6.5C22 4.84315 20.6569 3.5 19 3.5H5C3.34315 3.5 2 4.84315 2 6.5V20.2299C2 21.9256 3.97771 22.8519 5.28037 21.7664L8 19.5Z"
									fill="white"
								/>
							</svg>
							{dataStatus !== 0 && (
								<p className="header-message-count"></p>
							)}
						</Link>
					)}
					{role !== "admin" && (
						<div className="position-relative">
							<div className="header-notification" onClick={handleNotificationClick}>
								<svg
									width="20"
									height="21"
									viewBox="0 0 20 21"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M0 15.0959C2.07459e-06 14.7151 0.154705 14.3506 0.428635 14.086L1.45759 13.0922C1.84928 12.7139 2.06977 12.1922 2.06814 11.6476L2.05867 8.4946C2.04543 4.08319 5.61789 0.5 10.0293 0.5C14.4314 0.5 18 4.06859 18 8.47067L18 11.6716C18 12.202 18.2107 12.7107 18.5858 13.0858L19.5858 14.0858C19.851 14.351 20 14.7107 20 15.0858C20 15.8668 19.3668 16.5 18.5858 16.5H14C14 18.7091 12.2091 20.5 10 20.5C7.79086 20.5 6 18.7091 6 16.5H1.40408C0.628628 16.5 0 15.8714 0 15.0959ZM8 16.5C8 17.6046 8.89543 18.5 10 18.5C11.1046 18.5 12 17.6046 12 16.5H8ZM16 11.6716C16 12.7324 16.4214 13.7499 17.1716 14.5L2.87851 14.5C3.64222 13.746 4.07136 12.7161 4.06813 11.6416L4.05867 8.4886C4.04875 5.1841 6.7248 2.5 10.0293 2.5C13.3268 2.5 16 5.17316 16 8.47067L16 11.6716Z"
										fill="white"
									/>
								</svg>
								{notificationCount > 0 && (
									<p className="header-notification-count"></p>
								)}
							</div>
						</div>
					)}
					{showNotificationMenu && (
						<div className="notification-menu" ref={notificationRef}>
							<div className="notification">
								<div className="notification-header">
									<h4 className="notification-title">Bildirişlər</h4>
									<div className="notification-badge">
										<h6>{notificationCount}</h6>
									</div>
								</div>
								<div className="notification-body">
									{notifications.map((notification, index) => {
										const { icon, backgroundColor } = getNotificationStyle(notification.type);
										return (
											<div className="notification-item" key={index}>
												<div className="notification-content">
													<div className="d-flex">
														<div className="icon-content" style={{ backgroundColor }}>
															{icon}
														</div>
														<div className="not-body">
															<p style={{ fontWeight: notification.seen === 1 ? 400 : 700 }}>
																{notification.message}
															</p>
															<span>{getNotificationLabel(notification.type)}</span>
														</div>
													</div>
													<div className="not-date">
														<p>{notification.created_at.split(" ")[0]}</p>
														<span>{notification.created_at.split(" ")[1].slice(0, 5)}</span>
													</div>

												</div>
											</div>
										);
									})}
									{loadingNot && <p className="text-center">Yüklənir...</p>}
									{!hasMore && <p className="text-center">Bütün bildirişlər yükləndi.</p>}
									{notifications?.length === 0 && !loadingNot && <p className="text-center">Bildiriş yoxdur.</p>}
								</div>
							</div>
						</div>
					)}

					<ProfileMenuDropdown
						show={profileMenu}
						onToggle={handleToggleProfileMenu}
						setRole={setRole}
						role={role}
						setShowModal={setShowModal}
					/>
				</div>
			</header>
		</>
	);
};

export default Header;
