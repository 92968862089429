import React from 'react'

function NotificationMessage() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 3.5H22V20.5H2V3.5Z" stroke="#5B90A7" stroke-width="2" stroke-linejoin="round" />
            <path d="M6 8L12 12L18 8" stroke="#5B90A7" stroke-width="2" />
        </svg>
    )
}

export default NotificationMessage