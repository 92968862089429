import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'react-bootstrap'
import AverageScore from '../components/students/AverageScore'
import TopStudent from '../components/TopStudent'
import GradeTableStudent from '../components/students/GradeTableStudent'
import { setSelectedGroup, setSelectedGroupSubject } from "../store/action";
import { handleUnauthorizedError } from "../functions/authUtils";
import Form from "react-bootstrap/Form";
import { fetchStudentGroups, fetchSettingsGrade } from "../functions/apiService";
import { OverlayTrigger, Popover } from "react-bootstrap";
import NotesTableStudent from '../components/students/NotesTableStudent';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as consts from "../consts/Consts";
import { createHeaders } from "../functions/apiService"
import LineChartStudent from '../components/students/LineChartStudent'
import PieChartStudent from '../components/students/PieChartStudent'
import SemicircleProgressBarStudent from '../components/students/SemicircleProgressBarStudent'

function GradeStudent() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [openSelectGroup, setOpenSelectGroup] = useState(false);
	const [openSelectLesson, setOpenSelectLesson] = useState(false);
	const [groups, setGroups] = useState([]);
	const selectedGroup = useSelector((state) => state.selectedGroup);
	const selectedGroupSubject = useSelector((state) => state.selectedGroupSubject);
	const [loading, setLoading] = useState(true);
	const [showCalendar, setShowCalendar] = useState(false);
	const calendarRef = useRef(null);
	const [startTime, setStartTime] = useState(new Date());
	const [endTime, setEndTime] = useState(new Date());
	const [lessons, setLessons] = useState([]);
	const [lessonClicked, setLessonClicked] = useState(false);
	const [getData, setGetData] = useState(null);


	const handleToggleCalendar = () => {
		setShowCalendar((prev) => !prev);
	};

	const handleClickOutside = (event) => {
		if (calendarRef.current && !calendarRef.current.contains(event.target) && !event.target.closest('.week-current')) {
			setShowCalendar(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const formatDate = (date) => {
		return date ? date.toLocaleDateString('ru-RU') : '';
	};

	const formattedStartTime = formatDate(startTime);
	const formattedEndTime = formatDate(endTime);

	useEffect(() => {
		if (selectedGroup) {
			const selectedGroupData = groups.find((group) => group.id === Number(selectedGroup));
			setLessons(selectedGroupData ? selectedGroupData.lessons : []);
		} else if (groups && groups.length > 0) {
			dispatch(setSelectedGroup(groups?.[0]?.id));
		}
	}, [groups, selectedGroup, dispatch]);

	useEffect(() => {
		if (groups && groups.length > 0) {
			// if (!lessonClicked) {
			// 	dispatch(setSelectedGroupSubject(lessons?.[0]?.id));
			// }
			if (!selectedGroup) {
				dispatch(setSelectedGroup(groups?.[0]?.id));
			}
			// if (!selectedGroupSubject) {
			// 	dispatch(setSelectedGroupSubject(lessons?.[0]?.id));
			// }
		}
	}, [groups, lessons, selectedGroup, dispatch]);


	const handleGroupChange = (e) => {
		setLessonClicked(false);
		const groupId = Number(e.target.value);
		dispatch(setSelectedGroup(groupId));
		dispatch(setSelectedGroupSubject(null));
	};


	// const handleGroupChange = (e) => {
	// 	const groupId = e.target.value;
	// 	dispatch(setSelectedGroup(groupId));

	// 	const selectedGroupData = groups.find((group) => group.id === Number(groupId));

	// 	setLessons(selectedGroupData ? selectedGroupData.lessons : []);
	// };

	const handleLessonChange = (e) => {
		const groupSubjectId = e.target.value ? Number(e.target.value) : null;

		if (!lessonClicked) {
			setLessonClicked(true);
		}

		dispatch(setSelectedGroupSubject(groupSubjectId));
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchStudentGroups(navigate);
				setGroups(data);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [navigate]);

	const [percents, setPercents] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchSettingsGrade(navigate);
				setPercents(data);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);


	const fetchAllPages = async (baseUrl, headers, requestParams, setData) => {
		let page = 1;
		let allData = [];
		let totalPages = 1;
	
		do {
			requestParams.set("page", page);
	
			try {
				const response = await fetch(`${baseUrl}/student/grade?${requestParams.toString()}`, {
					method: "GET",
					headers: headers,
				});
	
				if (!response.ok) {
					throw new Error("Network Error");
				}
	
				const data = await response.json();
				console.log(data);
				
				allData = [...allData, data.data];
				totalPages = data.pagination?.total_pages || 1;
			} catch (error) {
				console.error("API request failed:", error);
				break;
			}
	
			page++;
		} while (page <= totalPages);
	
		setData(allData);
	};

	
	
	useEffect(() => {
		if (selectedGroup && formattedStartTime && formattedEndTime) {
			const headers = createHeaders();
			const requestParams = new URLSearchParams({
				group_id: selectedGroup,
				date_from: formattedStartTime,
				date_to: formattedEndTime,
			});
	
			if (selectedGroupSubject !== null) {
				requestParams.append("group_subject_id", selectedGroupSubject);
			}
	
			fetchAllPages(consts.API_URL, headers, requestParams, setGetData);
		}
	}, [selectedGroup, selectedGroupSubject, startTime, endTime]);
	
	const dateRange = useRef();

	useEffect(() => {
		function handleClickOutside(event) {
			if (dateRange.current && !dateRange.current.contains(event.target)) {
				setShowCalendar(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);


	const renderSchedule = (groupSubjectId) => {
		const weekDayTranslations = {
			monday: "Bazar ertəsi",
			tuesday: "Çərşənbə axşamı",
			wednesday: "Çərşənbə",
			thursday: "Cümə axşamı",
			friday: "Cümə",
			saturday: "Şənbə",
			sunday: "Bazar",
		};
	
		if (!groupSubjectId) {
			return <p>Dərs seçilməyib</p>;
		}
	
		const selectedLesson = lessons.find((lesson) => lesson.id === Number(groupSubjectId));
	
		if (!selectedLesson || !selectedLesson.schedules || selectedLesson.schedules.length === 0) {
			return <p>Cədvəl mövcud deyil</p>;
		}
	
		return (
			<table className="turn-wrapper">
				<tbody>
					{selectedLesson.schedules.map((schedule, index) => (
						<tr key={`${selectedLesson.id}-${schedule.id}`}>
							<td>
								<span className="turn-left">
									{`${weekDayTranslations[schedule.week_day]}:` || schedule.week_day}
								</span>
							</td>
							<td>
								<span className="turn-right">
									<div>
										{schedule.start_time?.slice(0, 5)} - {schedule.end_time?.slice(0, 5)}
									</div>
								</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	};
	

	const [isMobile, setIsMobile] = useState(false);

	const handleResize = () => {
		if (window.innerWidth <= 768) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<section className="grade-student">
			<div className="grade-student__wrapper ">
				<Row>
					<Col xs={12} md={12} lg={12} xl={8}>
						<div className="group-subjectd-flex d-flex align-items-center position-relative">
							<div className="group-subjectd-item d-flex align-items-center mb-4">
								<p className="group-subject-name">Qrup: </p>
								<Form.Select
									className={`selection ${openSelectGroup ? "active" : ""}`}
									onClick={() => setOpenSelectGroup((prev) => !prev)}
									onChange={handleGroupChange}
									value={selectedGroup || ""}
								>
									{groups?.map((group) => (
										<option key={group.id} value={group.id}>
											{group.name}
										</option>
									))}
								</Form.Select>
							</div>

							<div className="group-subjectd-item d-flex align-items-center mb-4">
								<p className="group-subject-name">Dərs: </p>
								<Form.Select
									className={`selection ${openSelectLesson ? "active" : ""}`}
									onClick={() => setOpenSelectLesson((prev) => !prev)}
									onChange={handleLessonChange}
									value={selectedGroupSubject || ""}
								>
									<option value="">Günlük dərslər</option>
									{Array.isArray(lessons) && lessons.length > 0 ? (
										lessons.map((lesson) =>
											lesson.subject?.translations?.map((translation) => (
												<option key={lesson.id} value={lesson.id}>
													{translation.name}
												</option>
											))
										)
									) : (
										<option value="" disabled>
											Dərs yoxdur
										</option>
									)}
								</Form.Select>
								{selectedGroupSubject !== null && (
									<div className="group-schedules">
										<OverlayTrigger
											placement={isMobile ? "bottom" : "right"}
											rootClose
											trigger="hover"
											overlay={
												<Popover id="popover-positioned-right">
													<Popover.Body style={{ padding: "5px" }}>
														{renderSchedule(selectedGroupSubject)}
													</Popover.Body>
												</Popover>
											}
										>
											<span className="d-inline-block">
												<button className="operation-btn gray-btn text-white">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width="24"
														height="24"
														color="#fff"
														fill="none"
													>
														<circle
															cx="12"
															cy="12"
															r="10"
															stroke="currentColor"
															strokeWidth="1.5"
														/>
														<path
															d="M9.5 9.5L12.9999 12.9996M16 8L11 13"
															stroke="currentColor"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</button>
											</span>
										</OverlayTrigger>
									</div>
								)}
							</div>

							<div className="group-subjectd-item d-flex align-items-center mb-4">
								<p className="group-subject-name">Tarix: </p>
								<div className='position-relative'>
									<button className="week-current" onClick={handleToggleCalendar}>
										{startTime ? `${formattedStartTime} - ${endTime ? formattedEndTime : ''}` : ''}
									</button>

									{showCalendar && (
										<div className="calendar-popup" ref={dateRange}>
											<DatePicker
												selected={startTime}
												onChange={dates => { setStartTime(dates[0]); setEndTime(dates[1]); }}
												startDate={startTime}
												endDate={endTime}
												selectsRange
												inline
												dateFormat="dd.MM.yyyy"
												placeholderText="Başlanğıc və Bitiş Tarixi"
											/>
										</div>
									)}
								</div>
							</div>

						</div>
						<AverageScore data={getData} />
						{percents && (
							<div className="grade-info">
								<span>Qiymət ortalamasının hesablanması</span>
								<div className='grade-btn'>
									<span className="bordered">
										{Number.isInteger(parseFloat(percents.daily_percent))
											? parseInt(percents.daily_percent)
											: percents.daily_percent}% günlük
									</span>
									<span className="bordered">
										{Number.isInteger(parseFloat(percents.quiz_percent))
											? parseInt(percents.quiz_percent)
											: percents.quiz_percent}% quiz
									</span>
									<span className="bordered">
										{Number.isInteger(parseFloat(percents.project_percent))
											? parseInt(percents.project_percent)
											: percents.project_percent}% layihə
									</span>
									<span className="bordered">
										{Number.isInteger(parseFloat(percents.exam_percent))
											? parseInt(percents.exam_percent)
											: percents.exam_percent}% imtahan
									</span>
								</div>
							</div>
						)}
						<LineChartStudent data={getData} />
						<div className="charts mt-5 mb-5">
							<Row>
								<Col md={7}>
									<PieChartStudent data={getData} />
								</Col>
								<Col md={5}>
									<SemicircleProgressBarStudent data={getData} />
									{/* <SemicircleProgressBarStudent2 /> */}
								</Col>
							</Row>
						</div>
						<GradeTableStudent startTime={formattedStartTime} endTime={formattedEndTime} isGeneralSelected={selectedGroupSubject === null} />
						<NotesTableStudent startTime={formattedStartTime} endTime={formattedEndTime} isGeneralSelected={selectedGroupSubject === null} />
					</Col>
					<TopStudent />
				</Row>
			</div>
		</section>
	)
}

export default GradeStudent
