import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Modal, Button, Form, Row, Col, Dropdown } from "react-bootstrap";
import InputMask from "react-input-mask";
import * as consts from "../../consts/Consts";
import {
	fetchSubjects,
	fetchTeachers,
	fetchStudents,
	createHeaders,
} from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import CustomSelect from '../CustomSelect'
import CustomDropdown from "./CustomDropdown";
import DropdownWithPagination from "../DropdownWithPagination";

const EditGroup = ({ show, handleClose, id, data }) => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		teacher_id: "",
		name: "",
		type: "",
		status: "",
		start_date: "",
		students: []
	});

	const handleTeacherChange = (newTeacherId) => {
		setFormData(prevFormData => ({
			...prevFormData,
			teacher_id: newTeacherId,
		}));
	};

	const [errors, setErrors] = useState({});

	const [searchParams, setSearchParams] = useSearchParams();

	const [currentPage, setCurrentPage] = useState(1);

	const pagination = parseInt(searchParams.get('pagination')) || 5;

	useEffect(() => {
		if (!searchParams.get('page') || !searchParams.get('pagination')) {
			setSearchParams({
				page: currentPage,
				pagination: pagination,
			});
		}
	}, [searchParams, setSearchParams, currentPage, pagination]);

	const endOfListRef = useRef(null);

	const resetFormData = () => {
		setFormData({
			teacher_id: "",
			name: "",
			type: "",
			status: "",
			start_date: "",
			students: [
				{
					user_id: "",
				},
			],
		});
		setErrors({});
	};

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target || { name: e.name, value: e.value, type: e.type, checked: e.checked };

		if (type === "checkbox" && name === "student") {
			setFormData((prevData) => {
				const newStudents = checked
					? [...prevData.students, { id: parseInt(value, 10) }]
					: prevData.students.filter((student) => student.id !== parseInt(value, 10));

				return { ...prevData, students: newStudents };
			});
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}

		if (value && errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: undefined,
			}));
		}
	};


	const getDropdownLabel = () => {
		if (loading) {
			return "Yüklənir"
		}
		if (!formData.students || formData.students.length === 0) {
			return "Siyahıdan seçin";
		}

		const studentNames = formData.students
			.map((student) => {
				const foundStudent = students.find(
					(s) => s?.id?.toString() === student?.id?.toString()
				);
				return foundStudent ? foundStudent.full_name : null;
			})
			.filter((name) => !!name);


		return studentNames.length > 0
			? studentNames.join(", ")
			: "Yüklənir";
	};

	const validateForm = () => {
		const newErrors = {};

		if (!formData.name.trim()) {
			newErrors.name = "Qrupun adını daxil edin";
		} else if (formData.name.length > 64) {
			newErrors.name = "Qrupun adı 64 simvoldan çox olmamalıdır.";
		}

		if (!formData.type) {
			newErrors.type = "Tədrisin təşkilini seçin.";
		}

		if (!formData.status) {
			newErrors.status = "Qrupun növünü seçin";
		}

		// if (!formData.start_date) {
		// 	newErrors.start_date = "Başlanma tarixini seçin.";
		// }

		// if (!formData.teacher_id) {
		// 	newErrors.teacher_id = "Müəllimi seçin.";
		// }

		if (formData.students.length === 0) {
			newErrors.student = "Tələbə tələb olunur";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const [getData, setGetData] = useState([]);
	const [teachers, setTeachers] = useState([]);
	const [students, setStudents] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingTeachers, setLoadingTeachers] = useState(true);
	const [dropDownStudent, setDropDownStudent] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchSubjects();
				setGetData(data);
			} catch (error) {
				// console.error("Ошибка при получении данных:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const fetchAllTeachers = async () => {
			let allTeachers = [];
			let currentPage = 1;
			let totalPages = 1;

			try {
				setLoadingTeachers(true)
				while (currentPage <= totalPages) {
					const data = await fetchTeachers(navigate, 50, currentPage, "", "full_name", "asc")
					if (data?.items) {
						allTeachers = [...allTeachers, ...data.items];
					}

					totalPages = data.totalPages;
					currentPage++;
				}

				const uniqueTeachers = allTeachers.filter((value, index, self) =>
					index === self.findIndex((t) => t.id === value.id)
				);

				setTeachers(uniqueTeachers);
				setLoadingTeachers(false)
			} catch (error) {
				console.error("Error:", error);
				setLoadingTeachers(false)
			} finally {
				setLoadingTeachers(false)
			}
		};

		fetchAllTeachers();
	}, []);

	useEffect(() => {
		const fetchAllStudents = async () => {
			let allStudents = [];
			let currentPage = 1;
			let totalPages = 1;

			try {
				setLoading(true);
				while (currentPage <= totalPages) {
					const data = await fetchStudents(navigate, 50, currentPage, "", "created_at", "desc");
					if (data?.items) {
						allStudents = [...allStudents, ...data.items];
					}

					totalPages = data.totalPages;
					currentPage++;
				}

				const uniqueStudents = allStudents.filter((value, index, self) =>
					index === self.findIndex((t) => t.id === value.id)
				);

				setStudents(uniqueStudents);
				setLoading(false);
			} catch (error) {
				console.error("Error:", error);
				setLoading(false);
			} finally {
				setLoading(false);
			}
		};

		fetchAllStudents();
	}, []);


	const parseDate = (dateString) => {
		const [day, month, year] = dateString.split(".");
		return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
	};

	const handleGetData = async () => {
		try {
			setLoading(true);
			const headers = createHeaders();
			const response = await fetch(`${consts.API_URL}/admin/group/${data.id}`, {
				headers,
			});

			if (!response.ok) {
				throw new Error("Ошибка при получении данных");
			}

			const result = await response.json()
			setFormData({
				name: result.data.name || "",
				teacher_id: result.data.teacher?.id || "",
				students: result.data.students || [],
				type: result.data.type || "",
				status: result.data.status || "",
				start_date: result.data.start_date,
			});
			setLoading(false);
		} catch (error) {
			handleUnauthorizedError(error, navigate);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		handleGetData();
	}, [show, data?.id]);

	const handleSubmit = async () => {
		if (validateForm()) {
			const headers = createHeaders();

			const formattedStudents = formData.students.map((student) => ({
				user_id: student.id,
			}));
			try {
				const response = await fetch(`${consts.API_URL}/admin/group/${id}`, {
					method: "PUT",
					headers,
					body: JSON.stringify({
						status: formData.status,
						teacher_id: formData.teacher_id,
						name: formData.name,
						type: formData.type,
						start_date: formData.start_date,
						students: formattedStudents,
					}),
				});

				const data = await response.json();

				if (data.errors && data.errors.name && data.errors.name.includes("The name has already been taken.")) {
					setErrors((prevErrors) => ({
						...prevErrors,
						name: "Bu qrup adı artıq istifadə olunur.",
					}));
				} else {
					handleCloseModal();
					window.location.reload();
				}
			} catch (error) {
				// console.error("Error updating group:", error);
			}
		}
	};


	const [isFirstSubmission, setIsFirstSubmission] = useState(true);

	const fetchSelectedTeacher = async (teacherId) => {
		try {
			const response = await fetchTeachers(navigate, pagination, 1, teacherId);
			return response.items.find(teacher => teacher.id === teacherId) || null;
		} catch (error) {
			// console.error('Error fetching selected teacher:', error);
			return null;
		}
	};


	const [hasMore, setHasMore] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const fetchMoreData = async () => {
		if (isLoading || !hasMore) return;

		try {
			setIsLoading(true);
			const nextPage = currentPage + 1;
			const newTeachers = await fetchTeachers(navigate, pagination, nextPage);

			if (newTeachers.items.length < pagination) {
				setHasMore(false);
			}

			setTeachers(prevTeachers => {
				const uniqueTeachers = newTeachers.items.filter(
					teacher => !prevTeachers.some(prev => prev.id === teacher.id)
				);
				return [...prevTeachers, ...uniqueTeachers];
			});

			setCurrentPage(nextPage);
		} catch (error) {
			// console.error('Error fetching more data:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleCloseModal = () => {
		resetFormData();
		setCurrentPage(1);
		setTeachers([]);
		setHasMore(true);
		setIsLoading(false);
		if (isFirstSubmission) {
			handleClose();
		} else {
			window.location.reload();
			handleClose();
		}
	};

	useEffect(() => {
		if (show) {
			setCurrentPage(1);
			setTeachers([]);
			setHasMore(true);
			setIsLoading(false);

			const fetchInitialData = async () => {
				try {
					setIsLoading(true);
					const initialData = await fetchTeachers(navigate, pagination, 1);
					setTeachers(initialData.items);
					if (initialData.items.length < pagination) {
						setHasMore(false);
					}
				} catch (error) {
					// console.error('Error fetching initial data:', error);
				} finally {
					setIsLoading(false);
				}
			};

			fetchInitialData();
		}
	}, [show, navigate, pagination]);



	return (
		<Modal show={show} onHide={handleCloseModal} backdrop="static">
			<Modal.Header closeButton>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row className="mb-4">
						<Col>
							<Form.Group controlId="formName">
								<Form.Label>Qrupun adı</Form.Label>
								<Form.Control
									type="text"
									name="name"
									value={formData.name}
									onChange={handleChange}
									placeholder="Qrupun adını daxil edin"
									isInvalid={!!errors.name}
									className={errors.name ? "input-error" : ""}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.name}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>

						<Col>
							<Form.Group controlId="formTeacher" className="select-form">
								<Form.Label>Sinif rəhbərini seçin</Form.Label>
								<CustomSelect
									options={
										loading
											? [{ value: "", label: "Yüklənir" }]
											: teachers?.map(teacher => ({ value: teacher.id, label: teacher.full_name }))
									}
									value={
										formData.teacher_id
											? { value: formData.teacher_id, label: teachers.find(teacher => teacher.id === formData.teacher_id)?.full_name || "" }
											: null
									}
									onChange={(selected) => handleChange({ name: 'teacher_id', value: selected ? selected.value : "" })}
									isInvalid={!!errors.teacher_id}
									placeholder={loading ? "Yüklənir" : "Siyahıdan seçin"}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.teacher_id}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>

					</Row>

					<Row className="mb-4">
						<Col>
							<Form.Group controlId="formType" className="select-form">
								<Form.Label>Tədrisin təşkili</Form.Label>
								<CustomSelect
									options={[
										{ value: 'group', label: 'Qrup' },
										{ value: 'individual', label: 'Fərdi' },
									]}
									value={
										formData.type
											? { value: formData.type, label: formData.type === 'group' ? 'Qrup' : 'Fərdi' }
											: null
									}
									onChange={(selected) => handleChange({ name: 'type', value: selected ? selected.value : '' })}
									isInvalid={!!errors.type}
									placeholder="Siyahıdan seçin"
								/>
								<Form.Control.Feedback type="invalid">
									{errors.type}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group controlId="formStatus" className="select-form">
								<Form.Label>Qrupun növü</Form.Label>
								<CustomSelect
									options={[
										{ value: 'online', label: 'Onlayn' },
										{ value: 'offline', label: 'Oflayn' },
										{ value: 'hybrid', label: 'Hibrid' },
									]}
									value={
										formData.status
											? {
												value: formData.type, label: formData.status === 'online' ? 'Onlayn'
													: formData.status === 'offline' ? 'Oflayn'
														: 'Hibrid'
											}
											: null
									}
									onChange={(selected) => handleChange({ name: 'status', value: selected ? selected.value : '' })}
									isInvalid={!!errors.status}
									placeholder="Siyahıdan seçin"
								/>
								<Form.Control.Feedback type="invalid">
									{errors.status}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>

					<Row className="mb-4">
						<Col style={{ width: "50%" }}>
							<Form.Group controlId="formGroupStudent" >
								<Form.Label>Tələbəni seç</Form.Label>
								<div className="position-relative">
									<Dropdown onClick={() => setDropDownStudent((prev) => !prev)}>
										<Dropdown.Toggle
											id="dropdown-basic"
											className={`select-multiple ${errors.student ? "border-red" : ""
												}`}
										>
											<div>
												{getDropdownLabel()}
											</div>
											{dropDownStudent ? (
												<svg
													width="14"
													height="8"
													viewBox="0 0 14 8"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M1 7L7 1L13 7"
														stroke="#6F767E"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											) : (
												<svg
													width="14"
													height="8"
													viewBox="0 0 14 8"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M1 1L7 7L13 1"
														stroke="#6F767E"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											)}
										</Dropdown.Toggle>

										<Dropdown.Menu className="px-2 add-dropdown-menu">
											{students?.length === 0 ? (
												<Dropdown.Item disabled>
													Yüklənir
												</Dropdown.Item>
											) : students?.length > 0 ? (
												students.map((student) => (
													<Form.Check
														key={student.id}
														type="checkbox"
														id={`student-${student.id}`}
														label={student.full_name}
														name="student"
														value={student.id}
														className="custom-switch"
														checked={formData.students.some(
															(item) => item.id === student.id
														)}
														onChange={handleChange}
													/>
												))
											) : (
												<Dropdown.Item disabled>
													Yüklənir
												</Dropdown.Item>
											)}
										</Dropdown.Menu>
									</Dropdown>
								</div>{" "}
								<div className="text-danger mt-2">
									{errors.student && <div>{errors.student}</div>}{" "}
								</div>
							</Form.Group>
						</Col>

						<Col>
							<Form.Group controlId="formStartDate" className="select-form">
								<Form.Label>Başlanma tarixi</Form.Label>
								<Form.Control
									type="date"
									name="start_date"
									value={formData.start_date}
									onChange={handleChange}
									isInvalid={!!errors.start_date}
									className={`pr-5 ${errors.start_date ? "input-error" : ""}`}
								/>

								<Form.Control.Feedback type="invalid">
									{errors.start_date}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>

					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex w-100" style={{ gap: "5px" }}>
					<Button className="modal-btn btn-green" onClick={handleSubmit}>
						Qrupu düzəliş et
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default EditGroup;
