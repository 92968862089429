import React from "react";

function NotesTableItemStudent({ data, isGeneralSelected }) {
	return (
		<>
			{Array.isArray(data) && data.length > 0 && (
				data.map((gradeItem, index) =>
					gradeItem.note ? (
						<tr key={index}>
							<td>
								<div className="left column-content">{gradeItem.date}</div>
							</td>
							{isGeneralSelected && (
								<td>
									<div className="column-content">{gradeItem.group_subject?.subject?.translations[0]?.name}</div>
								</td>
							)}
							<td>
								<div className="column-content right" style={{ overflow: "hidden" }}>
									<p
										className="column-note"
										style={{
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
											width: "100%",
											maxWidth: "400px",
										}}
										title={gradeItem.note}
									>
										{gradeItem.note}
									</p>
								</div>
							</td>
						</tr>
					) : null
				)
			)}
		</>
	);
}

export default NotesTableItemStudent;
