import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GroupActionModal from "./GroupActionModal";

function MyGroups({ getData }) {
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [selectedType, setSelectedType] = useState("daily");
	const [sortBy, setSortBy] = useState("sort[students.full_name]");
	const [sortDirection, setSortDirection] = useState("asc");

	const handleGroupClick = (groupId) => {
		if (window.innerWidth <= 768) {
			setSelectedGroup(groupId);
			setShowModal(true);
		} else {
			navigate("/grade", { state: { group_id: groupId } });
		}
	};

	const handleCloseModal = () => setShowModal(false);
	if (!getData || !Array.isArray(getData.groups) || getData.groups.length === 0) {
		return <p>Hazırda qrupunuz yoxdur.</p>;
	}

	return (
		<>
		{showModal && (
			<GroupActionModal
			show={showModal}
			handleClose={handleCloseModal}
			selectedGroup={selectedGroup}
			/>
		)}
			{getData.groups.map((group) => (
				<Col key={group.id} md={6} className="mb-4">
					<div
						className="user__groups-item"
						onClick={() => handleGroupClick(group.id)}
						style={{ cursor: 'pointer' }} // Make it look clickable
					>
						<p className="user__groups-date">Başlanma tarixi: {group.start_date}</p>
						<h4 className="user__groups-name">{group.name}</h4>
					</div>
				</Col>
			))}
		</>
	);
}

export default MyGroups;
