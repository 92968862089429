import React, { useState } from "react";

function GradeTableItemStudent({ data, rol, isGeneralSelected }) {
	return (
		<>
			{Array.isArray(data) && data.length > 0 && (
				data.map((gradeItem, index) => (
					<tr key={index}>
						<td>
							<div className="left column-content">{gradeItem.date}</div>
						</td>
							{isGeneralSelected && (
								<td>
								<div className="column-content" >{gradeItem.group_subject?.subject?.translations[0]?.name}</div>
								</td>
							)}
						<td>
							<div
								className="column-content"

							>
								<span className="column-content-grade"
									style={{
										color: gradeItem.grade ? "#141518" : "#FFFFFF",
										backgroundColor: gradeItem.grade
											? "#1AE5BE"
											: gradeItem.attendance === 1
												? "#246CF9"
												: gradeItem.attendance === 0
													? "#FC5A5A"
													: ""
									}}
								>
									{gradeItem.grade ? gradeItem.grade : !gradeItem.grade && gradeItem.attendance === 1 ? "+" : !gradeItem.grade && gradeItem.attendance === 0 ? "Q" : ""}
								</span>
							</div>
						</td>
						<td>
							<div className="column-content right"  style={{ justifyContent: "center" }}>
								{gradeItem && gradeItem.status === 'daily' ? 'Gündəlik' :
									gradeItem && gradeItem.status === 'quiz' ? 'Quiz' :
										gradeItem && gradeItem.status === 'project' ? 'Layihə' :
											gradeItem && gradeItem.status === 'exam' ? 'İmtahan' :
												"Məlumat tapılmadı"
								}
							</div>

						</td>
					</tr>
				))
			)}

		</>
	);
}

export default GradeTableItemStudent;
