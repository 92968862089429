import { EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Dropdown } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { fetchTeacherGroups, fetchTeacherSubjectLessons, fetchTeacherLessons } from "../../functions/apiService";
import { Link, useNavigate } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as consts from "../../consts/Consts";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { createHeaders, ensureValidToken } from "../../functions/apiService";
import { stateToHTML } from "draft-js-export-html";
import CustomSelect from '../CustomSelect'

const AddExerciseModal = ({ show, handleClose, initialSelectedGroup, initialSelectedSubject, lessonClicked }) => {
	const [selectedGroup, setSelectedGroup] = useState(initialSelectedGroup);
	const [selectedSubject, setSelectedSubject] = useState(initialSelectedSubject);
	// const [selectedGroupSubject, setSelectedGroupSubject] = useState('')
	const [filteredGroups, setFilteredGroups] = useState([]);
	const [selectedGroupData, setSelectedGroupData] = useState(null);

	useEffect(() => {
		if (initialSelectedGroup !== selectedGroup) {
			setSelectedGroup(initialSelectedGroup);
		}
	}, [initialSelectedGroup]);

	useEffect(() => {
		if (initialSelectedSubject !== selectedSubject) {
			setSelectedSubject(initialSelectedSubject);
		}
	}, [initialSelectedSubject]);

	const navigate = useNavigate();
	const [editorState, setEditorState] = useState(() =>
		EditorState.createEmpty()
	);
	const [errors, setErrors] = useState({ content: "" });

	const handleEditorStateChange = (state) => {
		setEditorState(state);

		const currentContent = state.getCurrentContent();
		const htmlContent = stateToHTML(currentContent);

		setFormData((prevFormData) => ({
			...prevFormData,
			content: htmlContent,
		}));
	};

	const [foundGroup, setFoundGroup] = useState(null); // Используем null вместо []

	useEffect(() => {
		setFoundGroup(
			filteredGroups?.find(
				(g) => g?.groupID?.toString() === initialSelectedGroup?.toString()
			) || null
		);
	}, [filteredGroups, initialSelectedGroup]); // Добавляем зависимости


	const [formData, setFormData] = useState({
		attachments: [],
		name: "",
		content: "",
		subject_id: null,
		type: "group",
		students: [],
		group_subjects: initialSelectedGroup && foundGroup
			? [{ group_subject_id: foundGroup.groupSubjectID.toString() }]
			: [],
		start_date: new Date().toISOString().split("T")[0],
		end_date: "",
		is_draft: null,
		group: selectedGroup || null
	});

	// Функция сброса формы
	const resetFormData = () => {
		const updatedFoundGroup = filteredGroups?.find(
			(g) => g?.groupID.toString() === initialSelectedGroup?.toString()
		) || null;

		setFormData({
			attachments: [],
			name: "",
			content: "",
			subject_id: selectedSubject || null,
			type: "group",
			students: [
				{
					student_id: "",
				},
			],
			group_subjects: initialSelectedGroup && updatedFoundGroup
				? [{ group_subject_id: updatedFoundGroup.groupSubjectID.toString() }]
				: [],
			start_date: new Date().toISOString().split("T")[0],
			end_date: "",
			is_draft: null,
			group: selectedGroup || null
		});

		setEditorState(EditorState.createEmpty());
		setErrors({});
		setSelectedSubject(initialSelectedSubject);
	};

	useEffect(() => {
		const updatedFoundGroup = filteredGroups?.find(
			(g) => g?.groupID.toString() === selectedGroup?.toString()
		) || null;
		setSelectedGroupData(updatedFoundGroup);

		if (updatedFoundGroup) {
			setFormData((prevFormData) => ({
				...prevFormData,
				group_subjects: [{ group_subject_id: updatedFoundGroup.groupSubjectID.toString() }],
			}));
		}
	}, [selectedGroup, filteredGroups, lessonClicked, selectedSubject]);

	// useEffect(() => {
	// 	if (selectedGroup) {
	// 		setFormData((prevFormData) => ({
	// 			...prevFormData,
	// 			group_subjects: [{ group_subject_id: selectedGroup.toString() }],
	// 		}));
	// 	}
	// }, [selectedGroup]);

	useEffect(() => {
		if (selectedSubject || (selectedSubject && lessonClicked)) {
			setFormData((prevFormData) => ({
				...prevFormData,
				subject_id: selectedSubject.toString(),
			}));
		}
	}, [selectedSubject, lessonClicked]);

	useEffect(() => {
		if (selectedGroup || (selectedGroup && lessonClicked)) {
			if (formData.group_subjects.length === 1) {
				setSelectedGroup(formData.group);
			} else {
				// setSelectedGroup(null);
			}
		}
	}, [selectedGroup, formData.group_subjects, lessonClicked]);


	const [dropDownStudent, setDropDownStudent] = useState(false);
	const [dropDownGroup, setDropDownGroup] = useState(false);
	const [groups, setGroups] = useState([]);
	const [students, setStudents] = useState([]);
	const [subjects, setSubjects] = useState([]);
	const [loading, setLoading] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const toolbarOptions = {
		options: [
			"inline",
			"emoji",
			"list",
			"textAlign",
			"link",
			"remove",
			"history",
		],
	};

	useEffect(() => {
		if (selectedSubject || (selectedSubject && lessonClicked)) {
			setFilteredGroups(groups.filter(group => group.subjectID === selectedSubject));
		} else {
			setFilteredGroups([]);
		}
		// setSelectedGroup(null);
	}, [selectedSubject, groups, selectedGroup, lessonClicked]);

	// useEffect(() => {
	// 	if (lessonClicked) {
	// 		setFormData(prev => ({
	// 			...prev,
	// 			group_subjects: []
	// 		}));
	// 	}

	// }, [lessonClicked]);


	const handleCloseModal = () => {
		setSelectedGroup(initialSelectedGroup);
		resetFormData();
		handleClose();
	};

	const formatDate = (dateString) => {
		const [year, month, day] = dateString.split("-");
		return `${day}.${month}.${year}`;
	};

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;

		if (name === "file") {
			const newFiles = Array.from(e.target.files);
			const uniqueFiles = newFiles.filter(
				(f) => !formData.files.some((file) => file.name === f.name)
			);

			setFormData((prevData) => ({
				...prevData,
				attachments: [...prevData.attachments, ...uniqueFiles],
			}));
		} else {
			let updatedValue = value;
			if (name === "start_date" || name === "end_date") {
				updatedValue = formatDate(value);
			}

			setFormData((prevData) => ({
				...prevData,
				[name]: updatedValue,
			}));

			if (updatedValue.trim() && errors[name]) {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: undefined,
				}));
			}
		}

		if (type === "checkbox") {
			if (formData.type === "group") {
				setFormData((prevData) => {
					const newGroup = checked
						? [...prevData.group_subjects, { group_subject_id: value }]
						: prevData.group_subjects.filter((item) => item.group_subject_id !== value);

					if (selectedGroupData && !newGroup.some((item) => item.group_subject_id.toString() === selectedGroupData.groupSubjectID.toString())) {
						// setSelectedGroupData(null);
						// setSelectedGroup(null);
					}

					return { ...prevData, group_subjects: newGroup, students: [] };
				});
			}
			else if (formData.type === "user") {
				setFormData((prevData) => {
					const newStudent = checked
						? [...prevData.students, { student_id: value }]
						: prevData.students.filter((item) => item.student_id !== value);
					return { ...prevData, students: newStudent, groups: [] };
				});
			}
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}

		if (value.trim() && errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: undefined,
			}));
		}
	};


	useEffect(() => {
		const fetchData = async () => {
			try {
				const [subjectData, groupData] = await Promise.all([
					fetchTeacherSubjectLessons(navigate),
					// fetchTeacherGroups(navigate),
					fetchTeacherLessons(navigate)
				]);

				const extractedSubjects = subjectData.map(item => ({
					id: item.id,
					name: item.translations.find(t => t.language_id === 1)?.name || "Məlumat tapılmadı",
				}));

				setSubjects(extractedSubjects);

				const formattedGroups = groupData.map(item => ({
					groupSubjectID: item.id,
					subjectID: item.subject.id,
					// name: item.name,
					groupID: item.group.id,
					groupName: item.group.name,
				})).sort((a, b) => a.groupName.localeCompare(b.groupName)); ;

				setGroups(formattedGroups);

			} catch (error) {
				// console.error("Ошибка при загрузке данных:", error);
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};
	  
		fetchData();
	  }, [navigate]);
	  

	const getDropdownLabel = (name) => {
		if (name === "group") {
			if (selectedGroup || (selectedGroup && lessonClicked)) {
				const foundGroup = filteredGroups?.find(
					(g) => g.groupID.toString() === selectedGroup.toString()
				);
				return foundGroup ? foundGroup.groupName : "Qrup tapılmadı";
			}

			if (formData.group_subjects.length === 0) {
				return "Siyahıdan seçin";
			}

			return formData.group_subjects
				.map((group) => {
					const foundGroup = filteredGroups.find(
						(g) => g.groupSubjectID.toString() === group.group_subject_id
					);
					return foundGroup ? foundGroup.groupName : null;
				})
				.filter((name) => name)
				.join(", ");
		} else if (name === "user") {
			if (formData.students.length === 0) {
				return "Siyahıdan seçin";
			}
			return formData.students
				.map((student) => {
					const foundStudent = students.find(
						(s) => s?.id.toString() === student.student_id
					);

					return foundStudent ? foundStudent.full_name : null;
				})
				.filter((name) => name)
				.join(", ");
		}
	};
	const validateForm = () => {
		const newErrors = {};

		if (!formData.name.trim()) {
			newErrors.name = "Tapşırığın adı tələb olunur";
		}

		if (!formData.subject_id) {
			newErrors.subject_id = "Fənn tələb olunur";
		}

		if (!formData.content.trim() && formData.attachments.length === 0) {
			newErrors.content = "Məzmun tələb olunur";
			newErrors.attachments = "Fayl tələb olunur";
		}

		if (!formData.type) {
			newErrors.type = "İştirakçı tipi tələb olunur";
		} else if (formData.type === "group" && formData.group_subjects.length === 0) {
			newErrors.group = "Qrup tələb olunur";
		} else if (formData.type === "user" && formData.students.length === 0) {
			newErrors.student = "Tələbə tələb olunur";
		}

		if (!formData.start_date) {
			newErrors.start_date = "Başlama tarixi tələb olunur";
		}

		if (!formData.end_date) {
			newErrors.end_date = "Bitmə tarixi tələb olunur";
		}

		const today = new Date();
		today.setHours(0, 0, 0, 0);

		const startDate = new Date(formData.start_date);
		const endDate = new Date(formData.end_date);

		if (startDate < today) {
			newErrors.start_date = "Keçmiş gün seçilə bilməz";
		}

		if (endDate < startDate) {
			newErrors.end_date = "Bitmə tarixi başlanğıc tarixindən əvvəl ola bilməz";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleDrop = async (event) => {
		event.preventDefault();
		const newFiles = Array.from(event.dataTransfer.files);

		if (newFiles.length > 0) {
			const uploadedFiles = await Promise.all(
				newFiles.map(async (file) => {
					if (!isFileAlreadyUploaded(file)) {
						const fileData = new FormData();
						fileData.append("file", file);
						fileData.append("folder", "task");

						const response = await uploadFile(fileData);
						return {
							name: file.name,
							size: (file.size / (1024 * 1024)).toFixed(2) + " MB",
							path: response.data.path,
						};
					}
					return null;
				})
			);

			const validFiles = uploadedFiles.filter((file) => file !== null);
			setFormData((prevData) => ({
				...prevData,
				attachments: [...prevData.attachments, ...validFiles],
			}));
		}
	};

	const [avatar, setAvatar] = useState("");
	const [image, setImage] = useState([]);

	useEffect(() => {
		if (avatar) {
			const fileReader = new FileReader();
			fileReader.onload = () =>
				setImage((prev) => [...prev, fileReader.result]);
			fileReader.readAsDataURL(avatar);
		}
	}, [avatar]);

	const handleFileChange = async (e) => {
		const selectedFiles = Array.from(e.target.files);

		if (selectedFiles.length > 0) {
			const uploadedFiles = await Promise.all(
				selectedFiles.map(async (file) => {
					if (!isFileAlreadyUploaded(file)) {
						const fileData = new FormData();
						fileData.append("file", file);
						fileData.append("folder", "task");

						const response = await uploadFile(fileData);
						return {
							name: file.name,
							size: (file.size / (1024 * 1024)).toFixed(2) + " MB",
							path: response.data.path,
						};
					}
					return null;
				})
			);

			const validFiles = uploadedFiles.filter((file) => file !== null);
			setFormData((prevData) => ({
				...prevData,
				attachments: [...prevData.attachments, ...validFiles],
			}));
		}
	};

	const uploadFile = async (fileData) => {
		setIsLoading(true);
		let headers = createHeaders();
		delete headers["Content-Type"];

		try {
			const response = await fetch(`${consts.API_URL}/site/uploader`, {
				method: "POST",
				headers: headers,
				body: fileData,
			});

			if (!response.ok) {
				throw new Error("Ошибка при загрузке файла");
			}

			const result = await response.json();
			return result;
		} catch (error) {
			// console.error("Ошибка при загрузке файла:", error);
			return { data: { path: "" } };
		} finally {
			setIsLoading(false);
		}
	};

	const isFileAlreadyUploaded = (file) => {
		return formData.attachments.some(
			(attachedFile) => attachedFile.name === file.name
		);
	};
	const handleRemoveFile = (filePath, event) => {
		event.preventDefault();
		setFormData((prevData) => ({
			...prevData,
			attachments: prevData.attachments.filter(
				(file) => file.path !== filePath
			),
		}));
	};

	const handleSubmit = async (isDraft) => {
		if (!validateForm()) {
			return;
		}

		const token = await ensureValidToken(navigate);
		const headers = createHeaders();

		const formatDate = (dateStr) => {
			const [year, month, day] = dateStr.split("-");
			return `${day}.${month}.${year}`;
		};

		const requestData = {
			type: formData.type,
			subject_id: parseInt(formData.subject_id),
			name: formData.name,
			start_date: formatDate(formData.start_date),
			end_date: formatDate(formData.end_date),
			is_draft: parseInt(isDraft),
		};

		if (formData.content && formData.content.trim() !== "") {
			requestData.content = formData.content;
		}

		if (formData.attachments && formData.attachments.length > 0) {
			requestData.attachments = formData.attachments.map((file) => ({
				name: file.name,
				size: file.size,
				path: file.path,
			}));
		}

		if (formData.type === "user") {
			if (formData.student_id) {
				requestData.students = [
					{
						student_id: formData.student_id,
					},
				];
			}
		}

		if (formData.type === "group") {
			requestData.group_subjects = formData.group_subjects;
		}
		try {
			const response = await fetch(`${consts.API_URL}/teacher/task`, {
				method: "POST",
				headers: headers,
				body: JSON.stringify(requestData),
			});

			// console.log("Request Data:", requestData);

			if (!response.ok) {
				const contentType = response.headers.get("content-type");

				if (contentType && contentType.includes("application/json")) {
					const errorData = await response.json();
				} else {
					// throw new Error("Ошибка сети или сервер вернул неверный формат.");
				}
			} else {
				const data = await response.json();
				// console.log("Данные успешно отправлены:", data);
				window.location.reload();
			}
		} catch (error) {
			// console.error("Ошибка при отправке запроса:", error);
			handleUnauthorizedError(error);
		}
	};

	return (
		<Modal show={show} onHide={handleCloseModal} backdrop="static">
			<Modal.Header closeButton>
				<div className="modal-title">
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M4 9.48513L8.57372 14L16 5"
							stroke="#696974"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					<span>Yeni tapşırıq</span>
				</div>
			</Modal.Header>
			<Modal.Body>
				<Form className="edit-profile-password-form">
					<Form.Group controlId="formInput1" className="mt-5">
						<Form.Label>Tapşırığın adı</Form.Label>
						<Form.Control
							type="text"
							name="name"
							value={formData.name}
							onChange={handleChange}
							className={`${errors.name ? "border-red" : ""}`}
						/>
						<div className="error-message">{errors.name}</div>
					</Form.Group>

					<Form.Group controlId="formInput2" className="mt-5">
						<Form.Label>Məzmun</Form.Label>
						<div
							className={`textarea-container ${errors.content ? "border-red" : ""
								}`}
						>
							<Editor
								editorState={editorState}
								onEditorStateChange={handleEditorStateChange}
								toolbar={toolbarOptions}
							/>
						</div>
						<div className="error-message">{errors.content}</div>
					</Form.Group>

					<Form.Group controlId="formInput1" className="mt-5">
						<div className="label">Maksimum 10 mb</div>
						<div
							className="file-inner"
							onDrop={handleDrop}
							onDragOver={handleDragOver}
						>
							<label
								htmlFor="file"
								className={`file-label ${errors.text ? "border-red" : ""}`}
							>
								<div>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M17.2072 9.2905C17.5977 9.68103 17.5977 10.3142 17.2072 10.7047C16.8167 11.0952 16.1835 11.0952 15.793 10.7047L13.002 7.91377V15C13.002 15.5523 12.5543 16 12.002 16C11.4498 16 11.002 15.5523 11.002 15V7.91368L8.20906 10.7067C7.81854 11.0972 7.18537 11.0972 6.79485 10.7067C6.40432 10.3161 6.40432 9.68298 6.79485 9.29245L11.2949 4.79241C11.4824 4.60487 11.7368 4.49951 12.002 4.49951C12.2672 4.49951 12.5216 4.60487 12.7091 4.79241L17.2072 9.2905Z"
											fill="#1A1D1F"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M4 14C4.55228 14 5 14.4477 5 15V17C5 17.5523 5.44772 18 6 18H18C18.5523 18 19 17.5523 19 17V15C19 14.4477 19.4477 14 20 14C20.5523 14 21 14.4477 21 15V17C21 18.6569 19.6569 20 18 20H6C4.34315 20 3 18.6569 3 17V15C3 14.4477 3.44772 14 4 14Z"
											fill="#1A1D1F"
										/>
									</svg>
									<span>Fayl yüklə</span>
								</div>
							</label>
							<input
								type="file"
								id="file"
								name="file"
								className="file-input"
								multiple
								onChange={handleFileChange}
							/>
						</div>
						<div>
							{formData.attachments && (
								<div>
									<ul className="file-download-list">
										{formData.attachments.map((file, id) => (
											<li key={id}>
												<div className="file-download-wrapper">
													<Link
														to={`${consts.API_URL_IMG}/${file.path}`}
														title={file.name}
														className="file-download-inner"
														target="_blank"
													>
														<div className="file-download">
															{file.path && file.path.includes("doc") && (
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="50"
																	height="50"
																	viewBox="0 0 30 20"
																	color="#B3B3B3"
																	fill="none"
																>
																	<path
																		d="M21 15.0163C20.9544 14.0244 20.2766 14 19.3571 14C17.9407 14 17.7059 14.3384 17.7059 15.6667V17.3333C17.7059 18.6616 17.9407 19 19.3571 19C20.2766 19 20.9544 18.9756 21 17.9837M10.2949 16.5C10.2949 17.8807 9.18876 19 7.82429 19C7.51642 19 7.36248 19 7.24782 18.933C6.9733 18.7726 7.00076 18.448 7.00076 18.1667V14.8333C7.00076 14.552 6.9733 14.2274 7.24782 14.067C7.36248 14 7.51642 14 7.82429 14C9.18876 14 10.2949 15.1193 10.2949 16.5ZM14 19C13.2236 19 12.8354 19 12.5941 18.7559C12.3529 18.5118 12.3529 18.119 12.3529 17.3333V15.6667C12.3529 14.881 12.3529 14.4882 12.5941 14.2441C12.8354 14 13.2236 14 14 14C14.7764 14 15.1646 14 15.4059 14.2441C15.6471 14.4882 15.6471 14.881 15.6471 15.6667V17.3333C15.6471 18.119 15.6471 18.5118 15.4059 18.7559C15.1646 19 14.7764 19 14 19Z"
																		stroke="currentColor"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																	/>
																	<path
																		d="M19 11C19 10 19 9.4306 18.8478 9.06306C18.6955 8.69552 18.4065 8.40649 17.8284 7.82843L13.0919 3.09188C12.593 2.593 12.3436 2.34355 12.0345 2.19575C11.9702 2.165 11.9044 2.13772 11.8372 2.11401C11.5141 2 11.1614 2 10.4558 2C7.21082 2 5.58831 2 4.48933 2.88607C4.26731 3.06508 4.06508 3.26731 3.88607 3.48933C3 4.58831 3 6.21082 3 9.45584V14C3 17.7712 3 19.6569 4.17157 20.8284C5.34315 22 7.22876 22 11 22H19M12 2.5V3C12 5.82843 12 7.24264 12.8787 8.12132C13.7574 9 15.1716 9 18 9H18.5"
																		stroke="currentColor"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																</svg>
															)}

															{file.path && file.path.includes("zip") && (
																<svg
																	width="35"
																	height="35"
																	viewBox="0 0 60 73"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M50.5384 72.8594H9.41332C7.02615 72.8565 4.73757 71.907 3.04958 70.219C1.3616 68.531 0.412033 66.2424 0.40918 63.8552V9.62914C0.412033 7.24197 1.3616 4.95339 3.04958 3.2654C4.73757 1.57742 7.02615 0.627853 9.41332 0.625H36.3844C36.7384 0.62484 37.089 0.694421 37.4161 0.82977C37.7432 0.965119 38.0405 1.16358 38.2909 1.41383L58.7537 21.8766C59.004 22.127 59.2024 22.4243 59.3378 22.7514C59.4731 23.0785 59.5427 23.4291 59.5425 23.7831V63.8534C59.5402 66.2409 58.5908 68.53 56.9028 70.2183C55.2147 71.9067 52.9259 72.8565 50.5384 72.8594ZM9.41332 6.01563C8.45525 6.01658 7.53669 6.39759 6.85923 7.07505C6.18177 7.75251 5.80076 8.67107 5.7998 9.62914V63.8552C5.80076 64.8133 6.18177 65.7319 6.85923 66.4093C7.53669 67.0868 8.45525 67.4678 9.41332 67.4688H50.5384C51.4965 67.4678 52.415 67.0868 53.0925 66.4093C53.77 65.7319 54.151 64.8133 54.1519 63.8552V24.899L35.2686 6.01563H9.41332Z"
																		fill="#B3B3B3"
																	/>
																	<path
																		d="M56.8471 26.4784H42.6931C40.3059 26.4756 38.0174 25.526 36.3294 23.838C34.6414 22.15 33.6918 19.8615 33.689 17.4743V3.32031C33.689 2.60547 33.9729 1.91991 34.4784 1.41444C34.9839 0.90897 35.6694 0.625 36.3843 0.625C37.0991 0.625 37.7847 0.90897 38.2902 1.41444C38.7956 1.91991 39.0796 2.60547 39.0796 3.32031V17.4743C39.0805 18.4324 39.4616 19.3509 40.139 20.0284C40.8165 20.7058 41.735 21.0869 42.6931 21.0878H56.8471C57.5619 21.0878 58.2475 21.3718 58.753 21.8773C59.2584 22.3827 59.5424 23.0683 59.5424 23.7831C59.5424 24.498 59.2584 25.1835 58.753 25.689C58.2475 26.1945 57.5619 26.4784 56.8471 26.4784Z"
																		fill="#B3B3B3"
																	/>
																	<path
																		d="M18.7034 53.0758H25.1721C25.8909 53.0758 26.2898 53.9329 26.2898 54.693C26.2898 55.453 25.8855 56.3101 25.1721 56.3101H15.0396C14.2777 56.3101 13.9614 55.9058 13.9614 55.3362C13.9843 54.8732 14.1234 54.4233 14.3657 54.0281L21.0735 42.158H15.5624C14.8437 42.158 14.4448 41.3979 14.4448 40.5408C14.4448 39.7573 14.7772 38.9236 15.5624 38.9236H24.7445C25.4632 38.9236 25.8226 39.3279 25.8226 39.8993C25.7971 40.3619 25.6582 40.8111 25.4183 41.2074L18.7034 53.0758Z"
																		fill="#B3B3B3"
																	/>
																	<path
																		d="M27.7881 40.0412C27.7881 39.2578 28.7153 38.9236 29.6425 38.9236C30.5696 38.9236 31.4986 39.2578 31.4986 40.0412V55.1691C31.4986 55.931 30.5714 56.3101 29.6425 56.3101C28.7135 56.3101 27.7881 55.931 27.7881 55.1691V40.0412Z"
																		fill="#B3B3B3"
																	/>
																	<path
																		d="M34.0664 40.0412C34.0664 39.4465 34.6145 38.9236 35.4464 38.9236H40.3698C43.509 38.9236 45.9833 40.3988 45.9833 44.3951V44.5137C45.9833 48.5099 43.4137 50.0319 40.1327 50.0319H37.777V55.1691C37.777 55.931 36.8498 56.3101 35.9226 56.3101C34.9954 56.3101 34.0664 55.931 34.0664 55.1691V40.0412ZM37.777 42.158V47.1766H40.1327C41.4641 47.1766 42.2727 46.4165 42.2727 44.7994V44.537C42.2727 42.9198 41.4641 42.158 40.1327 42.158H37.777Z"
																		fill="#B3B3B3"
																	/>
																</svg>
															)}

															{file.path && file.path.includes("pdf") && (
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="50"
																	height="50"
																	viewBox="0 0 30 20"
																	color="#B3B3B3"
																	fill="none"
																>
																	<path
																		d="M19 11C19 10.1825 19 9.4306 18.8478 9.06306C18.6955 8.69552 18.4065 8.40649 17.8284 7.82843L13.0919 3.09188C12.593 2.593 12.3436 2.34355 12.0345 2.19575C11.9702 2.165 11.9044 2.13772 11.8372 2.11401C11.5141 2 11.1614 2 10.4558 2C7.21082 2 5.58831 2 4.48933 2.88607C4.26731 3.06508 4.06508 3.26731 3.88607 3.48933C3 4.58831 3 6.21082 3 9.45584V14C3 17.7712 3 19.6569 4.17157 20.8284C5.34315 22 7.22876 22 11 22H19M12 2.5V3C12 5.82843 12 7.24264 12.8787 8.12132C13.7574 9 15.1716 9 18 9H18.5"
																		stroke="currentColor"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M21 14H19C18.4477 14 18 14.4477 18 15V16.5M18 16.5V19M18 16.5H20.5M7 19V17M7 17V14H8.5C9.32843 14 10 14.6716 10 15.5C10 16.3284 9.32843 17 8.5 17H7ZM12.5 14H13.7857C14.7325 14 15.5 14.7462 15.5 15.6667V17.3333C15.5 18.2538 14.7325 19 13.7857 19H12.5V14Z"
																		stroke="currentColor"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																</svg>
															)}

															{file.path &&
																(file.path.includes("jpg") ||
																	file.path.includes("jpeg") ||
																	file.path.includes("png")) && (
																	<img
																		className="file-download-img"
																		src={`${consts.API_URL_IMG}/${file.path}`}
																		alt=""
																	/>
																)}

															{image.length > 0 &&
																image.map((img, i) => (
																	<img
																		src={img}
																		key={i}
																		alt="Preview"
																		className="file-preview-img"
																	/>
																))}
														</div>
														<span className="file-download-name">
															{file.name}, {file.size}
														</span>
													</Link>

													<button
														onClick={(event) =>
															handleRemoveFile(file.path, event)
														}
														className="btn"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="20"
															height="20"
															color="#000000"
															fill="none"
														>
															<path
																d="M18 6L12 12M12 12L6 18M12 12L18 18M12 12L6 6"
																stroke="currentColor"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													</button>
												</div>
											</li>
										))}
									</ul>
								</div>
							)}
						</div>
						<div className="error-message">{errors.attachments}</div>
					</Form.Group>

					<Form.Group controlId="formSelect" className="select-form mt-5">
						<Form.Label>Dərs seç</Form.Label>
						<div className="position-relative">
							<CustomSelect
								options={subjects?.map(subject => ({
									value: subject.id,
									label: subject.name,
								}))}
								// value={subjects.find(subject => subject.id === formData.subject_id) ? {
								// 	value: formData.subject_id,
								// 	label: subjects.find(subject => subject.id === formData.subject_id).name
								// } : null}
								value={selectedSubject ? {
									value: selectedSubject,
									label: subjects.find(subject => subject.id === selectedSubject)?.name
								} : (subjects.find(subject => subject.id === formData.subject_id) ? {
									value: formData.subject_id,
									label: subjects.find(subject => subject.id === formData.subject_id).name
								} : null)}
								onChange={(selectedOption) => {
									const newSubjectId = selectedOption ? selectedOption.value : null;

									// Сначала обновляем formData.subject_id
									setFormData((prevData) => ({
										...prevData,
										subject_id: newSubjectId,
									}));

									// После обновления formData сбрасываем selectedSubject
									if (newSubjectId !== selectedSubject) {
										setSelectedSubject(newSubjectId);
									}

									// Сбрасываем ошибку, если есть выбор
									if (newSubjectId) {
										setErrors((prevErrors) => ({
											...prevErrors,
											subject_id: undefined,
										}));
									}
								}}
								placeholder="Siyahıdan seçin"
								isInvalid={!!errors.subject_id}
							/>
						</div>
						<div className="error-message">{errors.subject_id}</div>
					</Form.Group>

					{formData.type === "user" && (
						<Form.Group controlId="formGroupStudent" className="mt-5">
							<Form.Label>Tələbəni seç</Form.Label>
							<div className="position-relative">
								<Dropdown onClick={() => setDropDownStudent((prev) => !prev)}>
									<Dropdown.Toggle
										id="dropdown-basic"
										className={`select-multiple ${errors.student ? "border-red" : ""
											}`}
									>
										<div>{getDropdownLabel("user")}</div>
										{dropDownStudent ? (
											<svg
												width="14"
												height="8"
												viewBox="0 0 14 8"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M1 7L7 1L13 7"
													stroke="#6F767E"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										) : (
											<svg
												width="14"
												height="8"
												viewBox="0 0 14 8"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M1 1L7 7L13 1"
													stroke="#6F767E"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										)}
									</Dropdown.Toggle>

									<Dropdown.Menu className="px-2 add-dropdown-menu">
										{students?.length > 0 ? (
											students.map((student) => (
												<Form.Check
													key={student.id}
													type="checkbox"
													id={`student-${student.id}`}
													label={student.full_name}
													name="student_id"
													value={student.id}
													className="custom-switch"
													checked={formData.students.some(
														(item) => item.student_id === student.id.toString()
													)}
													onChange={handleChange}
												/>
											))
										) : (
											<Dropdown.Item disabled>
												Tələbələr mövcud deyil
											</Dropdown.Item>
										)}
									</Dropdown.Menu>
								</Dropdown>
							</div>{" "}
							<div className="text-danger mt-2">
								{errors.student && <div>{errors.student}</div>}{" "}
							</div>
							{/* <div className="text-danger mt-2">
                {errors.student && (
                  <div className="invalid-feedback d-block">
                    {errors.student}
                  </div>
                )}
              </div> */}
						</Form.Group>
					)}

					{formData.type === "group" && (
						<Form.Group controlId="formGroupGroup" className="mt-5">
							<Form.Label>Qrup seçin</Form.Label>
							<div className="position-relative">
								<Dropdown onClick={() => setDropDownGroup((prev) => !prev)}>
									<Dropdown.Toggle
										id="dropdown-basic"
										className={`select-multiple ${errors.group ? "border-red" : ""
											}`}
									>
										<div>{getDropdownLabel("group")}</div>
										{dropDownGroup ? (
											<svg
												width="14"
												height="8"
												viewBox="0 0 14 8"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M1 7L7 1L13 7"
													stroke="#6F767E"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										) : (
											<svg
												width="14"
												height="8"
												viewBox="0 0 14 8"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M1 1L7 7L13 1"
													stroke="#6F767E"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										)}
									</Dropdown.Toggle>

									<Dropdown.Menu className="px-2 add-dropdown-menu">
										{selectedSubject ? (
											filteredGroups?.length > 0 ? (
												filteredGroups.map((group, index) => (
													<Form.Check
														key={`${group.groupSubjectID}-${index}`}
														type="checkbox"
														id={`group-${group.groupSubjectID}`}
														label={group.groupName}
														name="group_subject_id"
														value={group.groupSubjectID}
														className="custom-switch"
														checked={
															selectedGroupData
																? selectedGroupData.groupSubjectID.toString() === group.groupSubjectID.toString()
																: formData.group_subjects?.some(
																	(item) =>
																		item.group_subject_id?.toString() === group.groupSubjectID.toString()
																)
														}
														onChange={handleChange}
													/>
												))
											) : (
												<Dropdown.Item disabled>Qruplar mövcud deyil</Dropdown.Item>
											)
										) : (
											<div className="p-2">
												<Form.Check disabled label="Qrup seçmək üçün fənni seçin" />
											</div>
										)}
									</Dropdown.Menu>
								</Dropdown>
							</div>{" "}
							<div className="text-danger mt-2">
								{errors.group && <div>{errors.group}</div>}{" "}
							</div>
							<div className="text-danger mt-2">
								{errors.groups && (
									<div className="invalid-feedback d-block">
										{errors.groups}
									</div>
								)}
							</div>
						</Form.Group>
					)}
					<Row>
						<Col>
							<Form.Group className="mt-5">
								<Form.Label>Başlama tarixi</Form.Label>
								<Form.Control
									type="date"
									name="start_date"
									value={formData.start_date}
									onChange={handleChange}
									className={`${errors.start_date ? "border-red" : ""}`}
								/>
								<div className="error-message">{errors.start_date}</div>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mt-5">
								<Form.Label>Bitmə tarixi</Form.Label>
								<Form.Control
									type="date"
									name="end_date"
									value={formData.end_date}
									onChange={handleChange}
									className={`${errors.end_date ? "border-red" : ""}`}
								/>
								<div className="error-message">{errors.end_date}</div>
							</Form.Group>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<div className="buttons">
					<button
						type="button"
						className="outlined-btn"
						onClick={() => handleSubmit(1)}
					>
						Qaralama kimi saxla
					</button>
					<button
						type="button"
						className="primary-button exercise-btn"
						onClick={() => handleSubmit(0)}
					>
						Yeni tapşırıq yarat
					</button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default AddExerciseModal;
