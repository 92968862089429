import React, { useState } from "react";
import LessonsTable from "../components/admin/LessonsTable";
import { useNavigate, useParams } from "react-router-dom";

function Lessons() {
	const { id } = useParams()
	const navigate = useNavigate()
	return (
		<section className="group-admin position-relative">
			<h3 className="title mb-3">Dərslər</h3>
			<button className="lessons-back"
				onClick={() => navigate(`/groups-admin?page=1&pagination=25`)}
			>
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M3.825 9L9.425 14.6L8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825Z" fill="#92929D" />
				</svg>
			</button>
			<LessonsTable groupId={id} />
		</section>
	);
}

export default Lessons;

