import React from 'react'

function NotificationGrade() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 1H16C19.866 1 23 4.13401 23 8V16C23 19.866 19.866 23 16 23H8C4.13401 23 1 19.866 1 16V8C1 4.13401 4.13401 1 8 1ZM8 3C5.23858 3 3 5.23858 3 8V16C3 18.7614 5.23858 21 8 21H16C18.7614 21 21 18.7614 21 16V8C21 5.23858 18.7614 3 16 3H8ZM8.69164 11.2784L10.9627 13.5203L15.2178 8.36355C15.5693 7.93756 16.1996 7.87718 16.6256 8.22868C17.0516 8.58018 17.1119 9.21046 16.7604 9.63645L11.8096 15.6364C11.4357 16.0895 10.7538 16.1243 10.3358 15.7117L7.28661 12.7018C6.89356 12.3138 6.88946 11.6806 7.27745 11.2876C7.66544 10.8945 8.29859 10.8904 8.69164 11.2784Z" fill="#3F8D48" />
        </svg>
    )
}

export default NotificationGrade