import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Pagination, Skeleton } from "@mui/material";
import StudentTableItem from "./StudentTableItem";
import { studentTableHeading, studentTableSelect } from "../../utils/data";
import AddStudent from "./AddStudent";
import Form from "react-bootstrap/Form";
import FilterSelect from "./FilterSelect";
import { fetchStudents } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import InboxIcon from '@mui/icons-material/Inbox';


function StudentTable() {
  const navigate = useNavigate();
  const [openSelectTwo, setOpenSelectTwo] = useState(false);
  const [modalShowAdd, setModalShowAdd] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page')) || 1;
  const pagination = parseInt(searchParams.get('pagination')) || 25;
  const [totalPages, setTotalPages] = useState(0);
  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [sortField, setSortField] = useState('created_at');
  const [sortOrder, setSortOrder] = useState('desc');

  const handleModalShowAdd = () => setModalShowAdd(true);
  const handleModalCloseAdd = () => setModalShowAdd(false);

  useEffect(() => {
    if (!searchParams.get('page') || !searchParams.get('pagination')) {
      setSearchParams({
        page: currentPage,
        pagination: pagination,
      });
    }
  }, [searchParams, setSearchParams, currentPage, pagination]);

  useEffect(() => {
    const fetchData = async () => {
      try {
				setLoading(true); 
        const data = await fetchStudents(navigate, pagination, currentPage, search, sortField, sortOrder);
        setGetData(data.items);
        setTotalPages(data.totalPages);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pagination, currentPage, search, navigate, sortField, sortOrder]);

  const handlePageChange = (event, value) => {
    setSearchParams({ page: value, pagination });
  };

  const handlepaginationChange = (event) => {
    setSearchParams({ page: 1, pagination: Number(event.target.value) });
  };

  const handleSortChange = (value) => {
    const [field, order] = value.split(' ');
    setSortField(field);
    setSortOrder(order);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setSearchParams({ page: 1, pagination });
  };

  return (
    <>
      {modalShowAdd && (
        <AddStudent show={modalShowAdd} handleClose={handleModalCloseAdd} />
      )}
      <div className="groups">
        <div className="groups-header">
          <button className="add-button" onClick={handleModalShowAdd}>
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="8.5"
                y="8"
                width="13"
                height="13"
                stroke="#141B34"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.5 8V1H1.5V16H8.5"
                stroke="#141B34"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M18 14.5H12M15 11.5V17.5"
                stroke="#141B34"
                strokeWidth="1.5"
              />
            </svg>
            <div>Yeni tələbə əlavə et</div>{" "}
          </button>

          <div className="header-right">
            <FilterSelect data={studentTableSelect} onSelect={handleSortChange} />
            <div className="search-box">
              <input
                type="text"
                placeholder="Axtar"
                value={search}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <div className="groups-body">
          <table>
            <thead>
              <tr>
                {studentTableHeading.map((item, i) => (
                  <th key={item.value}>
                    <div className={`${i === 0 ? "left" : ""} column-content`}>
                      {item.name}
                      {sortField === item.value && (
                        <span>{sortOrder === 'asc' ? '↓' : '↑'}</span>
                      )}
                    </div>
                  </th>
                ))}
                <th>
                  <div className="right column-content">Əməliyyatlar</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                Array.from({ length: 10 })?.map((_, i) => (
                  <tr key={i}>
                    {studentTableHeading?.map((item, index) => (
                      <td key={index}>
                        <Skeleton variant="text" width="100%" height={50} />
                      </td>
                    ))}
                    <td>
                      <Skeleton variant="text" width="100%" height={50} />
                    </td>
                  </tr>
                ))
              ) : getData?.length > 0 && (
                getData.map((student, i) => (
                  <StudentTableItem
                    data={student}
                    key={i}
                    handleModalShowAdd={handleModalShowAdd}
                  />
                ))
              )}
            </tbody>
          </table>
          {getData?.length === undefined && !loading && (
            <>
              <div className="request__message d-flex justify-content-center align-items-center flex-column" style={{ height: "100%" }}>
                <InboxIcon className="icon-box" />
                <p className="icon-box-p">Məlumat yoxdur!</p>
              </div>
            </>
          )}
        </div>

        <div className="groups-footer">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            className="pagination"
            variant="outlined"
            shape="rounded"
          />
          <Form.Select
            className={`selection ${openSelectTwo ? "active" : ""}`}
            value={pagination}
            onChange={handlepaginationChange}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </div>
      </div>
    </>
  );
}

export default StudentTable;
