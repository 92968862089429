import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Pagination, Skeleton } from "@mui/material";
import { deletedTableHeading, deletedTableSelect } from "../../utils/data";
import DeletedTableItem from "./DeletedTableItem";
import Form from "react-bootstrap/Form";
import FilterSelect from "./FilterSelect";
import AlertModal from "./AlertModal";
import { fetchDeletedStudents, fetchDeletedTeachers } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import InboxIcon from "@mui/icons-material/Inbox";

function DeletedTable() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get("page")) || 1;
  const pagination = parseInt(searchParams.get("pagination")) || 25;

  const [totalPages, setTotalPages] = useState(0);
  const [deletedData, setDeletedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState("Tələbə");
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState("full_name");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let data;
        if (selectedType === "Tələbə") {
          data = await fetchDeletedStudents(navigate, pagination, currentPage, search, sortField, sortOrder);
        } else {
          data = await fetchDeletedTeachers(navigate, pagination, currentPage, search, sortField, sortOrder);
        }
        setDeletedData(data.items);
        setTotalPages(data.totalPages);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pagination, currentPage, search, sortField, sortOrder, navigate, selectedType]);

  useEffect(() => {
    if (!searchParams.get("page") || !searchParams.get("pagination")) {
      setSearchParams({ page: currentPage, pagination });
    }
  }, [searchParams, setSearchParams, currentPage, pagination]);

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handlePageChange = (event, value) => {
    setSearchParams({ page: value, pagination });
  };

  const handlePaginationChange = (event) => {
    setSearchParams({ page: 1, pagination: Number(event.target.value) });
  };

  const handleSortChange = (value) => {
    const [field, order] = value.split(" ");
    setSortField(field);
    setSortOrder(order);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setSearchParams({ page: 1, pagination });
  };

  return (
    <>
      <div className="groups no-add-layer">
        <div className="groups-header">
          <div className="header-right">
            <Form.Select className="selection" onChange={handleTypeChange}>
              <option value="Tələbə">Tələbə</option>
              <option value="Müəllim">Müəllim</option>
            </Form.Select>

            <FilterSelect data={deletedTableSelect} onSelect={handleSortChange} />

            <div className="search-box">
              <input type="text" placeholder="Axtar" value={search} onChange={handleSearchChange} />
            </div>
          </div>
        </div>

        <div className="groups-body">
          <table>
            <thead>
              <tr>
                {deletedTableHeading.map((item, i) => (
                  <th key={item.value}>
                    <div className={`${i === 0 ? "left" : ""} column-content`}>
                      {item.name}
                      {sortField === item.value && <span>{sortOrder === "asc" ? "↓" : "↑"}</span>}
                    </div>
                  </th>
                ))}
                <th>
                  <div className="right column-content">Əməliyyatlar</div>
                </th>
              </tr>
            </thead>

            <tbody>
              {loading ? (
                Array.from({ length: 10 }).map((_, i) => (
                  <tr key={i}>
                    {deletedTableHeading.map((item, index) => (
                      <td key={index}>
                        <Skeleton variant="text" width="100%" height={50} />
                      </td>
                    ))}
                    <td>
                      <Skeleton variant="text" width="100%" height={50} />
                    </td>
                  </tr>
                ))
              ) : deletedData?.length > 0 ? (
                deletedData.map((deleted, i) => (
                  <DeletedTableItem data={deleted} key={i} tableType={selectedType === "Tələbə" ? "student" : "teacher"} />
                ))
              ) : (
                <tr>
                  <td colSpan={deletedTableHeading?.length + 1}>
                    <div className="request__message d-flex justify-content-center align-items-center flex-column" style={{ height: "100%" }}>
                      <InboxIcon className="icon-box" />
                      <p className="icon-box-p">Məlumat yoxdur!</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="groups-footer">
          <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} className="pagination" variant="outlined" shape="rounded" />
          <Form.Select className="selection" value={pagination} onChange={handlePaginationChange}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </div>
      </div>
    </>
  );
}

export default DeletedTable;
