import React from 'react'
import ActivityTable from '../components/teachers/ActivityTable'

function ActivityTeacher() {
	return (
		<section>
			<h3 className="title mb-3">Aktivlik</h3>
			<ActivityTable />
		</section>
	)
}

export default ActivityTeacher
