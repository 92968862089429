import * as consts from "../consts/Consts";
import { getApiToken, saveTokensToCookie } from "./tokenUtils";
import {
	generateRandomNonce,
	generateSignature,
	getCurrentDatetime,
	getCurrentEpochDivided,
	handleUnauthorizedError,
} from "./authUtils";
import { useNavigate } from "react-router-dom";
import { deleteCookie, getAccessTokenFromCookie, getRefreshTokenFromCookie, isTokenExpired } from './tokenService';
import { refreshAccessToken } from './authService';

const apiUrls = {
	groups: `${consts.API_URL}/admin/group`,
	lessons: `${consts.API_URL}/admin/group-subject`,
	subjects: `${consts.API_URL}/admin/subject`,
	teachers: `${consts.API_URL}/admin/teacher`,
	students: `${consts.API_URL}/admin/student/get/status/student`,
	graduates: `${consts.API_URL}/admin/student/get/status/graduated`,
	pendings: `${consts.API_URL}/admin/student/get/status/pending`,
	aboutMe: `${consts.API_URL}/auth/me`,
	contact: `${consts.API_URL}/site/contact`,
	subjectPost: `${consts.API_URL}/admin/subject`,
	deletedStudents: `${consts.API_URL}/admin/student/get/trashed`,
	deletedTeachers: `${consts.API_URL}/admin/teacher/get/trashed`,
	settingsGrade: `${consts.API_URL}/site/setting`,
	settingsGradeUpdate: `${consts.API_URL}/admin/setting`,
	teacherGroups: `${consts.API_URL}/teacher/group`,
	teacherLessons: `${consts.API_URL}/teacher/group-subject`,
	appeals: `${consts.API_URL}/admin/appeal`,
	currentTask: `${consts.API_URL}/teacher/task?filter=current&is_draft=0`,
	futureTask: `${consts.API_URL}/teacher/task?filter=future&is_draft=0`,
	pastTask: `${consts.API_URL}/teacher/task?filter=past&is_draft=0`,
	draftTask: `${consts.API_URL}/teacher/task?is_draft=1`,
	currentTaskStudent: `${consts.API_URL}/student/task?date_range=current`,
	pastTaskStudent: `${consts.API_URL}/student/task?date_range=past`,
	studentList: `${consts.API_URL}/teacher/student`,
	leaderList: `${consts.API_URL}/teacher/group/leader`,
	appealCount: `${consts.API_URL}/admin/appeal/get/count`,
	// teachersSubject: `${consts.API_URL}/subject`,
	teachersSubjectsList: `${consts.API_URL}/teacher/group/get/subject`,
	teachersSubjectLessons: `${consts.API_URL}/teacher/subject`,
	teachersSubject: `${consts.API_URL}/teacher/group-subject`,
	templates: `${consts.API_URL}/admin/note-template`,
	aboutUsAdmin: `${consts.API_URL}/admin/about`,
	aboutUs: `${consts.API_URL}/site/about`,
	teachersActivity: `${consts.API_URL}/admin/activity`,
	activity: `${consts.API_URL}/teacher/activity`,
	studentGroups: `${consts.API_URL}/student/group`,
	siteSubjects: `${consts.API_URL}/site/subject`,
	// currentTaskStudent: `${consts.API_URL}/student/task?filter=current`,
	// pastTaskStudent: `${consts.API_URL}/student/task?filter=past`,
};

const ensureValidToken = async (navigate) => {
	let accessToken = getAccessTokenFromCookie();
	const refreshToken = getRefreshTokenFromCookie();

	if (isTokenExpired(accessToken)) {
		if (refreshToken) {
			try {
				accessToken = await refreshAccessToken(navigate);
			} catch (error) {
				handleUnauthorizedError({ status: 401 }, navigate);
				throw new Error('Не удалось обновить токен');
			}
		} else {
			handleUnauthorizedError({ status: 401 }, navigate);
			throw new Error('Отсутствует refresh_token');
		}
	}
	return accessToken;
};


const createHeaders = (accessToken) => {
	const datetime = getCurrentDatetime();
	const nonce = generateRandomNonce(8);
	const salt = "NSP-Coders_(!#12!)_";
	const epochDivided = getCurrentEpochDivided();
	const signature = generateSignature(datetime, nonce, salt, epochDivided);

	return {
		"X-Component": "GroupSubjectComponent",
		"Content-Type": "application/json",
		Authorization: `Bearer ${getApiToken()}`,
		// Authorization: `Bearer ${accessToken}`,
		Datetime: datetime,
		Nonce: nonce,
		Signature: signature,
	};
};


const refreshToken = async () => {
	const refreshToken = getRefreshTokenFromCookie();

	try {
		if (!refreshToken) {
			throw new Error("Refresh token mövcud deyil. Zəhmət olmasa yenidən daxil olun.");
		}
		const response = await fetch(`${consts.API_URL}/auth/refresh`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${refreshToken}`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			// window.location.href = "/"; 
			throw new Error(errorData.message || "Refresh token yenilənmədi.");
		}

		if (response.ok) {
			const data = await response.json();
			const { access_token, refresh_token } = data.data;
			saveTokensToCookie(access_token, refresh_token);

		}

	} catch (error) {
		console.error("Refresh token yenilənmədi:", error.message);
		throw error;
	}
};

const fetchData = async (url, navigate) => {
	try {
		const token = await ensureValidToken(navigate);
		const headers = createHeaders(token);
		const response = await fetch(url, { headers });

		if (response.status === 401) {
			console.warn("Token etibarsızdır, yenilənir...");
			try {
				await refreshToken();
				return fetchData(url, navigate);
			} catch (error) {
				deleteCookie('access_token');
				deleteCookie('refresh_token');
				throw error;
			}
		}
		const data = await response.json();
		return data.data.filter(
			(item) => item.is_blocked === undefined || item.is_blocked === 0
		);
	} catch (error) {
		// console.error("Ошибка:", error);
		throw error;
	}
};

const fetchDataWithoutFilter = async (url, navigate) => {
	try {
		const token = await ensureValidToken(navigate);
		const headers = createHeaders(token);
		const response = await fetch(url, { headers });

		if (!response.ok) {
			if (response.status === 401) {
				handleUnauthorizedError(response, navigate);
			} else {
				// throw new Error(`Ошибка при получении данных: ${response.statusText}`);
			}
		}

		const data = await response.json();
		return data.data;
	} catch (error) {
		// console.error("Ошибка:", error);
		throw error;
	}
};

const fetchDataWithoutAuth = async (url, navigate) => {
	try {
		const response = await fetch(url);

		if (!response.ok) {
			if (response.status === 401) {
				handleUnauthorizedError(response, navigate);
			} else {
				throw new Error(`Ошибка при получении данных: ${response.statusText}`);
			}
		}

		const data = await response.json();
		return data.data;
	} catch (error) {
		// console.error("Ошибка:", error);
		throw error;
	}
};

const fetchDataWithPost = async (url, navigate, bodyData) => {
	try {
		const token = await ensureValidToken(navigate);
		const headers = createHeaders(token);
		const response = await fetch(url, {
			method: "POST",
			headers,
			body: JSON.stringify(bodyData),
		});

		if (!response.ok) {
			if (response.status === 401) {
				handleUnauthorizedError(response, navigate);
			} else {
				// throw new Error(`Ошибка при отправке данных: ${response.statusText}`);
			}
		}

		const data = await response.json();
		return data.data;
	} catch (error) {
		// console.error("Ошибка:", error);
		throw error;
	}
};

const fetchDataPagination = async (
	url,
	navigate,
	perPage = 25,
	page = 1,
	search = "",
	sortField = "",
	sortOrder = "",
	is_archived = "",
	type = "",
	group_id = ""
) => {
	try {
		const token = await ensureValidToken(navigate);
		const headers = createHeaders(token);
		let paginatedUrl = `${url}?pagination=${perPage}&page=${page}`;

		if (search) {
			paginatedUrl += `&${search}`;
		}

		if (is_archived !== "") {
			paginatedUrl += `&is_archived=${encodeURIComponent(is_archived)}`;
		}

		if (type) {
			paginatedUrl += `&type=${encodeURIComponent(type)}`;
		}

		if (group_id) {
			paginatedUrl += `&group_id=${encodeURIComponent(group_id)}`;
		}

		if (sortField) {
			const validSortOrder =
				sortOrder === "asc" || sortOrder === "desc" ? sortOrder : "asc";
			paginatedUrl += `&sort[${encodeURIComponent(
				sortField
			)}]=${validSortOrder}`;
		}

		const response = await fetch(paginatedUrl, { headers });

		if (!response.ok) {
			if (response.status === 401) {
				handleUnauthorizedError(response, navigate);
			} else {
				// throw new Error(`Error fetching data: ${response.statusText}`);
			}
		}

		const data = await response.json();

		return {
			items: data.data,
			totalPages: data.pagination.total_pages,
			perPage: data.pagination.per_page,
			currentPage: data.pagination.current_page,
		};
	} catch (error) {
		// console.error("Error:", error);
		throw error;
	}
};

export const fetchGroups = async (
	navigate,
	perPage = 25,
	page = 1,
	search = "",
	sortField = "",
	sortOrder = "",
	is_archived
) => await fetchDataPagination(
	apiUrls.groups,
	navigate,
	perPage,
	page,
	`name=${encodeURIComponent(search)}`,
	sortField,
	sortOrder,
	is_archived
);

export const createSubjectPost = async (navigate, groupData) => {
	return await fetchDataWithPost(apiUrls.subjectPost, navigate, groupData);
};

export const fetchSubjects = async (navigate) => {
	return await fetchData(apiUrls.subjects, navigate);
};

export async function fetchTeachersSubjects(navigate, selectedGroup) {
	const headers = createHeaders();
	const baseUrl = `${consts.API_URL}/teacher/group-subject`;

	const queryParams = new URLSearchParams();
	if (selectedGroup) {
		queryParams.append("group_id", selectedGroup);
	}

	try {
		const response = await fetch(`${baseUrl}?${queryParams.toString()}`, {
			method: "GET",
			headers: headers,
		});

		if (!response.ok) {
			throw new Error("Ошибка сети");
		}

		return await response.json();
	} catch (error) {
		handleUnauthorizedError(error, navigate);
		throw error;
	}
}
export async function fetchGroupSubjects(navigate, selectedGroup) {
	const headers = createHeaders();
	const baseUrl = `${consts.API_URL}/admin/group-subject`;

	const queryParams = new URLSearchParams();
	if (selectedGroup) {
		queryParams.append("group_id", selectedGroup);
	}

	try {
		const response = await fetch(`${baseUrl}?${queryParams.toString()}`, {
			method: "GET",
			headers: headers,
		});

		if (!response.ok) {
			throw new Error("Ошибка сети");
		}

		return await response.json();
	} catch (error) {
		handleUnauthorizedError(error, navigate);
		throw error;
	}
}


export const fetchTeacherGroups = async (navigate) => {
	return await fetchData(apiUrls.teacherGroups, navigate);
};

// export const fetchTeacherGroups = async (
//   navigate,
//   perPage = 25,
//   page = 1,
//   sortField = "",
//   sortOrder = ""
// ) => {
//   return await fetchDataPagination(
//     apiUrls.pendings,
//     navigate,
//     perPage,
//     page,
//     sortField,
//     sortOrder
//   );
// };

export const fetchTeachers = async (
	navigate,
	perPage = 25,
	page = 1,
	search = "",
	sortField = "",
	sortOrder = ""
) => {
	return await fetchDataPagination(
		apiUrls.teachers,
		navigate,
		perPage,
		page,
		`full_name=${encodeURIComponent(search)}`,
		sortField,
		sortOrder
	);
};
export const fetchTeachersActivity = async (
	navigate,
	perPage = 25,
	page = 1,
	search = "",
	sortField = "",
	sortOrder = "",
	type = "",
	selectedGroup = ""
) => {
	return await fetchDataPagination(
		apiUrls.teachersActivity,
		navigate,
		perPage,
		page,
		`description=${encodeURIComponent(search)}`,
		sortField,
		sortOrder,
		"",
		type,
		selectedGroup
	);
};

export const fetchActivity = async (
	navigate,
	perPage = 25,
	page = 1,
	search = "",
	sortField = "",
	sortOrder = "",
	type = "",
	selectedGroup = ""
) => {
	return await fetchDataPagination(
		apiUrls.activity,
		navigate,
		perPage,
		page,
		`description=${encodeURIComponent(search)}`,
		sortField,
		sortOrder,
		"",
		type,
		selectedGroup
	);
};

export const fetchStudentList = async (navigate, perPage = 25, page = 1) => {
	return await fetchDataPagination(
		apiUrls.studentList,
		navigate,
		perPage,
		page
	);
};

export const fetchLeaderList = async (navigate, perPage = 25, page = 1) => {
	return await fetchDataPagination(
		apiUrls.leaderList,
		navigate,
		perPage,
		page
	);
};

export const fetchPendings = async (
	navigate,
	perPage = 25,
	page = 1,
	search = "",
	sortField = "",
	sortOrder = ""
) => {
	return await fetchDataPagination(
		apiUrls.pendings,
		navigate,
		perPage,
		page,
		`full_name=${encodeURIComponent(search)}`,
		sortField,
		sortOrder
	);
};
export const fetchTemplates = async (
	navigate,
	perPage = 25,
	page = 1,
	search = "",
	sortField = "",
	sortOrder = ""
) => {
	return await fetchDataPagination(
		apiUrls.templates,
		navigate,
		perPage,
		page,
		`note=${encodeURIComponent(search)}`,
		sortField,
		sortOrder
	);
};

export const fetchAppeals = async (
	navigate,
	perPage = 25,
	page = 1,
	search = "",
	sortField = "",
	sortOrder = "",
	type = ""
) => {
	return await fetchDataPagination(
		apiUrls.appeals,
		navigate,
		perPage,
		page,
		`full_name=${encodeURIComponent(search)}`,
		sortField,
		sortOrder,
		type
	);
};



// export const fetchGroups = async (
// 	navigate,
// 	perPage = 25,
// 	page = 1,
// 	search = "",
// 	sortField = "",
// 	sortOrder = "",
// 	groupName = ""
// ) => {
// 	let searchParam = search;
// 	if (groupName) {
// 		searchParam = `name=${encodeURIComponent(groupName)}`;
// 	}
// 	else {
// 		searchParam = `name=${encodeURIComponent(search)}`;
// 	}

// 	return await fetchDataPagination(
// 		apiUrls.groups,
// 		navigate,
// 		perPage,
// 		page,
// 		searchParam,
// 		sortField,
// 		sortOrder
// 	);
// };


export const fetchStudents = async (
	navigate,
	perPage = 25,
	page = 1,
	search = "",
	sortField = "",
	sortOrder = ""
) => {
	return await fetchDataPagination(
		apiUrls.students,
		navigate,
		perPage,
		page,
		`full_name=${encodeURIComponent(search)}`,
		sortField,
		sortOrder
	);
};

export const fetchStudentsGroups = async (
	navigate,
	perPage = 25,
	page = 1,
	search = "",
	sortField = "",
	sortOrder = ""
) => {
	return await fetchDataPagination(
		apiUrls.students,
		navigate,
		perPage,
		page,
		`doesnt_have_groups=1&full_name=${encodeURIComponent(search)}`,
		sortField,
		sortOrder
	);
};

export const fetchGraduates = async (
	navigate,
	perPage = 25,
	page = 1,
	search = "",
	sortField = "",
	sortOrder = ""
) => {
	return await fetchDataPagination(
		apiUrls.graduates,
		navigate,
		perPage,
		page,
		`full_name=${encodeURIComponent(search)}`,
		sortField,
		sortOrder
	);
};

export const fetchDeletedStudents = async (
	navigate,
	perPage = 25,
	page = 1,
	search = "",
	sortField = "",
	sortOrder = ""
) => {
	return await fetchDataPagination(
		apiUrls.deletedStudents,
		navigate,
		perPage,
		page,
		`full_name=${encodeURIComponent(search)}`,
		sortField,
		sortOrder
	);
};

export const fetchDeletedTeachers = async (
	navigate,
	perPage = 25,
	page = 1,
	search = "",
	sortField = "",
	sortOrder = ""
) => {
	return await fetchDataPagination(
		apiUrls.deletedTeachers,
		navigate,
		perPage,
		page,
		`full_name=${encodeURIComponent(search)}`,
		sortField,
		sortOrder
	);
};

export const fetchAboutMe = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.aboutMe, navigate);
};

export const fetchContact = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.contact, navigate);
};

export const fetchAppealCount = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.appealCount, navigate);
};

export const fetchCurrentTask = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.currentTask, navigate);
};
export const fetchStudentCurrentTask = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.currentTaskStudent, navigate);
};

export const fetchFutureTask = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.futureTask, navigate);
};

export const fetchPastTask = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.pastTask, navigate);
};
export const fetchStudentPastTask = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.pastTaskStudent, navigate);
};

export const fetchDraftTask = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.draftTask, navigate);
};

export const fetchSettingsGrade = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.settingsGrade, navigate);
};

export const fetchAboutUsAdmin = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.aboutUsAdmin, navigate);
};

export const fetchAboutUs = async (navigate) => {
	return await fetchDataWithoutAuth(apiUrls.aboutUs, navigate);
};

export const fetchStudentGroups = async (navigate) => {
	return await fetchData(apiUrls.studentGroups, navigate);
};

export const fetchCurrentTaskStudent = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.currentTaskStudent, navigate);
};

export const fetchPastTaskStudent = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.pastTaskStudent, navigate);
};

export const fetchLessons = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.lessons, navigate);
};

export const fetchTeacherLessons = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.teacherLessons, navigate);
};

export const fetchTeacherSubjectsList = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.teachersSubjectsList, navigate);
};

export const fetchTeacherSubjectLessons = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.teachersSubjectLessons, navigate);
};

export const fetchSiteSubjects = async (navigate) => {
	return await fetchDataWithoutFilter(apiUrls.siteSubjects, navigate);
};

export { createHeaders, ensureValidToken };
