import { useState } from "react";
import UserProfile from "../UserProfile";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";
import CreateExercise from "../teachers/CreateExercise";
import { useNavigate } from "react-router-dom";

function TeachersActivityTableItem({ data }) {
	const [taskData, setTaskData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState(false);
	const navigate = useNavigate()

	const handleClick = async () => {
		if (data.type === "grade") {
			try {
				const filters = JSON.parse(data.filters);
				console.log("filters.group_id", filters.group_id);

				navigate(`/group-details/${filters.group_id}?page=1&pagination=25&date=${filters.date}&type=${filters.type}&group_subject_id=${filters.group_subject_id}&status=${filters.status}`);
			} catch (error) {
				// console.error("Ошибка парсинга filters:", error);
			}
		}

		if (data.type === "task") {
			try {
				const filters = JSON.parse(data.filters);
				const taskId = filters?.id;

				setLoading(true);
				const headers = createHeaders();

				const response = await fetch(`${consts.API_URL}/admin/task/${taskId}`, {
					method: "GET",
					headers: headers,
				});

				if (!response.ok) {
					// throw new Error(`Ошибка: ${response.status}`);
				}

				const taskData = await response.json();
				setTaskData(taskData.data);
				setShow(true);
			} catch (error) {
				// console.error("Ошибка загрузки данных задачи:", error);
			} finally {
				setLoading(false);
			}
		}
	};

	const handleModalToggle = () => {
		setShow(prev => !prev);
	};

	const getCursorStyle = (type) => {
		if (type === "grade" || type === "task") return "pointer";
		if (type === "login" || type === "logout") return "";
		return "default";
	};
	return (
		<>
			{show && (
				<CreateExercise
					show={show}
					handleClose={handleModalToggle}
					id={taskData?.id}
					data={taskData}
				/>
			)}
			<tr>
				<td>
					<div className="left column-content">
						{data.date}
					</div>
				</td>
				<td>
					<div className="column-content">{data.full_name}</div>
				</td>
				<td>
					<div className="column-content">{data.group.name}</div>
				</td>
				<td>
					<div className="column-content" onClick={handleClick} style={{ cursor: getCursorStyle(data.type) }}>{data.description}</div>
				</td>
			</tr>
		</>

	);
}

export default TeachersActivityTableItem;
