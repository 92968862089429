import { 
	SET_SELECTED_GROUP, 
	SET_DATA_STATUS, 
	SET_GET_DATA, 
	SET_GROUP_NAME, 
	SET_SELECTED_GROUP_SUBJECT, 
	SET_SELECTED_SUBJECT 
  } from './action';
  
  // JSON.parse səhvlərdən qorunmaq üçün funksiya
  const getLocalStorageItem = (key) => {
	try {
	  const item = localStorage.getItem(key);
	  return item ? JSON.parse(item) : null;
	} catch (error) {
	  console.error(`Error parsing localStorage item: ${key}`, error);
	  return null;
	}
  };
  
  const initialState = {
	selectedGroup: getLocalStorageItem("selectedGroup") || null,
	selectedGroupSubject: getLocalStorageItem("selectedGroupSubject") || null,
	selectedSubject: getLocalStorageItem("selectedSubject") || null,
	dataStatus: 0,
	getData: 0,
	groupName: '',
  };
  
  const reducer = (state = initialState, action) => {
	switch (action.type) {
	  case SET_SELECTED_GROUP:
		localStorage.setItem("selectedGroup", JSON.stringify(action.payload));
		return {
		  ...state,
		  selectedGroup: action.payload,
		};
	  case SET_SELECTED_GROUP_SUBJECT:
		localStorage.setItem("selectedGroupSubject", JSON.stringify(action.payload));
		return {
		  ...state,
		  selectedGroupSubject: action.payload,
		};
	  case SET_SELECTED_SUBJECT:
		localStorage.setItem("selectedSubject", JSON.stringify(action.payload));
		return {
		  ...state,
		  selectedSubject: action.payload,
		};
	  case SET_DATA_STATUS:
		return {
		  ...state,
		  dataStatus: action.payload,
		};
	  case SET_GET_DATA:
		return {
		  ...state,
		  getData: action.payload,
		};
	  case SET_GROUP_NAME:
		return {
		  ...state,
		  groupName: action.payload,
		};
	  default:
		return state;
	}
  };
  
  export default reducer;
  