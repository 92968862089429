import React from 'react'

function TeachingDirection() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 47 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.8438 7.04167C23.8438 5.96586 24.7159 5.09375 25.7917 5.09375H44.125C45.2008 5.09375 46.0729 5.96586 46.0729 7.04167C46.0729 8.11747 45.2008 8.98958 44.125 8.98958H25.7917C24.7159 8.98958 23.8438 8.11747 23.8438 7.04167Z"
                fill="#92929D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.927086 27.6667C0.927086 26.5909 1.7992 25.7188 2.875 25.7188H21.2083C22.2841 25.7188 23.1563 26.5909 23.1563 27.6667C23.1563 28.7425 22.2841 29.6146 21.2083 29.6146H2.875C1.7992 29.6146 0.927086 28.7425 0.927086 27.6667Z"
                fill="#92929D"
            />
            <path
                d="M16.625 7.04175C16.625 10.8387 13.547 13.9167 9.75 13.9167C5.95304 13.9167 2.875 10.8387 2.875 7.04175C2.875 3.24479 5.95304 0.166748 9.75 0.166748C13.547 0.166748 16.625 3.24479 16.625 7.04175Z"
                fill="#92929D"
            />
            <path
                d="M44.125 27.6667C44.125 31.4637 41.047 34.5417 37.25 34.5417C33.453 34.5417 30.375 31.4637 30.375 27.6667C30.375 23.8698 33.453 20.7917 37.25 20.7917C41.047 20.7917 44.125 23.8698 44.125 27.6667Z"
                fill="#92929D"
            />
        </svg>
    )
}

export default TeachingDirection