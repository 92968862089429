import React from 'react'

function Requests() {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.7831 21.9762C21.4976 22.135 22.135 21.4976 21.9762 20.7831L20.8786 15.8439C21.6123 14.3503 22 12.7022 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C12.7022 22 14.3503 21.6123 15.8439 20.8786L20.7831 21.9762ZM18.9424 15.2373C18.8296 15.4481 18.7961 15.6924 18.848 15.9258L19.6829 19.6829L15.9258 18.848C15.6924 18.7961 15.4481 18.8296 15.2373 18.9424C13.9449 19.6335 12.5 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 12.5 19.6335 13.9449 18.9424 15.2373ZM8 13C9.10457 13 10 12.1046 10 11C10 9.89543 9.10457 9 8 9C6.89543 9 6 9.89543 6 11C6 12.1046 6.89543 13 8 13ZM16 11C16 12.1046 15.1046 13 14 13C12.8954 13 12 12.1046 12 11C12 9.89543 12.8954 9 14 9C15.1046 9 16 9.89543 16 11Z"
                fill="#92929D"
            />
        </svg>
    )
}

export default Requests