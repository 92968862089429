import React from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";

function MenuGrid({ name, background, path, image }) {
	return (
		<Col xs={6} sm={4} md={4} lg={3} className="mb-5">
			<div className="menu-item">
				<Link to={path} className="menu-icon" style={{ background }}>
					<span dangerouslySetInnerHTML={{ __html: image }} />
				</Link>
				<Link to={path} className="menu-label">
					{name}
				</Link>
			</div>
		</Col>
	);
}

export default MenuGrid;
