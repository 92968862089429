import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const PieChartStudent = ({ data }) => {
	const [state, setState] = useState({
		series: [0, 0, 0, 0, 0, 0, 0], 
		options: {
			chart: {
				width: 300,
				height: 300,
				type: 'pie',
				background: '#FFFFFF',
			},
			labels: ['Qeyri-kafi', 'Kafi', 'Orta', 'Yaxşı', 'Çox yaxşı', 'Əla', 'Qaib'],
			colors: ['#ff455b', '#F4A261', '#E9C46A', '#6a81eb', '#69c6f5', '#1AE5BE', '#FF006E'],
			dataLabels: {
				enabled: false,
			},
			title: {
				text: 'Nəticə bölgüsü',
				margin: 40,
				offsetY: 10,
				style: {
					fontSize: '20px',
					fontWeight: '600',
					color: '#000000',
				},
			},
			legend: {
				position: 'right',
				verticalAlign: 'middle',
				horizontalAlign: 'center',
				fontSize: '14px',
				formatter: (seriesName, opts) => {
					return `${seriesName}: ${opts.w.globals.series[opts.seriesIndex]}`;
				},
				labels: {
					colors: ['#404040'],
				},
				itemMargin: {
					vertical: 10,
				},
			},
			responsive: [
				{
					breakpoint: 1024, // Planşet
					options: {
						chart: { width: "100%", height: 350 },
						legend: { position: 'bottom', fontSize: '12px' },
					},
				},
				{
					breakpoint: 768, // Kiçik planşet
					options: {
						chart: { width: "100%", height: 350 },
						legend: { position: 'bottom', fontSize: '11px' },
					},
				},
				{
					breakpoint: 480, // Mobil
					options: {
						chart: { width: "100%", height: 350 },
						legend: { position: 'bottom', fontSize: '10px' },
					},
				},
			],
		},
	});

	useEffect(() => {
		if (data) {

			const stats = data?.[0]?.total_average?.statistics;
			const seriesData = [
				stats?.unsatisfactory || 0, 
				stats?.satisfactory || 0,  
				stats?.fair || 0,          
				stats?.good || 0,           
				stats?.very_good || 0,    
				stats?.excellent || 0,     
				stats?.attendance || 0     
			];

			setState((prevState) => ({
				...prevState,
				series: seriesData,
			}));
		}
	}, [data]);

	return (
		<div
			style={{
				backgroundColor: '#FFFFFF',
				padding: '20px 0 20px 20px',
				borderRadius: '20px',
				maxWidth: '100%',
			}}
		>
			<ReactApexChart options={state.options} series={state.series} type="pie" width="100%" height={300} />
		</div>
	);
};

export default PieChartStudent;
