import React from 'react'

function SideBarArrowRight() {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.76001 10.965L10.68 9.045L8.76001 7.125"
				stroke="#98ABFF"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3 9.04492H10.6275"
				stroke="#98ABFF"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9 3C12.315 3 15 5.25 15 9C15 12.75 12.315 15 9 15"
				stroke="#98ABFF"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export default SideBarArrowRight
