import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

const EditDirections = ({ show, handleClose, direction, data }) => {
	console.log(data);
	
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		name: "",
	});

	const [errors, setErrors] = useState({});

	const resetFormData = () => {
		setFormData({
			name: "",
		});
		setErrors({});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));

		if (value.trim() && errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: undefined,
			}));
		}
	};

	const validateForm = () => {
		const newErrors = {};

		if (!formData.name.trim()) {
			newErrors.name = "Tədrisin istiqamətini daxil edin";
		} else if (formData.name.length > 64) {
			newErrors.name = "Tədrisin istiqaməti 64 simvoldan çox olmamalıdır.";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleCloseModal = () => {
		resetFormData();
		handleClose();
	};

	const [loading, setLoading] = useState(true);

	const handleGetData = async () => {
		try {
			const headers = createHeaders();
			const response = await fetch(
				`${consts.API_URL}/admin/subject/${data.id}`,
				{
					method: "GET",
					headers: headers
				}
			);
			if (!response.ok) {
				throw new Error("Ошибка при получении данных");
			}
			const result = await response.json();
			if (result.data && typeof result.data === "object") {
				const translation = result.data.translations.find(
					(t) => t.language_id === 1
				);
				const name = translation?.name || "";
				setFormData({
					name,
				});
			} else {
				console.error("Ожидался объект, но получен:", result.data);
			}
		} catch (error) {
			handleUnauthorizedError(error, navigate);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (show && data?.id) {
			handleGetData();
		}
	}, [show, data?.id]);

	const handleSubmit = async () => {
		if (validateForm()) {
			const headers = createHeaders();

			const dataToSend = {
				translations: [
					{
						language_id: 1,
						name: formData.name,
					},
				],
			};

			console.log("Отправка данных:", dataToSend);

			try {
				const response = await fetch(
					`${consts.API_URL}/admin/subject/${data.id}`,
					{
						method: "PUT",
						headers: headers,
						body: JSON.stringify(dataToSend),
					}
				);
				const responseData = await response.json();
				console.log("Ответ от API:", responseData);

				if (responseData.code === 400) {
					setErrors((prevErrors) => ({
						...prevErrors,
						name: "Bu Fənn artıq daxil edilib.",
					}));
				} else if (response.ok) {
					console.log("Student updated successfully:", responseData);
					handleClose();
					window.location.reload();
				} else {
					console.error("Error updating Student:", responseData.message);
				}
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			}
		}
	};

	return (
		<Modal show={show} onHide={handleCloseModal} className="student-modal" backdrop="static">
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group controlId="formInput1">
						<Form.Label>Fənn adı</Form.Label>
						<Form.Control
							type="text"
							maxLength="64"
							name="name"
							value={formData.name}
							onChange={handleChange}
							placeholder="Fənni daxil edin"
							isInvalid={!!errors.name}
							className={errors.name ? "input-error" : ""}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.name}
						</Form.Control.Feedback>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button className="modal-btn btn-green" onClick={handleSubmit}>
					Düzəlişləri yadda saxla
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default EditDirections;
