import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import TopStudents from "../components/teachers/TopStudents";
import MenuGrid from "../components/MenuGrid";
import UserInfo from "../components/teachers/UserInfo";
import MyGroups from "../components/teachers/MyGroups";
import ContactCard from "../components/admin/ContactCard";
import { useNavigate } from "react-router-dom";
import { fetchAboutMe } from "../functions/apiService";
import { handleUnauthorizedError } from "../functions/authUtils";
import TopStudent from "../components/TopStudent";
import * as consts from "../consts/Consts";


function MainPageTeacher() {
	const [openSelectTwo, setOpenSelectTwo] = useState(false);

	const [getData, setGetData] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchAboutMe(navigate);
				setGetData(data);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	const mockMenuData = [
		{
			path: "/grade",
			name: "Qiymətlər",
			background: "linear-gradient(180deg, #2DEECE 0%, #03CCAA 100%)",
			svg: `
          <svg width="37" height="47" viewBox="0 0 37 47" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.0257 0.692322H8.51286C3.38466 0.692322 0.820557 3.25642 0.820557 8.38463V46.8462L18.7693 36.5898L36.718 46.8462V8.38463C36.718 3.25642 34.1539 0.692322 29.0257 0.692322ZM26.5386 15.7283L17.9924 24.2744C17.618 24.6488 17.1257 24.8385 16.6334 24.8385C16.1411 24.8385 15.6487 24.6514 15.2743 24.2744L11 20.0001C10.2487 19.2514 10.2487 18.0308 11 17.2795C11.7513 16.5282 12.9693 16.5282 13.7206 17.2795L16.6359 20.1923L23.823 13.0051C24.5743 12.2539 25.7923 12.2539 26.5436 13.0051C27.2949 13.7564 27.2899 14.977 26.5386 15.7283Z" fill="white"/>
          </svg>

        `,
		},
		{
			path: "/exercises/current",
			name: "Tapşırıqlar",
			background: "linear-gradient(180deg, #58B6FA 0%, #3894D7 100%)",
			svg: `
       <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33.787 46.8295C32.9126 47.2464 31.8911 47.5566 30.7346 47.765L14.0357 50.688C8.46945 51.6792 5.19043 49.3917 4.22459 43.8254L0.310949 21.5858C-0.6803 16.0196 1.60708 12.7408 7.17333 11.7496L11.7429 10.9463C12.2639 10.8548 12.7193 11.3098 12.6252 11.8309L9.63618 28.7534C8.2891 36.4546 11.9235 41.6395 19.5993 42.9866C19.5993 42.9866 32.8644 45.3173 33.5481 45.4495C34.3919 45.602 34.5266 46.5017 33.787 46.8295ZM50.6969 13.0611L46.7721 35.3107C45.8266 40.6686 42.7364 42.9586 37.5387 42.247C37.3404 42.219 37.1625 42.2191 36.9566 42.1835L20.2701 39.2402C14.7064 38.2591 12.4167 34.988 13.3978 29.4243L16.8113 10.0669L17.322 7.17447C18.3031 1.61076 21.5744 -0.679225 27.1381 0.301858L43.8264 3.24516C49.3876 4.22624 51.678 7.49991 50.6969 13.0611ZM34.5341 30.3189C34.7171 29.2819 34.026 28.2932 32.989 28.1102L22.558 26.27C21.5261 26.0845 20.5325 26.781 20.352 27.8154C20.169 28.8524 20.8601 29.8412 21.8971 30.0242L32.3281 31.8644C32.44 31.8847 32.5514 31.8923 32.6607 31.8923C33.5681 31.8948 34.3714 31.2441 34.5341 30.3189ZM42.2683 23.0472C42.4513 22.0102 41.7602 21.0216 40.7232 20.8386L24.0373 17.8953C23.0105 17.7148 22.0112 18.4062 21.8307 19.4407C21.6477 20.4777 22.3394 21.4663 23.3764 21.6493L40.0623 24.5926C40.1742 24.6129 40.2863 24.6205 40.3956 24.6205C41.3029 24.6205 42.1056 23.9698 42.2683 23.0472ZM44.048 14.7563C44.231 13.7193 43.5392 12.7306 42.5022 12.5476L25.8163 9.60429C24.7895 9.42129 23.7908 10.1152 23.6104 11.1497C23.4274 12.1867 24.1185 13.1755 25.1555 13.3585L41.8414 16.3016C41.9532 16.3219 42.0653 16.3297 42.1746 16.3297C43.082 16.3322 43.8853 15.6815 44.048 14.7563Z" fill="white"/>
        </svg>
        `,
		},
		{
			path: "/course-schedule",
			name: "Dərs cədvəli",
			background: "linear-gradient(180deg, #FF8A6B 0%, #FF633A 100%)",
			svg: `<svg
							width="50"
							height="50"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9 14.5H13M9 9.5H17"
								stroke="#fff"
								strokeWidth="2"
								strokeLinecap="round"
							/>
							<path
								d="M4 10C4 6.22876 4 4.34315 5.17157 3.17157C6.34315 2 8.22876 2 12 2H13.5C17.2712 2 19.1569 2 20.3284 3.17157C21.5 4.34315 21.5 6.22876 21.5 10V14C21.5 17.7712 21.5 19.6569 20.3284 20.8284C19.1569 22 17.2712 22 13.5 22H12C8.22876 22 6.34315 22 5.17157 20.8284C4 19.6569 4 17.7712 4 14V10Z"
								stroke="#fff"
								strokeWidth="2"
							/>
							<path
								d="M5 6L2.5 6M5 12L2.5 12M5 18H2.5"
								stroke="#fff"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
	`,
		},
		{
			path: "/activity",
			name: "Aktivlik",
			background: "linear-gradient(180deg, #711010 0%, #D4425F 100%)",
			svg: `
         <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M48.9752 31.7039C50.0742 31.7039 50.9745 32.5967 50.8737 33.691C50.5437 37.2757 49.3244 40.7331 47.3128 43.7437C44.9315 47.3075 41.547 50.0852 37.5871 51.7254C33.6272 53.3656 29.2699 53.7948 25.0661 52.9586C20.8624 52.1224 17.001 50.0584 13.9702 47.0277C10.9395 43.9969 8.87549 40.1355 8.03931 35.9318C7.20312 31.728 7.63228 27.3707 9.27251 23.4108C10.9127 19.4509 13.6904 16.0664 17.2542 13.6851C20.2648 11.6735 23.7222 10.4542 27.307 10.1242C28.4012 10.0234 29.294 10.9237 29.294 12.0227C29.294 13.1216 28.4003 14.0009 27.3083 14.1242C24.5119 14.44 21.8209 15.4199 19.4651 16.994C16.5557 18.938 14.2881 21.701 12.9491 24.9337C11.6101 28.1664 11.2597 31.7236 11.9424 35.1554C12.625 38.5872 14.31 41.7395 16.7842 44.2137C19.2584 46.688 22.4107 48.3729 25.8425 49.0555C29.2743 49.7382 32.8315 49.3878 36.0642 48.0488C39.2969 46.7098 42.06 44.4422 44.0039 41.5328C45.578 39.177 46.5579 36.486 46.8737 33.6896C46.9971 32.5976 47.8763 31.7039 48.9752 31.7039Z" fill="white"/>
        <path d="M32.9419 12.7548C33.1518 9.95522 35.6263 7.79918 38.3004 8.65405C41.5358 9.68834 44.5063 11.4764 46.953 13.9001C49.3998 16.3239 51.2158 19.2774 52.2806 22.5028C53.1607 25.1687 51.0282 27.6635 48.2307 27.8998L37.6447 28.7941C34.527 29.0575 31.9138 26.4688 32.1477 23.3488L32.9419 12.7548Z" fill="white"/>
        </svg>
      `,
		},
	];

	return (
		<section className="teacher">
			<div className="user__wrapper">
				<Row>
				<Col xs={12} md={12} lg={12} xl={8} xxl={8}>
				<div className="user__left">
							<UserInfo getData={getData} />
							<h4 className="user__title mb-4">Qruplarım</h4>
							<Row className="user__groups mb-4">
								<MyGroups getData={getData} />
							</Row>
							<div className="user__bottom mb-5">
								<Row>
									{mockMenuData.map((item, index) => (
										<MenuGrid
											key={index}
											name={item.name}
											background={item.background}
											path={item.path}
											image={item.svg}
										/>
									))}
								</Row>
							</div>
						</div>
					</Col>
						<TopStudent />
				</Row>
			</div>
		</section>
	);
}

export default MainPageTeacher;
