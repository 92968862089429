import React, { useEffect, useState } from "react";
import {
	Sidebar,
	Menu,
	MenuItem,
	SubMenu,
	useProSidebar,
} from "react-pro-sidebar";
import { NavLink, useNavigate } from "react-router-dom";
import Main from "../../assets/Main";
import Grade from "../../assets/Grade";
import Task from "../../assets/Task";
import Schedule from "../../assets/Schedule";
import SideBarArrowRight from "../../assets/SideBarArrowRight";
import SideBarArrowLeft from "../../assets/SideBarArrowLeft";
import Activity from "../../assets/Activity";
import { fetchAboutMe } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

const MySidebarTeacher = ({toggleSidebar, collapsed}) => {
	const [getData, setGetData] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchAboutMe(navigate);
				setGetData(data);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	return (
		<Sidebar
			collapsed={collapsed}
			style={{
				display: "flex",
				flexDirection: "column",
				height: "100vh",
				width: collapsed ? "80px" : "250px",
				background: "#fff",
				position: "fixed",
				top: "94px",
				bottom: "58px",
				transition: "width 0.3s ease", 
			}}
		>
			<div style={{ flex: 1, background: "#fff", height: "100%", overflowY: "auto"}}>
			<Menu>
					<MenuItem>
						<NavLink to="/teacher">
							<Main />
							{!collapsed && <span>Əsas</span>}
						</NavLink>
					</MenuItem>
					<MenuItem>
						<NavLink to="/grade">
							<Grade />
							<span>Qiymətlər</span>
						</NavLink>
					</MenuItem>
					<MenuItem>
						<NavLink to="/exercises">
							<Task />
							<span>Tapşırıqlar</span>
						</NavLink>
					</MenuItem>

					<MenuItem>
						<NavLink to="/course-schedule">
							<Schedule />
							<span>Dərs cədvəli</span>
						</NavLink>
					</MenuItem>
					{getData.groups && getData.groups.length > 0 && (
						<MenuItem>
							<NavLink to="/activity">
								<Activity />
								<span>Aktivlik</span>
							</NavLink>
						</MenuItem>
					)}
				</Menu>
			</div>
			<div>
				<button
					onClick={() => toggleSidebar()}
					className="btn btn-light"
					style={{
						border: "none",
						background: "transparent",
						fontSize: "24px",
					}}
				>
					{collapsed ? (
						<div className="sidebar-btn">
							<SideBarArrowRight />
						</div>
					) : (
						<div className="sidebar-btn">
							<SideBarArrowLeft />
						</div>
					)}
				</button>
			</div>
		</Sidebar>
	);
};

export default MySidebarTeacher;
