import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { createHeaders, fetchSiteSubjects } from "../functions/apiService";
import TopStudents from "../components/admin/TopStudents";
import ContactCard from "../components/admin/ContactCard";
import * as consts from "../consts/Consts";
import CustomSelect from "../components/CustomSelect";
import { Skeleton } from "@mui/material";

function TopStudent() {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSubjects, setLoadingSubjects] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState(null); // Initially null
  const [topStudents, setTopStudents] = useState([]);

  // Fetch subjects from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingSubjects(true);
        const data = await fetchSiteSubjects();
        const formattedSubjects = data.map((subject) => ({
          value: subject.id,
          label: subject.translations.find((t) => t.language_id === 1)?.name || "Unnamed Subject",
        }));

        setSubjects([{ value: null, label: "Bütün fənlər" }, ...formattedSubjects]); // Add 'All Subjects' option

        if (formattedSubjects.length > 0) {
          setSelectedSubject({ value: null, label: "Bütün fənlər" }); // Set 'All Subjects' as default
        }
        setLoadingSubjects(false);
      } catch (error) {
        console.error("Error fetching subjects:", error);
        setLoadingSubjects(false);
      }
    };

    fetchData();
  }, []);

  // Fetch top students for selected subject
  const getTopStudents = async (subjectId) => {
    const headers = createHeaders();
    const url = subjectId
      ? `${consts.API_URL}/site/statics/top-students?subject_id=${subjectId}`
      : `${consts.API_URL}/site/statics/top-students`;

    try {
      setLoading(true);
      const response = await fetch(url, { method: "GET", headers });

      if (!response.ok) {
        throw new Error("Error fetching top students");
      }

      const data = await response.json();
      setTopStudents(data.data);
    } catch (error) {
      console.error("Error fetching top students:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedSubject) {
      getTopStudents(selectedSubject.value);
    }
  }, [selectedSubject]);

  return (
    <Col xs={12} md={12} lg={12} xl={4} className="lg:mt-0">
      {consts.domain !== "@lider.performance.ms" && (
        <div className="user__right">
          <div className="top">
            <Row className="top__head">
              <Col xs={4}>
                <h3 className="top__label col">Top-5 tələbə</h3>
              </Col>
              <Col xs={8}>
                <div className="top__filter">
                  <CustomSelect
                    options={loadingSubjects ? [{ value: null, label: "Yüklənir" }] : subjects}
                    value={selectedSubject}
                    onChange={setSelectedSubject}
                    placeholder={loadingSubjects ? "Yüklənir" : "Fənn seç"}
                  />
                </div>
              </Col>
            </Row>
            <div className="top__list">
              {loading ? (
                <>
                  <Skeleton height={40} width={300} />
                </>
              ) : topStudents?.length > 0 ? (
                topStudents?.map((studentData) => (
                  <TopStudents key={studentData.id} data={studentData} />
                ))
              ) : (
                <p>Bu ixtisasda statistika yoxdur!</p>
              )}
            </div>
          </div>
        </div>
      )}

      <ContactCard />
    </Col>
  );
}

export default TopStudent;
