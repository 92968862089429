import React from 'react'

function Graduates() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_342_6786)">
                <path
                    d="M1.6875 20.25L27 6.75L52.3125 20.25L27 33.75L1.6875 20.25Z"
                    stroke="#92929D"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M39.6562 50.625V27L27 20.25"
                    stroke="#92929D"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M46.4062 23.3931V34.9103C46.4047 35.2687 46.2862 35.6168 46.0687 35.9017C44.6555 37.8001 38.6648 44.7188 27 44.7188C15.3352 44.7188 9.34453 37.8001 7.93125 35.9017C7.71377 35.6168 7.59526 35.2687 7.59375 34.9103V23.3931"
                    stroke="#92929D"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_342_6786">
                    <rect width="54" height="54" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Graduates