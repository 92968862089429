import React, { useState } from "react";
import EditNoteModal from "./EditNoteModal";

function NotesTableItem({ data, role }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  
  return (
    <>
    {show && (
      <EditNoteModal show={show} handleClose={handleClose} />
)}
      <tr>
       
        <td>
          <div className="left column-content">{data.student.full_name}</div>
        </td>
        <td>
          <div className="column-content" style={{ overflow: "hidden" }}>
            <p
              className="column-note"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                width: "400px",
              }}
              title={data.note}
            >
              {data.note}
            </p>
          </div>
        </td>
        <td>
          <div className="right column-content justify-content-center">{data.date}</div>
        </td>
        {/* {role !== "teacher" && (
          <td>
            <div
              className="operation-buttons right column-content"
              style={{
                marginRight: "20px",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <button
                className="edit-btn operation-btn"
                style={{ background: "none" }}
                onClick={handleShow}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 6H5.94444C5.56135 6 5.19395 6.15218 4.92307 6.42307C4.65218 6.69395 4.5 7.06135 4.5 7.44444V17.5556C4.5 17.9386 4.65218 18.306 4.92307 18.5769C5.19395 18.8478 5.56135 19 5.94444 19H16.0556C16.4386 19 16.806 18.8478 17.0769 18.5769C17.3478 18.306 17.5 17.9386 17.5 17.5556V12.5"
                    stroke="#282928"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.9356 4.48399C17.2173 4.1741 17.5994 4 17.9978 4C18.3962 4 18.7783 4.1741 19.06 4.48399C19.3417 4.79388 19.5 5.21418 19.5 5.65243C19.5 6.09068 19.3417 6.51099 19.06 6.82088L12.3326 14.221L9.5 15L10.2081 11.8841L16.9356 4.48399Z"
                    stroke="#282928"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </td>
        )} */}
      </tr>
    </>
  );
}

export default NotesTableItem;
