import React, { useState, useEffect, useRef } from "react";
import { Pagination } from "@mui/material";
import Form from "react-bootstrap/Form";
import NotesTableItemStudent from "./NotesTableItemStudent";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService"

function NotesTableStudent({ role, startTime, endTime, isGeneralSelected }) {
	const [openSelectOne, setOpenSelectOne] = useState(false);
	const [openSelectTwo, setOpenSelectTwo] = useState(false);
	const [currentWeek, setCurrentWeek] = useState(
		getCurrentWeekRange(new Date())
	);
	const [showCalendar, setShowCalendar] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const calendarRef = useRef(null);
	const dispatch = useDispatch();
	const selectedGroup = useSelector((state) => state.selectedGroup);
	const selectedGroupSubject = useSelector((state) => state.selectedGroupSubject);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [getData, setGetData] = useState(null);
	const [allStudents, setAllStudents] = useState(null);
	const [selectedType, setSelectedType] = useState("");
	const [sortBy, setSortBy] = useState("full_name");
	const [sortDirection, setSortDirection] = useState("asc");

	function getCurrentWeekRange(date) {
		const today = new Date(date);
		const dayOfWeek = today.getDay();

		const startOfWeek = new Date(today);
		startOfWeek.setDate(
			today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
		);

		const endOfWeek = new Date(startOfWeek);
		endOfWeek.setDate(startOfWeek.getDate() + 6);

		return {
			start: formatDate(startOfWeek),
			end: formatDate(endOfWeek),
		};
	}

	function formatDate(date) {
		return date.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
	}

	function parseDate(dateString) {
		const [day, month, year] = dateString.split(".");
		return new Date(`${year}-${month}-${day}`);
	}

	const handleDateChange = (date) => {
		setSelectedDate(date);
		setCurrentWeek(getCurrentWeekRange(date));
		setShowCalendar(false);
	};

	const handleToggleCalendar = () => {
		setShowCalendar((prev) => !prev);
	};

	const handleClickOutside = (event) => {
		if (calendarRef.current && !calendarRef.current.contains(event.target)) {
			setShowCalendar(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const getFormattedDate = () => {
		const dateToFormat = selectedDate;

		return dateToFormat.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
	};

	const handleTypeChange = (event) => {
		setSelectedType(event.target.value);
	};

	useEffect(() => {
		if (selectedGroup && startTime && endTime) {
			const formattedDate = getFormattedDate();

			const dateToSend = currentWeek.start || formattedDate;

			const headers = createHeaders();

			const requestParams = new URLSearchParams({
				group_id: selectedGroup,
				date_from: startTime,
				date_to: endTime,
				pagination: perPage,
				page: currentPage,
				note_nullable: 0
				// per_page: perPage,
				// ...(sortBy ? { [sortBy]: sortDirection } : {}), 
			});

			if (selectedGroupSubject !== null) {
				requestParams.append("group_subject_id", selectedGroupSubject);
			}

			if (selectedType) {
				requestParams.append('status', selectedType);
			}

			fetch(`${consts.API_URL}/student/grade?${requestParams.toString()}`, {
				method: "GET",
				headers: headers,
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Ошибка сети");
					}
					return response.json();
				})
				.then((data) => {
					setGetData(data.data);
					setTotalPages(data.pagination.total_pages);
				})
				.catch((error) => {
					// console.error("Ошибка при отправке запроса:", error);
				});

			const allStudentsParams = new URLSearchParams({
				group_id: selectedGroup,
				group_subject_id: selectedGroupSubject,
				date_from: startTime,
				date_to: endTime,
				pagination: perPage,
				page: currentPage,
				note_nullable: 0
				// per_page: perPage,
			});

			if (selectedType) {
				requestParams.append('status', selectedType);
			} else {
				requestParams.delete('status');
			}

			fetch(`${consts.API_URL}/student/grade?${allStudentsParams.toString()}`, {
				method: "GET",
				headers: headers,
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Ошибка сети");
					}
					return response.json();
				})
				.then((data) => {
					setAllStudents(data);
				})
				.catch((error) => {
					// console.error("Ошибка при отправке запроса:", error);
				});
		}
	}, [
		selectedGroup,
		selectedGroupSubject,
		selectedType,
		startTime,
		endTime,
		perPage,
		currentPage,
		// sortBy,
		// sortDirection,
	]);

	const handlePerPageChange = (event) => {
		setPerPage(Number(event.target.value));
		setCurrentPage(1);
	};

	const handlePageChange = (event, value) => {
		setCurrentPage(value);
	};


	const handleSortChange = (e) => {
		const value = e.target.value;
		const [field, direction] = value.split("=");
		if (field === sortBy) {
			setSortDirection((prevDirection) =>
				prevDirection === "asc" ? "desc" : "asc"
			);
		} else {
			setSortBy(field);
			setSortDirection(direction);
		}
	};

	const filteredNotes = getData ? Object.values(getData).filter(note =>
		note && note.note !== null && (selectedGroup ? note.groupId === selectedGroup.id : true)
	) : [];

	return (
		<div className="groups">
			<div className="groups-header groups-header-grade">
				<div className="header-left">
					<h3 className="title mb-3">Müəllimin qeydləri</h3>
				</div>
				<div className="header-right py-1">
					<div className="group-subject d-flex align-items-center">
						<p className="group-subject-name">Qiymət tipi: </p>
						<Form.Select
							className='selection form-select'
							value={selectedType}
							onChange={handleTypeChange}
						>
							<option value="">Ümumi</option>
							<option value="daily">Günlük</option>
							<option value="exam">İmtahan</option>
							<option value="quiz">Quiz</option>
							<option value="project">Disiplin</option>
						</Form.Select>
					</div>
					{/* <Form.Select
						className={`selection ${openSelectOne ? "active" : ""}`}
						onClick={() => setOpenSelectOne((prev) => !prev)}
						onChange={handleSortChange}
						value={`${sortBy}${sortDirection ? `=${sortDirection}` : ""}`}
					>
						<option value="">Sırala</option>
						<option value="full_name=asc">Müəllim A-Z</option>
						<option value="full_name=desc">
						Müəllim Z-A
						</option>
						<option value="note=asc">Qeyd A-Z</option>
						<option value="note=desc">Qeyd Z-A</option>
						<option value="date=asc">Tarix A-Z</option>
						<option value="date=desc">Tarix Z-A</option>
					</Form.Select> */}
				</div>
			</div>
			<div className="groups-body">
				<table>
					<thead>
						<tr>
							<th>
								<div className="left column-content">
									Tarix
								</div>
							</th>
							{isGeneralSelected && (
								<th>
									<div className="column-content">
										Dərs
									</div>
								</th>
							)}
							<th style={{width: "500px"}}>
								<div className="column-content">
									Qeyd
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{filteredNotes.map((student, i) => (
							<NotesTableItemStudent data={student} key={i} role={role} isGeneralSelected={isGeneralSelected} />
						))}
					</tbody>
				</table>
			</div>
			<div className="groups-footer">
				<div className="groups-pagination d-flex">
					<Pagination
						count={totalPages}
						page={currentPage}
						onChange={handlePageChange}
						className="pagination"
						variant="outlined"
						shape="rounded"
					/>

					<Form.Select
						className={`selection ${openSelectTwo ? "active" : ""}`}
						onClick={() => setOpenSelectTwo((prev) => !prev)}
						onChange={handlePerPageChange}
					>
						<option value="10">10</option>
						<option value="25">25</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</Form.Select>
				</div>
			</div>
		</div>
	);
}

export default NotesTableStudent;
