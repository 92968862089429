import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const formatGrades = (grades) => {
	const defaultGrades = {
		monday: "",
		tuesday: "",
		wednesday: "",
		thursday: "",
		friday: "",
		saturday: "",
		sunday: "",
	};

	if (!grades) {
		return defaultGrades;
	}

	const formattedGrades = { ...defaultGrades };

	grades.forEach(({ week_day, grade, attendance }) => {
		if (formattedGrades.hasOwnProperty(week_day)) {
			console.log(grade);
			
			if (grade) {
				formattedGrades[week_day] = grade;
			} else if (attendance === 0) {
				formattedGrades[week_day] = "Q";
			} else if (attendance === 1) {
				formattedGrades[week_day] = "+";
			} else {
				formattedGrades[week_day] = "";
			}
		}
	});

	return formattedGrades;
};

function GradeTableItem({ data, grades, isToggled }) {
	const formattedGrades = formatGrades(grades);

	console.log(data);
	console.log(grades);
	

	return (
		<tr>
			<td>
				<div
					className="column-content"
					style={{
						paddingLeft: "20px",
						marginLeft: "20px",
						borderTopLeftRadius: "8px",
						borderBottomLeftRadius: "8px",
					}}
				>
					{data.full_name}
				</div>
			</td>
			<td>
				<div className="column-content">
					<div className="table-icon">
						{data.email && (
							<OverlayTrigger
								rootClose={true}
								placement="right"
								className="hover-tooltip"
								trigger="click"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id="button-tooltip" className="tooltip-wrapper">
										{data.email}
									</Tooltip>
								}
							>
								<img src="/img/user.svg" alt="Email icon" />
							</OverlayTrigger>
						)}
					</div>
				</div>
			</td>
			<td>
				<div
					className="column-content"
					style={{
						marginRight: "20px",
						borderTopRightRadius: "8px",
						borderBottomRightRadius: "8px",
					}}
				>
					<div className="table-icon">
						{data.phone && (
							<OverlayTrigger
								placement="right"
								className="hover-tooltip"
								trigger="click"
								rootClose={true}
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id="button-tooltip" className="tooltip-wrapper">
										{data.phone}
									</Tooltip>
								}
							>
								<img src="/img/phone.png" alt="Phone icon" />
							</OverlayTrigger>
						)}
					</div>
				</div>
			</td>
			{!isToggled ? (
				<>
					{[
						"monday",
						"tuesday",
						"wednesday",
						"thursday",
						"friday",
						"saturday",
						"sunday",
					].map((day) => (
						<td key={day}>
							<div className="column-content bg-white grade">
								<input
									type="text"
									value={formattedGrades[day]}
									disabled
									className={`grade-input ${formattedGrades[day] === "Q" ? "q" : formattedGrades[day] === "+" ? "plus" : "grade-btn"
										}`}
								//   className="grade-input"
								//   style={{
								//     backgroundColor:
								//       formattedGrades[day] === "Q" ? "#fc5a5a" : "#b3b3b3",
								//     color: formattedGrades[day] === "Q" ? "#dfe1f3" : "#101110",
								//   }}
								/>
							</div>
						</td>
					))}

				</>
			) : (
				<>
					<td>
						<div className="column-content bg-white average">
							<input
								type="number"
								value={
									data?.total_average?.attendance?.attendance_count
										? Number(data.total_average?.attendance?.attendance_count).toLocaleString("en", {
											minimumFractionDigits: 0,
											maximumFractionDigits: 2,
										})
										: ''
								}
								disabled
								className="grade-input"
							/>
						</div>
					</td>
					<td>
						<div className="column-content bg-white average">
							<input
								type="number"
								value={
									data?.total_average?.attendance?.attendance_percent
										? Number(data.total_average?.attendance?.attendance_percent).toLocaleString("en", {
											minimumFractionDigits: 0,
											maximumFractionDigits: 2,
										})
										: ''
								}
								disabled
								className="grade-input"
							/>
						</div>
					</td>
				</>
			)}
			<td>
				<div className="column-content bg-white average">
					<input
						type="number"
						value={data?.total_average?.week_average ? parseFloat(data.total_average.week_average).toFixed(2) : ''}
						disabled
						className="grade-input"
					/>
				</div>
			</td>
			<td>
				<div className="column-content bg-white average">
					<input
						type="number"
						value={data?.total_average?.all_average ? parseFloat(data.total_average.all_average).toFixed(2) : ''}
						disabled
						className="grade-input"
					/>
				</div>
			</td>
		</tr>
	);
}

export default GradeTableItem;
