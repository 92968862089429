import React from 'react'

function Main() {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4 10H5C7.20914 10 9 11.7909 9 14V16C9 18.2091 7.20914 20 5 20H4C1.79086 20 0 18.2091 0 16V14C0 11.7909 1.79086 10 4 10ZM4 12C2.89543 12 2 12.8954 2 14V16C2 17.1046 2.89543 18 4 18H5C6.10457 18 7 17.1046 7 16V14C7 12.8954 6.10457 12 5 12H4ZM3.5 0C5.433 0 7 1.567 7 3.5V4.5C7 6.433 5.433 8 3.5 8C1.567 8 0 6.433 0 4.5V3.5C0 1.567 1.567 0 3.5 0ZM3.5 2C2.67157 2 2 2.67157 2 3.5V4.5C2 5.32843 2.67157 6 3.5 6C4.32843 6 5 5.32843 5 4.5V3.5C5 2.67157 4.32843 2 3.5 2ZM15 9H16C18.2091 9 20 10.7909 20 13V16C20 18.2091 18.2091 20 16 20H15C12.7909 20 11 18.2091 11 16V13C11 10.7909 12.7909 9 15 9ZM15 11C13.8954 11 13 11.8954 13 13V16C13 17.1046 13.8954 18 15 18H16C17.1046 18 18 17.1046 18 16V13C18 11.8954 17.1046 11 16 11H15ZM12.5 0H16.5C18.433 0 20 1.567 20 3.5C20 5.433 18.433 7 16.5 7H12.5C10.567 7 9 5.433 9 3.5C9 1.567 10.567 0 12.5 0ZM12.5 2C11.6716 2 11 2.67157 11 3.5C11 4.32843 11.6716 5 12.5 5H16.5C17.3284 5 18 4.32843 18 3.5C18 2.67157 17.3284 2 16.5 2H12.5Z"
				fill="#92929D"
			/>
		</svg>
	)
}

export default Main
